export const cashInOrderStatus = {
  CREATED: '',
  LOADING: 'Processing',
  PROCESSING: 'Processing',
  WAITING_CONFIRM: 'Processing',
  FINISHED: 'Succeeded',
  CLOSED: 'Cancelled'
}

export const authScopes = {
  google: ['profile', 'email'],
  facebook: [
    'user_friends',
    'user_hometown',
    'user_likes',
    'user_location',
    'groups_access_member_info',
    'user_photos',
    'user_posts',
    'instagram_basic',
    'instagram_manage_insights',
    'user_videos'
  ]
}

export const downloadAppUris = {
  iOS: {
    PayBy: 'https://apps.apple.com/ae/app/payby/id1513907527',
    BOTIM: 'https://botim.me/home/',
  },
  Android: {
    PayBy: 'https://www.payby.com/android-download',
    BOTIM: 'https://botim.me/home/',
  }
}

export const auditStatus = {
  PENDING: ['Dispute in progress', 'yellow'],
  SUPPLEMENT: ['Submit more docs', 'red'],
  REJECT: ['Rejected', 'red'],
  PASS: ['Pass', ''],
  DENY: ['Deny', 'red'],
  ACCEPT: ['Accept', 'green']
}

export const DateDICT = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '16th',
  '17th',
  '18th',
  '19th',
  '20th',
  '21st',
  '22nd',
  '23rd',
  '24th',
  '25th',
  '26th',
  '27th',
  '28th'
]

export const domainENUMS = {
  mtopay: ['sim-m.test2pay.com', 'uat-m.test2pay.com', 'm.payby.com'],
  mcashier: [
    'sim-mpaypage.test2pay.com',
    'uat-mpaypage.test2pay.com',
    'mpaypage.payby.com'
  ],
  cashier: ['sim-paypage.test2pay.com', 'uat-paypage.test2pay.com', 'paypage.payby.com'],
  activities: [
    'sim-activities.test2pay.com',
    'uat-activities.test2pay.com',
    'activities.payby.com'
  ]
}
