import React from 'react'
import { Route, Switch } from 'react-router-dom'
// import PageLayout from 'layouts/PageLayout'

const AppRouter = route => {
  return (
    <Switch>
      <Route
        path={route.match.path}
        render={routeProps => (
          <React.Fragment>
            {require('./About').default(routeProps)}
            {require('./Steps').default(routeProps)}
          </React.Fragment>
        )}
      />
    </Switch>
  )
}

export default AppRouter
