import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { transferStatus } from 'utils'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const RISK_HISTORY = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'RISK_HISTORY')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  const { pageNum, pageSize = 20, type } = data
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/appeal/V2/queryAppealHistory',
        method: 'POST',
        body: {
          pageNum,
          pageSize,
          appealTypes:
            type === 'unauth'
              ? ['UNAUTHORIZED_TRADE_EVENT']
              : ['PAYMENT_EVENT', 'NON_PAYMENT_EVENT']
        },
        requestAction: RISK_HISTORY.INIT_REQUEST,
        successAction: RISK_HISTORY.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RISK_HISTORY.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [RISK_HISTORY.INIT_SUCCESS]: (state, action) => {
    const { dataList = [] } = action.payload
    _.map(dataList, (item) => {
      if (item.appealTitle === 'Unauthorized payments report') {
        item.appealStatus = transferStatus(item.appealStatus)
      }
    })
    return Object.assign({}, state, action.payload)
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'riskHistory',
    reducerMap: {
      riskHistoryState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
