import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { getPublicConfig } from 'utils'
import { Toast } from 'antd-mobile'
import queryString from 'query-string'
import { autoRechargeAdvance } from 'store/siteConfig'

// ------------------------------------
// Constants
// ------------------------------------
export const TOP_UP_AUTO_RECHARGE_SIGN = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null,
  SIGN_REQUEST: null,
  SIGN_SUCCESS: null
}, 'TOP_UP_AUTO_RECHARGE_SIGN')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: '/cgs/api/cashdesk/protocol/querySimpleApply',
      method: 'POST',
      body: data,
      requestAction: TOP_UP_AUTO_RECHARGE_SIGN.INIT_REQUEST,
      successAction: TOP_UP_AUTO_RECHARGE_SIGN.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

export function submit(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cashdesk/protocol/signConfirm',
        method: 'POST',
        body: data,
        requestAction: TOP_UP_AUTO_RECHARGE_SIGN.SUBMIT_REQUEST,
        successAction: TOP_UP_AUTO_RECHARGE_SIGN.SUBMIT_SUCCESS,
        success: function(result) {
          console.log(result)
          if (result.identifyHint) {
            // 调用native核身方法
            const signData = data
            window.ToPayJSBridge.invoke(
              'openRiskIdentify',
              {
                eventType: 'PAYMENT',
                ...result.identifyHint
              },
              function(data) {
                if (data) {
                  const res = JSON.parse(data)
                  if (res.result === 'pass') {
                    console.log(signData)
                    getPublicConfig((publicInfo) => {
                      console.log(publicInfo)
                      dispatch(
                        sign({
                          ...signData,
                          ...publicInfo
                        })
                      )
                    })
                  } else {
                    res.message && Toast.info(res.message)
                  }
                }
              }
            )
          }
        }
      })
    )
  }
}

export function sign(data) {
  console.log(data)
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cashdesk/protocol/sign',
        method: 'POST',
        body: data,
        requestAction: TOP_UP_AUTO_RECHARGE_SIGN.SIGN_REQUEST,
        successAction: TOP_UP_AUTO_RECHARGE_SIGN.SIGN_SUCCESS,
        success: function(result) {
          const params = queryString.parseUrl(location.href).query
          dispatch(autoRechargeAdvance(params))
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_UP_AUTO_RECHARGE_SIGN.INIT_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_SIGN.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_AUTO_RECHARGE_SIGN.SUBMIT_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_SIGN.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_AUTO_RECHARGE_SIGN.SIGN_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_SIGN.SIGN_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topUpAutoRechargeSign',
    reducerMap: {
      topUpAutoRechargeSignState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
