import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import _ from 'lodash'
import { queryConfigItem } from 'store/siteConfig'

// ------------------------------------
// Constants
// ------------------------------------
export const TOP_UP_INTERNATIONAL_LIST = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'TOP_UP_INTERNATIONAL_LIST')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/queryAccount',
        method: 'POST',
        body: {
          pageInfo: { pageSize: 20, pageNum: 1 },
          accountTypes: ['MOBILE_TOP_UP_INT']
        },
        requestAction: TOP_UP_INTERNATIONAL_LIST.INIT_REQUEST,
        successAction: TOP_UP_INTERNATIONAL_LIST.INIT_SUCCESS,
        success: function(result) {
          if (!_.size(result.accountInfoList)) {
            dispatch(replace('/topUp/international/country'))
          } else {
            dispatch(queryConfigItem({ itemKey: 'MOBILE_TOP_UP_BANNER' }))
          }
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_UP_INTERNATIONAL_LIST.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [TOP_UP_INTERNATIONAL_LIST.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topUpInternationalList',
    reducerMap: {
      topUpInternationalListState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
