import { defineMessages } from 'react-intl'

export default defineMessages({
  headline: {
    id: 'components.FAQs.headline',
    defaultMessage: '{name} FAQ',
    description: '标题'
  },
  service: {
    id: 'components.FAQs.service',
    defaultMessage: 'Customer Service',
    description: '客服中心'
  }
})
