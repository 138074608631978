import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { Toast } from 'antd-mobile'
import _ from 'lodash'
import { setLoadingVisible } from 'store/siteConfig'
import queryString from 'query-string'

const { login } = queryString.parseUrl(window.location.href).query

// ------------------------------------
// Constants
// ------------------------------------
export const RISK_REPORT = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null
}, 'RISK_REPORT')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: `/cgs/api/personal/appeal/appealInit${login === 'N' ? 'Un' : ''}`,
        method: 'POST',
        body: data,
        requestAction: RISK_REPORT.INIT_REQUEST,
        successAction: RISK_REPORT.INIT_SUCCESS,
        success: function(result) {
          if (result.isExistAppeal === 'Y') {
            // 有正在申诉的事件不能再进行申诉，重定向至结果页
            return dispatch(
              replace(
                `/risk/result?${queryString.stringify({
                  isExistAppeal: 'Y',
                  login
                })}`
              )
            )
          }
          dispatch({
            type: RISK_REPORT.INIT_SUCCESS,
            payload: {
              showPage: true
            }
          })
        }
      })
    )
  }
}

export function submit(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: `/cgs/api/personal/appeal/submitAppeal${login === 'N' ? 'Un' : ''}`,
        method: 'POST',
        body: data,
        requestAction: RISK_REPORT.SUBMIT_REQUEST,
        successAction: RISK_REPORT.SUBMIT_SUCCESS,
        success: function(result) {
          dispatch(setLoadingVisible(false))
          dispatch(replace(`/risk/result${window.location.search}`))
        },
        error: function(result, msg) {
          Toast.info(msg)
          dispatch(setLoadingVisible(false))
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RISK_REPORT.INIT_REQUEST]: (state, action) => state,
  [RISK_REPORT.INIT_SUCCESS]: (state, action) => {
    let { kycFlag, payMethodFlag = '' } = action.payload
    // 由于同时完成了EID认证和护照认证同EID认证需要上传的数据一致，这里将其设为EID_KYC
    if (kycFlag === 'EID_AND_PASSPORT_KYC') {
      kycFlag = 'EID_KYC'
    }
    // 创建一个新的字段，用来区分上传文件类型
    if (kycFlag) {
      action.payload.uploadFlag = `${kycFlag}${
        _.identity(payMethodFlag) && `_${payMethodFlag}`
      }`
    }
    console.log(action.payload)
    return Object.assign({}, state, action.payload)
  },
  [RISK_REPORT.SUBMIT_REQUEST]: (state, action) => state,
  [RISK_REPORT.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'riskReport',
    reducerMap: {
      riskReportState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
