import React from 'react'
// import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { Modal } from 'antd-mobile'
import UAParser from 'ua-parser-js'

const { alert, prompt, operation } = Modal
const OSName = new UAParser().getOS().name
console.log(OSName)
const platform = OSName === 'Android' ? 'android' : 'ios'

const NewModal = props => <Modal {...props} platform={platform} />

NewModal.alert = (...props) => alert(...props, platform)
NewModal.prompt = (...props) => prompt(...props, platform)
NewModal.operation = (...props) => operation(...props, platform)

export default NewModal
