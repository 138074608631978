import React from 'react'
import { Route, Switch } from 'react-router-dom'
// import PageLayout from 'layouts/PageLayout'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path='/kyc/basic'
        render={routeProps => (
          <React.Fragment>
            {require('./Referrer').default(routeProps)}
            {require('./Introduction').default(routeProps)}
          </React.Fragment>
        )}
      />
    </Switch>
  )
}

export default AppRouter
