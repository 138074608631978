import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PageLayout from 'layouts/PageLayout'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path='/kyc/delete'
        render={routeProps => (
          <PageLayout>
            {require('./Confirm').default(routeProps)}
            {require('./Contact').default(routeProps)}
          </PageLayout>
        )}
      />
    </Switch>
  )
}

export default AppRouter
