import { defineMessages } from 'react-intl'

export default defineMessages({
  list: {
    id: 'components.MapCarousel.list',
    defaultMessage: 'List',
    description: '列表'
  },
  hour: {
    id: 'components.MapCarousel.hour',
    defaultMessage: 'Business hour',
    description: '营业时间'
  }
})
