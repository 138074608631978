import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { InputItem } from 'antd-mobile'
import { intl } from 'utils'

export default class Captcha extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { form, name, data = {}, getCaptcha } = this.props
    console.log(data)
    return (
      <div className={classes.componentsCaptcha}>
        {form.getFieldDecorator('captchaKey', {
          initialValue: data.captchaKey,
        })(<InputItem className='dn' />)}
        {form.getFieldDecorator(name, {
          rules: [
            {
              type: 'string',
              required: true,
              len: 4,
              message: 'Please enter the valid graphic verification code.',
            },
          ],
        })(
          <InputItem
            type='text'
            maxLength={4}
            placeholder={intl.formatMessage(messages.placeholder)}
            extra={<img src={data.imageBase64} onClick={getCaptcha} />}
          />
        )}
      </div>
    )
  }
}
