import React from 'react'
import PropTypes from 'prop-types'
import classes from './styles.module.scss'
import messages from './messages'
import { injectIntl } from 'react-intl'
import { InputItem as Input } from 'antd-mobile'
import { formatIncompletePhoneNumber, parseIncompletePhoneNumber } from 'libphonenumber-js'

class PhoneItem extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { intl, form, country = 'AE', name, onFocus } = this.props
    return (
      <div className={classes.componentsPhoneItem}>
        {form.getFieldDecorator(name, {
          normalize: (value = '', prev = '') => {
            // 调用国际化组件按国家/地区的格式对输入手机号格式化
            if (value === prev) {
              return value
            }
            if (
              parseIncompletePhoneNumber(value) ===
              parseIncompletePhoneNumber(prev)
            ) {
              return parseIncompletePhoneNumber(value)
            }
            return formatIncompletePhoneNumber(value, country)
          },
          rules: [
            {
              required: true,
              len: 9,
              type: 'string',
              message: 'Required format: 5XXXXXXXX'
            }
          ]
        })(
          <Input
            className={classes.input}
            type='tel'
            maxLength={9}
            onFocus={onFocus}
            placeholder={intl.formatMessage(messages.placeholder)}
          >
            <b>971</b>
          </Input>
        )}
      </div>
    )
  }
}

export default injectIntl(PhoneItem)

PhoneItem.propTypes = {
  form: PropTypes.object.isRequired
}
