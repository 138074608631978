import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { customEvent, isPayBy } from 'utils'
// import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------

const status = ['WAIT_PAY', 'PAY_SUCCESS', 'DEPOSIT_ING']
const successEnum = ['DEPOSIT_SUCCESS', 'SETTLED_ING', 'TRADE_FINISH']
var timer

export const TOP_UP_RESULT = keyMirror(
  {
    INIT_REQUEST: null,
    INIT_SUCCESS: null,
    QUERY_ORDER_AFTER_FINISH_REQUEST: null,
    QUERY_ORDER_AFTER_FINISH_SUCCESS: null
  },
  'TOP_UP_RESULT'
)

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: isPayBy()
          ? '/cgs/api/lifecenter/queryOrderDetail'
          : '/cgs/api/lifecenter/v1/unauth/query-order-detail',
        method: 'POST',
        body: data,
        requestAction: TOP_UP_RESULT.INIT_REQUEST,
        successAction: TOP_UP_RESULT.INIT_SUCCESS,
        success: function(result) {
          const { orderStatus, orderType } = result.orderInfo
          console.log(status.indexOf(orderStatus))
          customEvent({
            page_name: 'payment_result'
          })
          // 非处理中中止轮训
          if (status.indexOf(orderStatus) === -1) {
            if (
              isPayBy() &&
              successEnum.indexOf(orderStatus) !== -1 &&
              orderType === '2'
            ) {
              // 充值成功查询是否需要展示自动充值弹窗
              dispatch(queryOrderAfterFinished(data))
            }
            return false
          }
          // 每5s轮询一次
          timer = setTimeout(() => {
            dispatch(init(data))
          }, 5000)
        }
      })
    )
  }
}

export function clearTimeOut() {
  return (dispatch) => {
    clearTimeout(timer)
  }
}

export function queryOrderAfterFinished(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/queryOrderAfterFinished',
        method: 'POST',
        body: data,
        requestAction: TOP_UP_RESULT.QUERY_ORDER_AFTER_FINISH_REQUEST,
        successAction: TOP_UP_RESULT.QUERY_ORDER_AFTER_FINISH_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_UP_RESULT.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_RESULT.INIT_SUCCESS]: (state, action) => {
    return Object.assign({}, state, action.payload)
  },
  [TOP_UP_RESULT.QUERY_ORDER_AFTER_FINISH_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_RESULT.QUERY_ORDER_AFTER_FINISH_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topUpResult',
    reducerMap: {
      topUpResultState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
