import React from 'react'
import { withRouter } from 'react-router-dom'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { NoticeBar, Icon } from 'antd-mobile'
import { openKYC } from 'utils'

class KycNotification extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }

  handleClick = () => {
    const { status, history } = this.props
    status ? openKYC({ product: 'Mobile Transfer Details' }) : history.push('/kyc/quota?verified=true')
  }

  render() {
    const { children } = this.props
    return (
      <NoticeBar
        className={classes.noticebar}
        icon={null}
        mode='link'
        onClick={this.handleClick}
        action={<Icon type='right' size='sm' />}
      >
        {children}
      </NoticeBar>
    )
  }
}

export default withRouter(KycNotification)
