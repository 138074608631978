import PropTypes from 'prop-types'
import React from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 16, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false
}

const CurrencyInput = React.forwardRef(({ maskOptions, ...inputProps }, ref) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions
  })

  return (
    <div className='am-list-item am-input-item am-list-item-middle am-search'>
      <div className='am-list-line'>
        <div className='am-input-control'>
          <MaskedInput ref={ref} mask={currencyMask} {...inputProps} />
        </div>
      </div>
    </div>
  )
})

CurrencyInput.defaultProps = {
  inputMode: 'decimal',
  maskOptions: {}
}

CurrencyInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number
  })
}

export default CurrencyInput
