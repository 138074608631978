import { defineMessages } from 'react-intl'

export default defineMessages({
  placeholder: {
    id: 'components.SMSCode.placeholder',
    defaultMessage: 'Please enter code',
    description: '短信验证码placeholder'
  },
  getCode: {
    id: 'components.SMSCode.getCode',
    defaultMessage: 'Get code',
    description: '获取验证码'
  },
  errMsg: {
    id: 'components.SMSCode.errMsg',
    defaultMessage: 'Please enter the valid SMS Code.',
    description: '短信验证码报错信息'
  }
})
