import { Toast } from 'antd-mobile'
import _ from 'lodash'
import currencyFormatter from 'currency-formatter'
import * as enums from './enum'
import moment from 'moment'
import UAParser from 'ua-parser-js'
import intl from './intl'
import messages from 'languages/messages'
import queryString from 'query-string'
moment.locale(getCurrentLanguage())

export function checkFormError(data, sort) {
  // check passed form errors, if this is no error, return true, else show the first error
  if (!data) {
    return true
  }
  var errors = _.chain(data).values().map('errors').flatten().value()
  if (sort) {
    errors = _.sortBy(errors, (x) => _.findIndex(sort, (y) => x.field === y))
  }
  const msg = _.get(errors, '[0].message')
  Toast.info(msg)
  return false
}

export function getUUID(length = 32) {
  return [...Array(length)].map(i => (~~(Math.random() * 36)).toString(36)).join('')
}

export function handleDate(date, formatter = 'formatter1') {
  const formatters = {
    formatter1: 'DD/MM/YYYY HH:mm',
    formatter2: 'DD/MM HH:mm',
    formatter3: 'DD/MM/YYYY',
    formatter4: 'DD/MM/YYYY HH:mm'
  }
  return date ? moment(date).format(formatters[formatter]) : ''
}

export function handleAmount(data, symbol, precision = 2) {
  const directions = ['-', '+', '']
  if (data) {
    const currency = symbol || data.currency
    const direction = typeof data.direction === 'number' ? data.direction : '2'
    return currencyFormatter.format(data.amount, {
      symbol: currency,
      precision: precision,
      format: currency ? `%s ${directions[direction]}%v` : `${directions[direction]}%v`, // %s is the symbol and %v is the value
    })
  }
  return '-.--'
}

export function getCurrentLanguage(params) {
  // 从User-Agent获取语言，兼容IOS无法设置webview语言
  const data = PayByInfo()
  const supportLanguages = ['en', 'ar']
  const currentLanguage = data.lang || window.navigator.language.split(/[-_]/)[0]
  return supportLanguages.indexOf(currentLanguage) > -1 ? currentLanguage : 'en'
}

export function setHtmlLangAndDir(language) {
  document.documentElement.setAttribute('lang', language)
  if (language === 'ar') {
    document.documentElement.setAttribute('dir', 'rtl')
  } else {
    document.documentElement.removeAttribute('dir')
  }
}

export function checkVisible(elm) {
  var rect = elm.getBoundingClientRect()
  // 获取当前浏览器的视口高度，不包括工具栏和滚动条
  // document.documentElement.clientHeight兼容 Internet Explorer 8、7、6、5
  var viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  )
  // bottom top是相对于视口的左上角位置
  // bottom大于0或者top-视口高度小于0可见
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
}

export function isPayBy() {
  // 判断是否在PayBy环境下
  return navigator.userAgent.toLowerCase().indexOf('payby') !== -1
}

export function PayByInfo() {
  // PayBy/${PayBySDKVersion} (${hostAppName}, ${hostAppVersion}, ${IP}, ${mobileBrand})
  // 'Mozilla/5.0 (iPhone; CPU iPhone OS 12_4_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 PayBy/1.5.1 (payby, 1.3.0, 192.168.1.4, iPhone 6)'
  // 13 (payby, 2.9.0, 10.246.22.45, iPhone14,2, ar)
  const userAgent = navigator.userAgent
  if (userAgent.toLowerCase().indexOf('payby') === -1) {
    return {}
  }
  try {
    const PayBySDKVersion = userAgent.match(/PayBy\/([0-9.]+)/)[1]
    const infos = userAgent.match(/PayBy\/[0-9. ]+\(([^\\)]+)/)[1].split(', ')
    return {
      PayBySDKVersion,
      hostAppName: infos[0].trim(' '),
      hostAppVersion: infos[1].trim(' '),
      IP: infos[2].trim(' '),
      mobileBrand: infos[3].trim(' '),
      lang: infos[4] && infos[4].trim(' ')
    }
  } catch (error) {
    return {}
  }
}

export function closeWebView() {
  // 关闭webView
  console.log('closeWebView')
  if (typeof ToPayJSBridge === 'undefined') {
    return false
  }
  window.ToPayJSBridge.invoke('leaveWeb')
}

export function share(url) { // 调用native分享方法
  console.log(url)
  if (typeof ToPayJSBridge === 'undefined') {
    return false
  }
  window.ToPayJSBridge.invoke('share', { text: url }, function(data) {
    const res = JSON.parse(data)
    console.log(res)
  })
}

export function openKYC(params, callback) { // product为必传项
  const { referrerCode } = params
  if (!referrerCode) { // 兼容iOS版本
    params.referrerCode = ''
  }
  console.log(params)
  try {
    window.ToPayJSBridge.invoke('requestVerify', params, function(data) { // 登录状态
      if (data) {
        const res = JSON.parse(data)
        if (res.verifyFinished) {
          typeof callback === 'function' ? callback() : window.location.reload()
        }
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export function getPublicConfig(callback) { // 获取app公共配置信息
  const result = {
    requestHeader: {}
  }
  try {
    window.ToPayJSBridge.invoke('getPublicConfig', {}, function(data) {
      console.log(data)
      if (data) {
        const res = JSON.parse(data)
        console.log(res)
        res['Device-Id'] = res['X-Device-Id'] || res['Device-Id']
        delete res['X-Device-Id']
        result.requestHeader = res
        return callback(result)
      }
      return callback(result)
    })
  } catch (error) {
    console.log(error)
    return callback(result)
  }
}

export function sendProtocolState(status = 'PROTOCOL-FAIL') {
  function onBridgeReady() {
    try {
      // window.ToPayJSBridge.init 方法二次调用的时候会报错
      window.ToPayJSBridge.init(function(message, responseCallback) {})
    } catch (error) {
      console.log(error)
    }
    window.ToPayJSBridge.invoke('iapProtocolCallback', {
      // 回传给native签约状态
      state: status
    })
  }
  console.log('status:', status)
  console.log(typeof ToPayJSBridge)
  if (typeof ToPayJSBridge === 'undefined') {
    document.addEventListener('ToPayJSBridgeReady', onBridgeReady, false) // ToPayJSBridgeReady
  } else {
    onBridgeReady()
  }
}

export function openNative(url, needCallBack, callback) {
  // h5打开native原生功能
  console.log(url, needCallBack)
  try {
    window.ToPayJSBridge.invoke(
      'openNative',
      { router: url, needCallback: needCallBack || false },
      function(data) {
        const res = JSON.parse(data)
        console.log(res)
        typeof callback === 'function' && callback(res)
      }
    )
  } catch (error) {
    console.log(error)
  }
}

export function eventTracking(event, pagePos, iconPos, channel = 'payby', callback) {
  window.firebase && window.firebase.analytics().logEvent(event, {
    page_position: pagePos,
    icon_position: iconPos,
    partner_channel: channel,
    event_callback: function(params) {
      console.log(params)
      typeof callback === 'function' && callback()
    },
    event_timeout: 3000
  })
}

export function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function openUri(uri) {
  console.log('openUri:', uri)
  const OSName = new UAParser().getOS().name
  switch (OSName) {
    case 'iOS':
    case 'Android':
      if (inIframe()) {
        // 父页面注册message方法，拿到deeplink的地址后做跳转
        // window.addEventListener('message', function(e) {
        //   if (e.data.message === 'PayBy_Cashier_Link') {
        //     window.location.href = e.data.uri
        //   }
        // })
        const message = {
          message: 'PayBy_Cashier_Link',
          uri: uri
        }
        window.parent.postMessage(message, '*')
      } else {
        // window.location.href = uri
        window.open(uri, '_blank')
      }
      break
    default:
      Toast.info(intl.formatMessage(messages['app.error.downloadNotSupport']))
      break
  }
}

export function handleIdentifyHint(identifyHint) {
  console.info('identifyHint:', identifyHint)
  if (!identifyHint || !_.isArray(identifyHint.identifyMethods)) {
    return {}
  }
  const currentIdentifyHint = identifyHint.identifyMethods.shift()
  console.info('currentIdentifyHint', currentIdentifyHint)
  return {
    ...currentIdentifyHint,
  }
}

export function downloadApp(platform) {
  const OSName = new UAParser().getOS().name
  console.log(OSName)
  switch (OSName) {
    case 'Android':
      openUri(enums.downloadAppUris.Android[platform])
      break
    case 'iOS':
    case 'Mac OS':
      openUri(enums.downloadAppUris.iOS[platform])
      break
    default:
      Toast.info(intl.formatMessage(messages['app.error.downloadNotSupport']))
      break
  }
}

export function decimalToPercent(point) {
  if (point) {
    var string = Number(point * 100).toFixed(2)
    string += '%'
    return string
  }
  return '0.00%'
}

export function customEvent(params) {
  // Countly埋点事件 http://wiki.test2pay.com/pages/viewpage.action?pageId=37302821
  const { query } = queryString.parseUrl(window.location.href)
  const campaignInfo = _.omitBy(query, (value, key) => !_.includes(key, 'utm'))
  const { hostAppVersion, PayBySDKVersion, hostAppName, mobileBrand = '' } = PayByInfo()
  const [brand, model] = mobileBrand.split('.')
  const { name, version } = new UAParser().getOS()
  console.log(params)
  /* eslint-disable camelcase */
  const { refer_page_name } = query
  const { event_name = 'display', element_name = 'pageview' } = params
  window.Countly.q.push([
    'add_event',
    {
      key: event_name,
      count: 1,
      sum: 1.5,
      dur: 30,
      segmentation: {
        event_name,
        element_name,
        ...params,
        event_time: new Date().getTime(),
        user_id: localStorage.user_id,
        // 来源
        refer_page_name,
        // 活动信息
        ...campaignInfo,
        // APP信息
        app_name: hostAppName,
        app_version: hostAppVersion,
        sdk_version: PayBySDKVersion,
        // 设备信息
        device_brand_name: brand,
        device_model_name: model,
        // 系统信息
        os_platform: name,
        os_version: version
      }
    }
  ])
}

export function openBotimOAuth(scope, callback) { // H5请求botim OAuth
  console.log(scope)
  function onBridgeReady() {
    try {
      // window.ToPayJSBridge.init 方法二次调用的时候会报错
      window.ToPayJSBridge.init(function(message, responseCallback) {})
    } catch (error) {
      Toast.hide()
      console.log(error)
    }
    window.ToPayJSBridge.invoke(
      'openBotimOAuth',
      {
        scope: scope,
        client_id: getBotimClientId(),
        redirect_uri: 'botmpx://me.botim.open.authorizer/index.html',
        state: ''
      },
      function(data) {
        const res = JSON.parse(data)
        console.log(res)
        if (res.errorCode === '0') {
          typeof callback === 'function' && callback(res.data.authCode)
        } else {
          Toast.info(intl.formatMessage(messages['app.error.failed']))
        }
      }
    )
  }

  console.log(typeof ToPayJSBridge)
  if (typeof ToPayJSBridge === 'undefined') {
    document.addEventListener('ToPayJSBridgeReady', onBridgeReady, false) // ToPayJSBridgeReady
  } else {
    onBridgeReady()
  }
}

export function getVerifyStatus(callback) { // 查询kyc状态
  function onBridgeReady() {
    try {
      // window.ToPayJSBridge.init 方法二次调用的时候会报错
      window.ToPayJSBridge.init(function(message, responseCallback) {})
    } catch (error) {
      console.log(error)
    }
    window.ToPayJSBridge.invoke('getVerifyStatus', {}, function(data) {
      // Toast.info(data)
      const res = JSON.parse(data)
      return callback(res)
    })
  }
  console.log(typeof ToPayJSBridge)
  if (typeof ToPayJSBridge === 'undefined') {
    document.addEventListener('ToPayJSBridgeReady', onBridgeReady, false) // ToPayJSBridgeReady
  } else {
    onBridgeReady()
  }
}

export function getBotimClientId() {
  return window.location.hostname === 'm.payby.com' ? 'botJnTqkNEr8tHtxH' : 'bote0HTOSpzhWnBZ0'
}

export function hasEmptyValue(fieldsValue, notRequiredValues = []) {
  // 判断是否存在为空的必填项，notRequiredValues非必填项
  return Object.keys(fieldsValue).some(
    (field) => !_.includes(notRequiredValues, field) && !fieldsValue[field]
  )
}

export function redirectToOtherProject(url, project = 'mcashier') {
  const currentProject = 'mtopay'
  const currentHostName = window.location.hostname
  const ENUMS = {
    mtopay : ['sim-m.test2pay.com', 'uat-m.test2pay.com', 'm.payby.com'],
    mcashier : ['sim-mpaypage.test2pay.com', 'uat-mpaypage.test2pay.com', 'mpaypage.payby.com'],
    cashier : ['sim-paypage.test2pay.com', 'uat-paypage.test2pay.com', 'paypage.payby.com'],
    activities : ['sim-activities.test2pay.com', 'uat-activities.test2pay.com', 'activities.payby.com'],
    utilities: ['sim-utilities.test2pay.com', 'uat-utilities.test2pay.com', 'utilities.payby.com']
  }
  const index = currentHostName === 'localhost' ? 0 : _.findIndex(ENUMS[currentProject], item => item === currentHostName)
  window.location.href = `https://${ENUMS[project][index]}${url}`
}

export function formatMobileNumber(mobileNumber) {
  function format(mobile) {
    return _.startsWith(mobile, '0') ? mobile : `0${mobile}`
  }
  if (!_.includes(mobileNumber, '-')) {
    return format(mobileNumber)
  }
  return format(mobileNumber.split('-')[1])
}

export function ToPayRequest(token, callback) { // 呼起收银台
  function onBridgeReady() {
    try {
      window.ToPayJSBridge.init(function(message, responseCallback) {})
    } catch (error) {
      console.log(error)
      // Toast.info('Oops, failed to do the payment.')
    }
    Toast.hide()
    window.ToPayJSBridge.invoke(
      'ToPayRequest',
      {
        appId: '',
        token: token
      },
      (data) => {
        if (data) {
          const res = JSON.parse(data)
          console.log(res)
          typeof callback === 'function' && callback(res)
        }
      }
    )
  }
  console.log(typeof ToPayJSBridge)
  if (typeof ToPayJSBridge === 'undefined') {
    document.addEventListener('ToPayJSBridgeReady', onBridgeReady, false) // ToPayJSBridgeReady
  } else {
    onBridgeReady()
  }
}

export function openRiskIdentify(data, callback) {
  // 打开核身
  try {
    window.ToPayJSBridge.invoke('openRiskIdentify', data, function(data) {
      if (data) {
        const res = JSON.parse(data)
        if (res.result === 'pass') {
          typeof callback === 'function' && callback(res)
        } else {
          res.message && Toast.info(res.message)
        }
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export function requestPermission(data, callback) {
  // 请求原生系统权限
  try {
    window.ToPayJSBridge.invoke('requestPermission', data, function(data) {
      if (data) {
        console.log(data)
        const res = JSON.parse(data)
        if (res.status === 'rejectForever') {
          Toast.info(intl.formatMessage(messages['app.error.enableAccess']))
        } else {
          typeof callback === 'function' && callback()
        }
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export function getDomainName(project = 'mcashier') {
  const currentProject = 'mtopay'
  const currentHostName = window.location.hostname
  const index =
    currentHostName === 'localhost'
      ? 0
      : _.findIndex(enums.domainENUMS[currentProject], (item) => item === currentHostName)
  return `https://${enums.domainENUMS[project][index]}`
}

export function deeplink(url, brand = 'PayBy') {
  const deeplinks = {
    PayBy: 'https://app.payby.com/launch?feature-code=',
    BOTIM: 'https://botim.me/botim/payby?feature-code=',
  }
  window.location.href = deeplinks[brand] + encodeURIComponent(url)
}

export function transferStatus(params) {
  // 非授权申诉状态转化
  if (params === 'REJECT') {
    return 'DENY'
  }
  if (params === 'PASS') {
    return 'ACCEPT'
  }
  return params
}

export { enums as ENUMS }
export { default as intl } from './intl'
export { default as signInWithRedirect } from './signInWithRedirect'
export { recordEvent } from './JSBridge'
