import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import _ from 'lodash'
import queryString from 'query-string'
import { getSalt } from 'store/siteConfig'
import { encrypt } from 'utils/rsa'

// ------------------------------------
// Constants
// ------------------------------------
export const UTILITY_PARKING_TOP_UP = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null
}, 'UTILITY_PARKING_TOP_UP')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/queryParkingTopUpAmountRange',
        method: 'POST',
        body: {
          orderType: 'PARKING_DEPOSIT'
        },
        requestAction: UTILITY_PARKING_TOP_UP.INIT_REQUEST,
        successAction: UTILITY_PARKING_TOP_UP.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function submit(data, callback) {
  return dispatch => {
    dispatch(
      getSalt(saltObj => {
        // 调用接口前先获取盐做手机号加密
        const { salt } = saltObj
        console.log(saltObj)
        const { phone, ...resData } = data
        resData.phone = encrypt(phone, salt)
        dispatch(
          callAPI({
            dispatch: dispatch,
            endpoint: '/cgs/api/lifecenter/createParkingDepositOrder',
            method: 'POST',
            body: resData,
            requestAction: UTILITY_PARKING_TOP_UP.SUBMIT_REQUEST,
            successAction: UTILITY_PARKING_TOP_UP.SUBMIT_SUCCESS,
            success: function(result) {
              const tokenUrl = _.chain(result).get('token').value()
              const token = queryString.parse(tokenUrl).ft
              window.ToPayJSBridge &&
                window.ToPayJSBridge.invoke(
                  'ToPayRequest',
                  {
                    appId: '',
                    token: token
                  },
                  data => {
                    if (data) {
                      const res = JSON.parse(data)
                      console.log(res)
                      if (res.status === 'success' || res.status === 'paying') {
                        dispatch(
                          replace(`/utility/transaction/detail/${result.orderNo}`)
                        )
                      }
                    }
                  }
                )
            }
          })
        )
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UTILITY_PARKING_TOP_UP.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UTILITY_PARKING_TOP_UP.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UTILITY_PARKING_TOP_UP.SUBMIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UTILITY_PARKING_TOP_UP.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'utilityParkingTopUp',
    reducerMap: {
      utilityParkingTopUpState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
