import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { Modal, WhiteSpace, Toast } from 'antd-mobile'
import { Helmet } from 'react-helmet'
import { intl } from 'utils'

const grids = [0, 1, 2, 3, 4, 5]
const scriptSrc =
  window.location.hostname === 'm.payby.com'
    ? '/cfca/cfcasip.6.1.1.3.min.prod.js'
    : '/cfca/cfcasip.6.1.1.3.min.dev.js'
export default class PasswordVerifyModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: true,
    }
  }

  handleScriptInject = (newState, addedTags) => {
    addedTags.scriptTags[0].onload = () => {
      console.log('cfca控件加载完成!')
      this.initCFCAKeyboard()
    }
  }

  initCFCAKeyboard = () => {
    // 初始化密码控件
    const KEY_WORDS = [
      '000000',
      '111111',
      '222222',
      '333333',
      '444444',
      '555555',
      '666666',
      '777777',
      '888888',
      '999999',
      '0123456789876543210',
    ]
    console.log(window.CIPHER_TYPE_RSA, window.OUTPUT_TYPE_HASH)
    const keyboard = new window.CFCAKeyboard(window.KEYBOARD_TYPE_NUMBER)
    keyboard.bindInputBox('pwd')
    keyboard.setServerRandom(this.props.salt)
    keyboard.setCipherType(window.CIPHER_TYPE_RSA)
    keyboard.setMinLength(6)
    keyboard.setMaxLength(6)
    keyboard.setOutputType(window.OUTPUT_TYPE_HASH)
    keyboard.setKeywords(KEY_WORDS)
    keyboard.showKeyboard()
    keyboard.setInputChangeCallback(this.inputChangeCallback)
    this.keyboard = keyboard
  }

  inputChangeCallback = (sipBoxId, type, length) => {
    // 输入回调
    this.setState({
      pwdLength: length
    })

    if (length === 6) {
      const errorCode = this.keyboard.getErrorCode().toString(16)
      console.log(errorCode)
      if (errorCode !== '0') {
        return Toast.info('Please choose a stronger password!')
      }
      const encryptValue = this.keyboard.getEncryptedInputValue(sipBoxId)
      const clientRandom = this.keyboard.getEncryptedClientRandom(sipBoxId)
      this.props.submit({
        // identifyTicket: this.props.identifyTicket,
        salt: this.props.salt,
        paymentPassword:`${encryptValue}^${clientRandom}`
      }, () => {
        this.props.successCallback()
        this.keyboard.hideKeyboard()
        this.setState({
          showModal: false
        })
      }, () => {
        // 验证错误重置输入框
        this.keyboard.clearInputValue(sipBoxId)
        this.setState({
          pwdLength: 0
        })
      })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.salt !== this.props.salt) {
      this.keyboard.setServerRandom(this.props.salt)
    }
  }

  componentWillUnmount = () => {
    this.keyboard.hideKeyboard()
  }

  render() {
    const { pwdLength } = this.state
    return (
      <React.Fragment>
        <Helmet onChangeClientState={this.handleScriptInject}>
          <script src={scriptSrc} />
        </Helmet>
        <Modal
          popup
          closable
          title={intl.formatMessage(messages.title)}
          animationType='slide-up'
          className={classes.componentsPasswordVerifyModal}
          visible={this.state.showModal}
          onClose={this.props.removeSalt}
        >
          <div>
            <WhiteSpace size='xl' />
            <WhiteSpace size='xl' />
            <div className='text-center'>
              {grids.map((index) => {
                return (
                  <span key={index} className={`${classes.grid} ${index < pwdLength ? classes.active : ''}`}>*</span>
                )
              })}
            </div>
            <input className='dn' id='pwd' />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
