import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, goBack } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { closeWebView } from 'utils'
import { Toast } from 'antd-mobile'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYMENT_AUTHORIZATION_SERVICE_DETAIL = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  CANCEL_REQUEST: null,
  CANCEL_SUCCESS: null
}, 'PAYMENT_AUTHORIZATION_SERVICE_DETAIL')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cashdesk/protocol/detail',
        method: 'POST',
        body: data,
        requestAction: PAYMENT_AUTHORIZATION_SERVICE_DETAIL.INIT_REQUEST,
        successAction: PAYMENT_AUTHORIZATION_SERVICE_DETAIL.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function cancel(data) {
  const { isNeedBack, ...resData } = data
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cashdesk/protocol/relieve',
        method: 'POST',
        body: resData,
        requestAction: PAYMENT_AUTHORIZATION_SERVICE_DETAIL.CANCEL_REQUEST,
        successAction: PAYMENT_AUTHORIZATION_SERVICE_DETAIL.CANCEL_SUCCESS,
        success: function(result) { // TODO 回上层or关闭
          Toast.info('Service has been closed successfully.', 2, () => {
            if (isNeedBack) {
              dispatch(goBack())
              return
            }
            closeWebView()
          })
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PAYMENT_AUTHORIZATION_SERVICE_DETAIL.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [PAYMENT_AUTHORIZATION_SERVICE_DETAIL.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [PAYMENT_AUTHORIZATION_SERVICE_DETAIL.CANCEL_REQUEST]: (state, action) => state,
  [PAYMENT_AUTHORIZATION_SERVICE_DETAIL.CANCEL_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'paymentAuthorizationServiceDetail',
    reducerMap: {
      paymentAuthorizationServiceDetailState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
