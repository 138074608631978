import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import ReactMarkdown from 'react-markdown'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { tomorrowNightEighties } from 'react-syntax-highlighter/dist/esm/styles/hljs'

class CodeBlock extends React.PureComponent {
  render() {
    return (
      <SyntaxHighlighter style={tomorrowNightEighties}>
        {this.props.value}
      </SyntaxHighlighter>
    )
  }
}

export default class Markdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { source } = this.props
    return (
      <div>
        <ReactMarkdown
          className={classes.componentsMarkdown}
          source={source}
          escapeHtml={false}
          renderers={{
            code: CodeBlock
          }}
        />
      </div>
    )
  }
}
