import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import moment from 'moment'
import _ from 'lodash'
import { Toast } from 'antd-mobile'
import queryString from 'query-string'
import { languageChange } from 'store/siteConfig'

// ------------------------------------
// Constants
// ------------------------------------
export const UAT_PAYMENT = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null
}, 'UAT_PAYMENT')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: 'test.json',
      method: 'POST',
      requestAction: UAT_PAYMENT.INIT_REQUEST,
      successAction: UAT_PAYMENT.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

export function submit(data, callback) {
  const { paySceneParams, ...restData } = data
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/sgs/api/acquire2/placeOrder',
        method: 'POST',
        body: {
          requestHeader: {
            'Content-Language': 'en',
            'Partner-Id': '200000042613',
          },
          signature: true,
          requestTime: moment().valueOf(),
          bizContent: {
            merchantOrderNo: moment().valueOf(),
            subject: 'productName',
            paySceneParams: {
              redirectUrl: window.location.href,
              ...paySceneParams,
            },
            ...restData,
          },
        },
        requestAction: UAT_PAYMENT.SUBMIT_REQUEST,
        successAction: UAT_PAYMENT.SUBMIT_SUCCESS,
        success: function(result) {
          const tokenUrl = _.chain(result)
            .get('interActionParams.tokenUrl')
            .value()
          console.log(tokenUrl)
          switch (data.paySceneCode) {
            case 'JSAPI':
              var token = queryString.parse(tokenUrl).ft
              console.log(token)
              window.ToPayJSBridge &&
                window.ToPayJSBridge.invoke(
                  'ToPayRequest',
                  {
                    appId: '',
                    token: token,
                  },
                  () => {
                    window.ToPayJSBridge.invoke('leaveWeb')
                  }
                )
              break
            case 'EWALLET':
              var deeplink = _.get(result, 'interActionParams.deepLink')
              window.location.href = deeplink
              break
            case 'PAYPAGE':
              tokenUrl && window.location.replace(tokenUrl)
              break
            default:
              break
          }
          Toast.hide()
        },
      })
    )
  }
}

// Toast.hide()

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UAT_PAYMENT.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UAT_PAYMENT.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UAT_PAYMENT.SUBMIT_REQUEST]: (state, action) => state,
  [UAT_PAYMENT.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'uatPayment',
    reducerMap: {
      uatPaymentState: reducer,
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)],
    // Actions to fire when this module is added/removed
    initialActions: [languageChange('en')],
    // finalActions: []
  }
}
