import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYMENT_AUTHORIZATION_MAIN = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'PAYMENT_AUTHORIZATION_MAIN')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data, callback) {
  const { pageNum, pageSize } = data
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cashdesk/protocol/query',
        method: 'POST',
        body: {
          protocolType: 'AUTH_DEDU', // INTEGRATED_BUSINESS 自动充值服务
          protocolSubTypes: ['AUTH_DEDU'], // MOBILE_AUTO_RECHARGE TODO
          pageNum,
          pageSize
        },
        requestAction: PAYMENT_AUTHORIZATION_MAIN.INIT_REQUEST,
        successAction: PAYMENT_AUTHORIZATION_MAIN.INIT_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback()
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PAYMENT_AUTHORIZATION_MAIN.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [PAYMENT_AUTHORIZATION_MAIN.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'paymentAuthorizationMain',
    reducerMap: {
      paymentAuthorizationMainState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
