import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { getSalt, autoRechargeAdvance } from 'store/siteConfig'
import { encrypt } from 'utils/rsa'
import { Toast } from 'antd-mobile'
import { openRiskIdentify, intl } from 'utils'
import _ from 'lodash'
import messages from './messages'
import { DateDICT } from 'utils/enum'

// ------------------------------------
// Constants
// ------------------------------------
export const TOP_UP_AUTO_RECHARGE_ACTIVATE = keyMirror(
  {
    INIT_REQUEST: null,
    INIT_SUCCESS: null,
    QUERY_MOBILE_PACKAGE_REQUEST: null,
    QUERY_MOBILE_PACKAGE_SUCCESS: null,
    QUERY_GOODS_REQUEST: null,
    QUERY_GOODS_SUCCESS: null,
    QUERY_CONFIG_DETAIL_REQUEST: null,
    QUERY_CONFIG_DETAIL_SUCCESS: null,
    OPEN_REQUEST: null,
    OPEN_SUCCESS: null,
    DISABLE_CONFIG_REQUEST: null,
    DISABLE_CONFIG_SUCCESS: null,
    INIT_PAGE: null
  },
  'TOP_UP_AUTO_RECHARGE_ACTIVATE'
)

// ------------------------------------
// Actions
// ------------------------------------
export function init() { // 初始化获取套餐列表
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: '/cgs/api/lifecenter/autoRecharge/initAutoRechargePage',
      method: 'POST',
      requestAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.INIT_REQUEST,
      successAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

export function queryMobilePackage(data, callback) {
  // 查询套餐信息 TODO 新增是否开通字段
  Toast.loading('', 0)
  return (dispatch) => {
    dispatch(getSalt()).then((res) => {
      const { salt } = res.payload
      const phone = encrypt(data.phone, salt)
      console.log(data.phone)
      dispatch(
        callAPI({
          dispatch: dispatch,
          endpoint: '/cgs/api/lifecenter/queryMobilePackage',
          method: 'POST',
          body: { phone, queryChannelFlag: false },
          requestAction:
            TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_MOBILE_PACKAGE_REQUEST,
          successAction:
            TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_MOBILE_PACKAGE_SUCCESS,
          success: function(result) {
            const {
              serviceProviderCode,
              packageTypeCode,
              serviceProviderIconPath,
              packageTypeTitle,
              serviceProviderTitle,
              autoRecharge
            } = result
            // 已开通提示
            if (autoRecharge === 'Y') {
              dispatch({
                type: TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_MOBILE_PACKAGE_SUCCESS,
                payload: {
                  msg: intl.formatMessage(messages.activated)
                }
              })
              dispatch(initPage(false))
              Toast.hide()
              return
            }
            if (serviceProviderCode && packageTypeCode) {
              const defaultUserPackage = _.pickBy(
                {
                  serviceProvider: serviceProviderCode,
                  packageType: packageTypeCode,
                  chosenIconUrl: serviceProviderIconPath,
                  title:
                    serviceProviderTitle &&
                    packageTypeTitle &&
                    `${serviceProviderTitle}·${packageTypeTitle}`,
                  phone: data.phone
                },
                _.identity
              )
              dispatch(queryGoods(defaultUserPackage, callback))
              return
            }
            // 无套餐信息让用户自行选择
            dispatch({
              type: TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_MOBILE_PACKAGE_SUCCESS,
              payload: {
                defaultUserPackage: {
                  selectOperator: true
                }
              }
            })
            Toast.hide()
          }
        })
      )
    })
  }
}

export function queryGoods(data, callback) {
  // 查询话费商品
  // Toast.loading('', 0)
  return (dispatch, getState) => { // 设置套餐类型
    const notHasDefaultPackage = !_.size(
      getState().topUpAutoRechargeActivateState.defaultUserPackage
    )
    dispatch(getSalt()).then((res) => {
      const { salt } = res.payload
      const { phone, chosenIconUrl, title, ...resData } = data
      console.log(phone)
      resData.phone = encrypt(phone, salt)
      dispatch(
        callAPI({
          dispatch: dispatch,
          endpoint: '/cgs/api/lifecenter/queryMobileGoodsList',
          method: 'POST',
          body: {
            ...resData,
            queryType: 'MOBILE_TOPUP_AUTO_RECHARGE'
          },
          requestAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_GOODS_REQUEST,
          successAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_GOODS_SUCCESS,
          success: function(result) {
            dispatch(
              initPage(
                false,
                notHasDefaultPackage && {
                  defaultUserPackage: data
                }
              )
            )
            Toast.hide()
            typeof callback === 'function' && callback(result, data.packageType)
          },
          error: function(result) {
            dispatch(
              initPage(
                false,
                notHasDefaultPackage && {
                  defaultUserPackage: data
                }
              )
            )
            Toast.hide()
          }
        })
      )
    })
  }
}

export function queryConfigDetail(data) {
  // 查询自动开通配置详情
  return (dispatch, getState) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/autoRecharge/queryConfigDetail',
        method: 'POST',
        body: data,
        requestAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_CONFIG_DETAIL_REQUEST,
        successAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_CONFIG_DETAIL_SUCCESS,
        success: function(result) {
          const { targetNo, packageType, serviceProvider, accountIconUrl } = result.config
          const defaultUserPackage = {
            phone: targetNo,
            packageType,
            serviceProvider,
            chosenIconUrl: accountIconUrl
          }
          dispatch(queryGoods(defaultUserPackage))
        }
      })
    )
  }
}

export function open(data) {
  Toast.loading('', 0)
  return (dispatch) => {
    dispatch(getSalt()).then((res) => {
      const { salt } = res.payload
      const { phone, ...resData } = data
      console.log(phone)
      resData.targetNo = encrypt(phone, salt)
      dispatch(
        callAPI({
          dispatch: dispatch,
          endpoint: `/cgs/api/lifecenter/autoRecharge/${data.configId ? 'update' : 'open'}`,
          method: 'POST',
          body: {
            ...resData,
            accountType: 'MOBILE_TOP_UP'
          },
          requestAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.OPEN_REQUEST,
          successAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.OPEN_SUCCESS,
          success: function(result) {
            Toast.hide()
            const { openStatus, pendingStatus, identifyHint, protocolToken, requestNo, configId, failMsg } = result
            switch (openStatus) {
              case 'SUCCESS':
                dispatch(replace(`/topUp/auto-recharge/result?configId=${configId}`))
                break
              case 'FAIL':
                dispatch(replace(`/topUp/auto-recharge/result?msg=${failMsg}`))
                break
              default:
                switch (pendingStatus) {
                  case 'AUTO_DEBIT_IDENTIFY_HINT':
                    // 调用native核身方法
                    openRiskIdentify(
                      {
                        eventType: 'PAYMENT', // TODO
                        ...identifyHint
                      },
                      () => {
                        dispatch(autoRechargeAdvance({ requestNo }))
                      }
                    )
                    break
                  case 'PROTOCOL_SIGN':
                    dispatch(
                      replace(
                        `/topUp/auto-recharge/sign/${protocolToken}?requestNo=${requestNo}`
                      )
                    )
                    break
                  default:
                    break
                }
                break
            }
          }
        })
      )
    })
  }
}

export function disableConfig(data) { // 取消自动充值配置
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/autoRecharge/close',
        method: 'POST',
        body: {
          operatorType: 'DISABLE',
          ...data
        },
        requestAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.DISABLE_CONFIG_REQUEST,
        successAction: TOP_UP_AUTO_RECHARGE_ACTIVATE.DISABLE_CONFIG_SUCCESS,
        success: function(result) {
          dispatch(replace('/topUp/auto-recharge/list'))
        }
      })
    )
  }
}

export function initPage(initial, data) {
  console.log(data)
  return {
    type: TOP_UP_AUTO_RECHARGE_ACTIVATE.INIT_PAGE,
    payload: {
      initPage: !!initial,
      ...data
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.INIT_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_MOBILE_PACKAGE_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_MOBILE_PACKAGE_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_GOODS_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_GOODS_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_CONFIG_DETAIL_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.QUERY_CONFIG_DETAIL_SUCCESS]: (state, action) => {
    const { config = {} } = action.payload
    config.autoPaymentDay = _.chain(DateDICT)
      .filter((item) => item.replace(/[a-z]+/, '') === config.autoPaymentDay)
      .head()
      .value()
    console.log(config)
    return Object.assign({}, state, action.payload, config)
  },
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.OPEN_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.OPEN_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.DISABLE_CONFIG_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.DISABLE_CONFIG_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_AUTO_RECHARGE_ACTIVATE.INIT_PAGE]: (state, action) => {
    const { initPage } = action.payload // 查询商品时页面默认商品置灰
    if (initPage) {
      action.payload.defaultUserPackage = {}
      action.payload.goodsInfos = []
    }
    return Object.assign({}, state, action.payload)
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  initPage: true
}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topUpAutoRechargeActivate',
    reducerMap: {
      topUpAutoRechargeActivateState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
