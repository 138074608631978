import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, goBack } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const UTILITY_SET_ALIAS = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null
}, 'UTILITY_SET_ALIAS')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: 'test.json',
      requestAction: UTILITY_SET_ALIAS.INIT_REQUEST,
      successAction: UTILITY_SET_ALIAS.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

export function updateAlias(data) {
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/updateAccountAlias',
        method: 'POST',
        body: data,
        requestAction: UTILITY_SET_ALIAS.SUBMIT_REQUEST,
        successAction: UTILITY_SET_ALIAS.SUBMIT_SUCCESS,
        success: function(result) {
          dispatch(goBack())
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UTILITY_SET_ALIAS.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UTILITY_SET_ALIAS.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UTILITY_SET_ALIAS.SUBMIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UTILITY_SET_ALIAS.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'utilitySetAlias',
    reducerMap: {
      utilitySetAliasState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
