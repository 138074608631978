import React from 'react'
import { connect } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import modules, { init, showPage } from './modules'
import Loading from 'components/Loading'
import { languageChange, queryKycStatus } from 'store/siteConfig'

export default function(route) {
  const Referrer = Loadable({
    loader: () => import('./Referrer'),
    render(loaded, props) {
      const Component = connect(
        state => ({
          kycBasicReferrerState: state.kycBasicReferrerState,
          siteConfig: state.siteConfig
        }),
        {
          init,
          languageChange,
          queryKycStatus,
          showPage
        }
      )(loaded.default)
      return <Component {...props} />
    },
    loading: Loading
  })

  return (
    <Route
      path={`${route.match.path}/referrer`}
      render={routeProps => (
        <DynamicModuleLoader modules={[modules(routeProps.history)]}>
          <Referrer {...routeProps} />
        </DynamicModuleLoader>
      )}
    />
  )
}
