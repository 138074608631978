import React from 'react'
import { Route, Switch } from 'react-router-dom'

const AppRouter = route => {
  return (
    <Switch>
      <Route
        path={`${route.match.path}/payby`}
        render={routeProps => (
          <React.Fragment>
            {require('./CustomerService').default(routeProps)}
            {require('./Landing').default(routeProps)}
            {require('./About').default(routeProps)}
            {require('./UpgradeApp').default(routeProps)}
            <Route
              path='/platform/payby/fAQs'
              component={require('./FAQs').default}
            />
          </React.Fragment>
        )}
      />
    </Switch>
  )
}

export default AppRouter
