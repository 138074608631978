import React from 'react'
import connect from 'redux-connect-decorator'
import { LocaleProvider } from 'antd-mobile'
import { IntlProvider } from 'react-intl'
import { ConnectedRouter } from 'connected-react-router'
import AppRouter from 'routes'
import AppLanguage from 'languages'
import './styles/index.module.scss'
import './styles/iconfont.css'
import './styles/font-impact.css'

@connect(
  (state) => ({
    siteConfig: state.siteConfig
  }),
  {}
)
class App extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    // 语言没切换的时候就不做update
    return this.props.siteConfig.language !== nextProps.siteConfig.language
  }

  render() {
    const { siteConfig, history } = this.props
    const lang = siteConfig.language
    return (
      <LocaleProvider locale={AppLanguage.antd[lang]} key={lang}>
        <IntlProvider
          locale={lang}
          key={lang}
          messages={AppLanguage['react-intl'][lang].messages}
        >
          <ConnectedRouter history={history}>
            <AppRouter history={history} />
          </ConnectedRouter>
        </IntlProvider>
      </LocaleProvider>
    )
  }
}

export default App
