import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { NoticeBar as BaseNotiveBar, Carousel } from 'antd-mobile'
import _ from 'lodash'

export default class NoticeBar extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { data } = this.props
    const notices = _.chain(data)
      .filter({ templateType: 'NOTICE' })
      .map('noticeMsg')
      .value()
    if (!notices.length) {
      return null
    }
    return (
      <BaseNotiveBar
        className={`${classes.componentsNoticeBar} ${notices[0].level}`}
        icon={<span className='iconfont font-sm iconNotice' />}
      >
        <Carousel
          vertical
          dots={false}
          dragging={false}
          swiping={false}
          autoplay
          infinite
        >
          {notices.map((item, index) => (
            <div key={index}>{item.text}</div>
          ))}
        </Carousel>
      </BaseNotiveBar>
    )
  }
}
