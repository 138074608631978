import React from 'react'
import classes from './styles.module.scss'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { Flex, WingBlank, WhiteSpace, List, Icon } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'

class FAQs extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { data, history, root, headline, path, service, location } = this.props
    return (
      <Flex.Item className={classes.componentsFAQs}>
        {
          root && (
            <React.Fragment>
              <WhiteSpace className='bg-gray' />
              <WhiteSpace />
              <WhiteSpace />
              <WhiteSpace size='lg' />
              <h4 className='text-center'>
                <FormattedMessage {...messages.headline} values={{ name: headline }} />
              </h4>
              <WhiteSpace size='lg' />
              <WhiteSpace size='lg' />
            </React.Fragment>
          )
        }
        <WingBlank>
          <List className='no-border'>
            {
              data.map(item => (
                <List.Item key={item.title} arrow='horizontal' wrap onClick={() => history.push(`${path}?type=${item.url}`)}>
                  <b>
                    {item.title}
                  </b>
                </List.Item>
              ))
            }
          </List>
        </WingBlank>
        {
          service && (
            <div className={classes.service}>
              <Link to={`/platform/${service}/customerService${location.search}`}>
                <img className='service' src={require('./service.png')} />
                <FormattedMessage {...messages.service} />
                <Icon type='right' />
              </Link>
            </div>
          )
        }
      </Flex.Item>
    )
  }
}

export default withRouter(FAQs)
