import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { WhiteSpace } from 'antd-mobile'

export default class BankCardTypePrompt extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    return (
      <div className={classes.componentsBankCardTypePrompt}>
        <p className='font-xs green'>Card Types</p>
        <WhiteSpace size='md' />
        <div>
          <img height={18} src={require('./images/VISA.png')} />
          <img height={18} src={require('./images/MasterCard.png')} />
          <span />
          <img height={18} src={require('./images/VISA2.png')} />
          <img height={18} src={require('./images/MasterCard2.png')} />
        </div>
      </div>
    )
  }
}
