import React from 'react'
import { Route, Switch } from 'react-router-dom'
// import PageLayout from 'layouts/PageLayout'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path='/utility/parking'
        render={routeProps => (
          <div>
            {require('./Select').default(routeProps)}
            {require('./TopUp').default(routeProps)}
            {require('./Fine').default(routeProps)}
          </div>
        )}
      />
    </Switch>
  )
}

export default AppRouter
