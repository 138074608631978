import { defineMessages } from 'react-intl'

export default defineMessages({
  text1: {
    id: 'components.CustomerService.text1',
    defaultMessage: 'Business Hours',
    description: '工作时间'
  },
  text2: {
    id: 'components.CustomerService.text2',
    defaultMessage: 'From Sunday to Thursday 9 AM to 6 PM UAE Time.',
    description: '文案2'
  },
  text3: {
    id: 'components.CustomerService.text3',
    defaultMessage: 'Other time we will respond on the next working day.',
    description: '文案3'
  },
  contactPayBy: {
    id: 'components.CustomerService.contactPayBy',
    defaultMessage: 'For all questions regarding PayBy payment service,',
    description: '联系payby'
  },
  pleaseContact: {
    id: 'components.CustomerService.pleaseContact',
    defaultMessage: 'please contact us at',
    description: '请联系我们'
  },
  email: {
    id: 'components.CustomerService.email',
    defaultMessage: 'Email',
    description: '邮件'
  },
  phone: {
    id: 'components.CustomerService.phone',
    defaultMessage: 'Phone Call',
    description: '电话'
  },
  help: {
    id: 'components.CustomerService.help',
    defaultMessage: 'payhelp',
    description: '求助'
  },
  tips: {
    id: 'components.CustomerService.tips',
    defaultMessage: 'Copy successfully',
    description: '提示'
  },
  whatsApp: {
    id: 'components.CustomerService.whatsApp',
    defaultMessage: 'WhatsApp:',
    description: '电话'
  }
})
