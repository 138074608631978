import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const TOPIC_TAKEAWAY__ORDERINFO = keyMirror(
  {
    INIT_REQUEST : null,
    INIT_SUCCESS : null
  },
  'TOPIC_TAKEAWAY__ORDERINFO'
)

// ------------------------------------
// Actions
// ------------------------------------
export function init(token) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch      : dispatch,
        endpoint      : '/jollychic-service/acquire2/detail?token=' + token,
        requestAction : TOPIC_TAKEAWAY__ORDERINFO.INIT_REQUEST,
        successAction : TOPIC_TAKEAWAY__ORDERINFO.INIT_SUCCESS,
        success       : function(result) {},
        error         : function(result) {
          dispatch({
            type: TOPIC_TAKEAWAY__ORDERINFO.INIT_REQUEST,
            payload: result
          })
          console.log(result)
        }
      })
    )
  }
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOPIC_TAKEAWAY__ORDERINFO.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [TOPIC_TAKEAWAY__ORDERINFO.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id          : 'topicTakeawayOrderInfo',
    reducerMap  : {
      topicTakeawayOrderInfoState : reducer
    },
    // middlewares when this module needed
    middlewares : [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
