import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import _ from 'lodash'
import queryString from 'query-string'
import { getSalt } from 'store/siteConfig'
import { encrypt } from 'utils/rsa'
import { Toast } from 'antd-mobile'
import { ToPayRequest } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------
export const TOP_UP_DATA = keyMirror(
  {
    INIT_REQUEST: null,
    INIT_SUCCESS: null,
    SUBMIT_REQUEST: null,
    SUBMIT_SUCCESS: null,
    QUERY_MOBILE_PACKAGE_REQUEST: null,
    QUERY_MOBILE_PACKAGE_SUCCESS: null,
    INIT_PAGE: null,
    QUERY_MOBILE_PACKAGE_LIST_REQUEST: null,
    QUERY_MOBILE_PACKAGE_LIST_SUCCESS: null
  },
  'TOP_UP_DATA'
)

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(getSalt()).then((res) => {
      const { salt } = res.payload
      console.log(salt)
      const { phone, chosenIconUrl, title, ...resData } = data
      console.log(phone)
      resData.phone = encrypt(phone, salt)
      dispatch(
        callAPI({
          dispatch: dispatch,
          endpoint: '/cgs/api/lifecenter/queryMobileTopUpGoods',
          method: 'POST',
          body: {
            ...resData,
            categoryCode: '000100010002'
          },
          requestAction: TOP_UP_DATA.INIT_REQUEST,
          successAction: TOP_UP_DATA.INIT_SUCCESS,
          success: function(result) {
            Toast.hide()
            dispatch({
              type: TOP_UP_DATA.QUERY_MOBILE_PACKAGE_SUCCESS,
              payload: { defaultUserPackage: data }
            })
            // sessionStorage.setItem('defaultUserPackage', JSON.stringify(data))
            dispatch(initPage(false))
          },
          error: function(result) {
            Toast.hide()
            dispatch({
              type: TOP_UP_DATA.QUERY_MOBILE_PACKAGE_SUCCESS,
              payload: {
                defaultUserPackage: data,
                msg: result.head.msg
              }
            })
            dispatch(initPage(false))
          }
        })
      )
    })
  }
}

export function queryMobilePackage(data) {
  // 查询套餐信息
  Toast.loading('', 0)
  return (dispatch) => {
    dispatch(getSalt()).then((res) => {
      const { salt } = res.payload
      console.log(salt)
      const phone = encrypt(data.phone, salt)
      console.log(data.phone)
      dispatch(
        callAPI({
          dispatch: dispatch,
          endpoint: '/cgs/api/lifecenter/queryMobilePackage',
          method: 'POST',
          body: {
            phone,
            queryChannelFlag: false
          },
          requestAction: TOP_UP_DATA.QUERY_MOBILE_PACKAGE_REQUEST,
          successAction: TOP_UP_DATA.QUERY_MOBILE_PACKAGE_SUCCESS,
          success: function(result) {
            const {
              serviceProviderCode,
              packageTypeCode,
              serviceProviderIconPath,
              packageTypeTitle,
              serviceProviderTitle
            } = result
            if (serviceProviderCode && packageTypeCode) {
              const defaultUserPackage = {
                serviceProvider: serviceProviderCode,
                packageType: packageTypeCode,
                chosenIconUrl: serviceProviderIconPath,
                title: `${serviceProviderTitle}·${packageTypeTitle}`,
                phone: data.phone
              }
              dispatch(init(defaultUserPackage))
              return
            }
            // 无套餐信息主动弹窗让用户自行选择
            dispatch({
              type: TOP_UP_DATA.QUERY_MOBILE_PACKAGE_SUCCESS,
              payload: {
                defaultUserPackage: {
                  selectOperator: true
                }
              }
            })
            Toast.hide()
          }
        })
      )
    })
  }
}

export function submit(data, callback) {
  // 下单
  Toast.loading('', 0)
  return (dispatch) => {
    dispatch(getSalt()).then((res) => {
      const { salt } = res.payload
      console.log(salt)
      const { phone, ...resData } = data
      console.log(phone)
      resData.targetPhone = encrypt(phone, salt)
      dispatch(
        callAPI({
          dispatch: dispatch,
          method: 'POST',
          body: resData,
          endpoint: '/cgs/api/lifecenter/createMobileTopUpOrder',
          requestAction: TOP_UP_DATA.SUBMIT_REQUEST,
          successAction: TOP_UP_DATA.SUBMIT_SUCCESS,
          success: function(result) {
            typeof callback === 'function' && callback()
            const tokenUrl = _.chain(result).get('token').value()
            const token = queryString.parse(tokenUrl).ft
            ToPayRequest(token, (res) => {
              if (res.status === 'success' || res.status === 'paying') {
                dispatch(replace(`/topUp/result/${result.orderNo}`))
              }
            })
          }
        })
      )
    })
  }
}

export function initPage(data) {
  console.log(data)
  return {
    type: TOP_UP_DATA.INIT_PAGE,
    payload: {
      initPage: !!data
    }
  }
}

export function queryMobilePackageList() {
  // 初始化获取套餐列表
  Toast.loading('', 0)
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/initMobileTopUpPageV2',
        method: 'POST',
        requestAction: TOP_UP_DATA.QUERY_MOBILE_PACKAGE_LIST_REQUEST,
        successAction: TOP_UP_DATA.QUERY_MOBILE_PACKAGE_LIST_SUCCESS,
        success: function(result) {
          const { defaultUserPackage } = result
          if (_.size(defaultUserPackage) < 3) {
            setTimeout(() => {
              dispatch({
                type: TOP_UP_DATA.QUERY_MOBILE_PACKAGE_SUCCESS,
                payload: {
                  defaultUserPackage: {
                    ...defaultUserPackage,
                    selectOperator: true
                  }
                }
              })
            }, 500)
            Toast.hide()
            return
          }
          dispatch(init(defaultUserPackage))
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_UP_DATA.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [TOP_UP_DATA.INIT_SUCCESS]: (state, action) => {
    console.log(action.payload)
    const { goodsList = [] } = action.payload
    const goodsSectionList = []
    if (_.size(goodsList)) {
      goodsSectionList[0] = {
        title: 'Data',
        categoryCode: '000100010002',
        goodsList
      }
      action.payload.goodsSectionList = goodsSectionList
    }
    return Object.assign({}, state, action.payload)
  },
  [TOP_UP_DATA.QUERY_MOBILE_PACKAGE_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_DATA.QUERY_MOBILE_PACKAGE_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_DATA.SUBMIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_DATA.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_DATA.INIT_PAGE]: (state, action) => {
    const { initPage } = action.payload
    if (initPage) {
      action.payload.defaultUserPackage = {}
      action.payload.goodsSectionList = []
    }
    return Object.assign({}, state, action.payload)
  },
  [TOP_UP_DATA.QUERY_MOBILE_PACKAGE_LIST_REQUEST]: (state, action) => state,
  [TOP_UP_DATA.QUERY_MOBILE_PACKAGE_LIST_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  initPage: true
}

function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topUpData',
    reducerMap: {
      topUpDataState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
