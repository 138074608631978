import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import { Toast } from 'antd-mobile'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import queryString from 'query-string'
import { intl, isPayBy } from 'utils'
import messages from './messages'

// ------------------------------------
// Constants
// ------------------------------------
export const SMART_CODE = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null,
}, 'SMART_CODE')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/fixqr/query',
        body: data,
        requestAction: SMART_CODE.INIT_REQUEST,
        successAction: SMART_CODE.INIT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function submit(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/fixqr/acquire/placeOrder',
        body: data,
        requestAction: SMART_CODE.SUBMIT_REQUEST,
        successAction: SMART_CODE.SUBMIT_SUCCESS,
        success: function(result) {
          if (!result.token) { // 避免因账户异常导致下单没返回token，安卓崩溃的问题
            Toast.info(intl.formatMessage(messages.error))
            return
          }
          if (!isPayBy()) {
            window.location.href = result.token
            return
          }
          Toast.hide()
          const token = queryString.parse(result.token).ft
          window.ToPayJSBridge &&
            window.ToPayJSBridge.invoke(
              'ToPayRequest',
              {
                appId: '',
                token: token,
              },
              (data) => {
                window.ToPayJSBridge.invoke('leaveWeb')
              }
            )
        },
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SMART_CODE.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [SMART_CODE.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [SMART_CODE.SUBMIT_REQUEST]: (state, action) => state,
  [SMART_CODE.SUBMIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'smartCode',
    reducerMap: {
      smartCodeState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
