import { apiMiddleware } from 'redux-api-middleware'
import { replace, routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { getCaptcha, getAppSalt } from 'store/siteConfig'
import queryString from 'query-string'
import { Toast } from 'antd-mobile'
import { getUUID } from 'utils'
import { encrypt } from 'utils/rsa'

// ------------------------------------
// Constants
// ------------------------------------
export const LOGIN = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SEND_SMS_REQUEST: null,
  SEND_SMS_SUCCESS: null,
  VERIFY_SMS_REQUEST: null,
  VERIFY_SMS_SUCCESS: null,
}, 'LOGIN')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: 'test.json',
      requestAction: LOGIN.INIT_REQUEST,
      successAction: LOGIN.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

export function sendSms(data, callback) {
  return (dispatch) => {
    const saltKey = getUUID()
    dispatch(getAppSalt(saltKey)).then((res) => {
      const { salt } = res.payload
      if (!salt) {
        typeof callback === 'function' && callback()
        return dispatch(getCaptcha())
      }
      const { phoneNo, ...restData } = data
      restData.phoneNo = encrypt(phoneNo, salt)
      dispatch(
        callAPI({
          dispatch: dispatch,
          header: {
            'Salt-Key': saltKey,
          },
          method: 'POST',
          body: restData,
          endpoint: '/cgs/api/personal/otpLoginSendSms',
          requestAction: LOGIN.SEND_SMS_REQUEST,
          successAction: LOGIN.SEND_SMS_SUCCESS,
          success: function(result) {
            if (result.needCaptcha === 'Y') {
              typeof callback === 'function' && callback()
              dispatch(getCaptcha())
            }
          },
          error: function(result, msg) {
            Toast.info(msg)
            dispatch(getCaptcha())
            typeof callback === 'function' && callback()
          },
        })
      )
    })
  }
}

export function verifySms(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: data,
        endpoint: '/cgs/api/personal/otpLoginVerifySms',
        requestAction: LOGIN.VERIFY_SMS_REQUEST,
        successAction: LOGIN.VERIFY_SMS_SUCCESS,
        success: function(result) {
          const params = queryString.parse(window.location.search)
          dispatch(replace(decodeURIComponent(params.redirect_url)))
        },
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOGIN.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [LOGIN.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [LOGIN.SEND_SMS_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [LOGIN.SEND_SMS_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [LOGIN.VERIFY_SMS_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [LOGIN.VERIFY_SMS_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'login',
    reducerMap: {
      loginState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
