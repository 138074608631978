import { messages } from 'languages'
import keyMirror from 'pkeymirror'
import callAPI from 'utils/callAPI'
import { getCurrentLanguage, setHtmlLangAndDir, getBotimClientId, isPayBy, intl, openNative } from 'utils'
import sha256 from 'sha256'
import { replace } from 'connected-react-router'
import _ from 'lodash'
import { Modal, Toast } from 'antd-mobile'

// ------------------------------------
// Constants
// ------------------------------------
export const SITE_CONFIG = keyMirror({
  LANGUAGE_CHANGE: null,
  APPAUTHORIZE_REQUEST: null,
  APPAUTHORIZE_SUCCESS: null,
  SAVE_ACCESSINFO: null,
  SENDSMS_REQUEST: null,
  SENDSMS_SUCCESS: null,
  GET_SALT_REQUEST: null,
  GET_SALT_SUCCESS: null,
  REMOVE_SALT: null,
  QUERY_KYC_STATUS_REQUEST: null,
  QUERY_KYC_STATUS_SUCCESS: null,
  QUERY_AGREEMENTS_REQUEST: null,
  QUERY_AGREEMENTS_SUCCESS: null,
  QUERY_NOTICE_REQUEST: null,
  QUERY_NOTICE_SUCCESS: null,
  OAUTH_BY_AUTHCODE_REQUEST: null,
  OAUTH_BY_AUTHCODE_SUCCESS: null,
  QUERY_BILL_INFO_REQUEST: null,
  QUERY_BILL_INFO_SUCCESS: null,
  UPLOAD_REQUEST: null,
  UPLOAD_SUCCESS: null,
  SET_LOADING_VISIBLE: null,
  GET_CAPTCHA_REQUEST: null,
  GET_CAPTCHA_SUCCESS: null,
  DELELE_ACCOUNT_REQUEST: null,
  DELELE_ACCOUNT_SUCCESS: null,
  UPDATE_ALIAS_REQUEST: null,
  UPDATE_ALIAS_SUCCESS: null,
  CHECKISLOGIN_REQUEST: null,
  CHECKISLOGIN_SUCCESS: null,
  AUTO_RECHARGE_ADVANCE_REQUEST: null,
  AUTO_RECHARGE_ADVANCE_SUCCESS: null,
  QUERY_AREACODE_REQUEST: null,
  QUERY_AREACODE_SUCCESS: null,
  QUERY_CONFIG_ITEM_REQUEST: null,
  QUERY_CONFIG_ITEM_SUCCESS: null,
  BEFORE_CHECK_REQUEST: null,
  BEFORE_CHECK_SUCCESS: null,
  CHECK_EXIST_APPEAL_REQUEST: null,
  CHECK_EXIST_APPEAL_SUCCESS: null
}, 'SITE_CONFIG')

// ------------------------------------
// Actions
// ------------------------------------
export function languageChange(lang = 'en') {
  const language = lang.split(/[-_]/)[0].toLowerCase()
  window.language = language
  setHtmlLangAndDir(language)
  return (dispatch) => {
    dispatch({
      type    : SITE_CONFIG.LANGUAGE_CHANGE,
      payload : language
    })
  }
}

function showFailedToast() {
  // 登陆失败，浮层提示保持以阻断用户操作
  Toast.info(
    intl.formatMessage(messages['app.error.expired']),
    0
  )
}

export function getVerifyToken() {
  return (dispatch) => {
    return new Promise(resolve => {
      if (process.env.NODE_ENV === 'development') {
        // 测试用户：100000081917 971140252158413237 +971-585806138
        return resolve({
          codeVerifier: 'test',
          verifyToken: '719f758aa650403aad6f0ae92cebc6a0'
        })
      }
      function onBridgeReady() {
        // Bridge方法注册成功
        console.log('ToPayJSBridge:start')
        try {
          // window.ToPayJSBridge.init 方法二次调用的时候会报错
          window.ToPayJSBridge.init(function(message, responseCallback) {})
        } catch (error) {
          console.log(error)
        }
        const random = Math.random().toString(16).slice(-8)
        window.ToPayJSBridge.invoke(
          'getVerifyToken',
          {
            codeChallenge: sha256(random),
            appDomain: window.location.hostname,
          },
          function(data) {
            if (data) {
              const res = JSON.parse(data)
              if (res && res.verifyToken) {
                return resolve({
                  codeVerifier: random,
                  verifyToken: res.verifyToken,
                })
              } else {
                showFailedToast()
              }
            } else {
              showFailedToast()
            }
          }
        )
      }
      console.log(typeof ToPayJSBridge)
      if (typeof ToPayJSBridge === 'undefined') {
        document.addEventListener('ToPayJSBridgeReady', onBridgeReady, false) // ToPayJSBridgeReady
      } else {
        onBridgeReady()
      }
    })
  }
}

var loginable = true // 并发的登录请求，只允许一次，避免多次请求导致前面的token失效
export function login() {
  if (!loginable) {
    return
  }
  return (dispatch) => {
    loginable = false
    if (!isPayBy()) {
      Toast.hide()
      // 新增了OPT登陆流程，PayBy webview中走自动登录，非PayBy webview走OTP登陆流程
      const url = `${window.location.pathname}${window.location.search}`
      return dispatch(replace(`/login?redirect_url=${encodeURIComponent(url)}`))
    }
    Toast.loading('', 0)
    return dispatch(getVerifyToken()).then((res) => {
      dispatch(
        callAPI({
          dispatch: dispatch,
          endpoint: '/cgs/api/h5app/auth/v2/verify',
          body: {
            verifyToken: res.verifyToken,
            codeVerifier: res.codeVerifier
          },
          method: 'POST',
          requestAction: SITE_CONFIG.APPAUTHORIZE_REQUEST,
          successAction: SITE_CONFIG.APPAUTHORIZE_SUCCESS,
          success: function(result) {
            console.log(result)
            Toast.hide()
            window.firebase.analytics().setUserId(result.userId)
            localStorage.setItem('user_id', result.userId)
            dispatch(saveAccessInfo(res)) // TODO HOST-app
            dispatch(
              replace(`${window.location.pathname}${window.location.search}`)
            )
          },
          error: () => {
            showFailedToast()
          }
        })
      )
    })
  }
}

export function checkIsLogin(callback) {
  return (dispatch) => {
    return dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/common/checkLogon',
        requestAction: SITE_CONFIG.CHECKISLOGIN_REQUEST,
        successAction: SITE_CONFIG.CHECKISLOGIN_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback()
        }
      })
    )
  }
}

export function saveAccessInfo(data) { // oauth隐式授权需要codeVerifier
  return {
    type: SITE_CONFIG.SAVE_ACCESSINFO,
    payload: data
  }
}

export function sendSms(data, callback) {
  // 发短信
  const token = sha256(Math.random().toString(16).slice(-8))
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/otps/mns/sendbytarget',
        body: {
          target: `+971-${data.phone.replace(/ /g, '')}`,
          token: token
        },
        method: 'POST',
        requestAction: SITE_CONFIG.SENDSMS_REQUEST,
        successAction: SITE_CONFIG.SENDSMS_SUCCESS,
        success: function(result) {
          sessionStorage.setItem('token', token)
          typeof callback === 'function' && callback()
        }
      })
    )
  }
}

export function getSalt(callback) {
  return (dispatch) => {
    return dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/common/getSalt',
        requestAction: SITE_CONFIG.GET_SALT_REQUEST,
        successAction: SITE_CONFIG.GET_SALT_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback(result)
        },
      })
    )
  }
}

export function getAppSalt(saltKey) {
  return (dispatch) => {
    return dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: {
          saltKey,
        },
        endpoint: '/cgs/api/common/getAppSalt',
        requestAction: SITE_CONFIG.GET_SALT_REQUEST,
        successAction: SITE_CONFIG.GET_SALT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function removeSalt() {
  return (dispatch) => {
    dispatch({
      type: SITE_CONFIG.REMOVE_SALT,
    })
  }
}

export function queryKycStatus(callback) {
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/kyc/kycInfo',
        method: 'POST',
        requestAction: SITE_CONFIG.QUERY_KYC_STATUS_REQUEST,
        successAction: SITE_CONFIG.QUERY_KYC_STATUS_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback(result)
        }
      })
    )
  }
}

export function queryAgreements(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: data,
        endpoint: '/cgs/api/personal/common/contract/query',
        requestAction: SITE_CONFIG.QUERY_AGREEMENTS_REQUEST,
        successAction: SITE_CONFIG.QUERY_AGREEMENTS_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function queryNotice(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: data,
        endpoint: '/cgs/api/personal/notice/pull',
        requestAction: SITE_CONFIG.QUERY_NOTICE_REQUEST,
        successAction: SITE_CONFIG.QUERY_NOTICE_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function oauthByAuthCode(data, callback) {
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/auth/followOA', // 非授权接口 关注公众号
        method: 'POST',
        body: {
          hostApp: 'botim-pay',
          redirectUri: 'botmpx://me.botim.open.authorizer/index.html',
          clientId: getBotimClientId(),
          ...data
        },
        requestAction: SITE_CONFIG.OAUTH_BY_AUTHCODE_REQUEST,
        successAction: SITE_CONFIG.OAUTH_BY_AUTHCODE_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback()
        }
      })
    )
  }
}

export function queryBillInfo(data, callback) {
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: isPayBy()
          ? '/cgs/api/lifecenter/queryBillInfo'
          : '/cgs/api/lifecenter/v1/unauth/query-bill-info',
        method: 'POST',
        body: {
          ...data
        },
        requestAction: SITE_CONFIG.QUERY_BILL_INFO_REQUEST,
        successAction: SITE_CONFIG.QUERY_BILL_INFO_SUCCESS,
        success: function(result) {
          Toast.hide()
          if (!_.size(result) || !_.get(result, 'billAmount')) {
            Modal.alert('', intl.formatMessage(messages['app.error.noDueAmount']), [
              {
                text: 'OK'
              }
            ])
            return
          }
          callback()
        }
      })
    )
  }
}

export function upload(data, callback) { // 文件上传 登录状态下
  const { login, ...resData } = data
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: login === 'N' ? '/cgs/uploadUn' : '/cgs/upload',
        type: 'multipart/form-data',
        method: 'POST',
        body: resData,
        requestAction: SITE_CONFIG.UPLOAD_REQUEST,
        successAction: SITE_CONFIG.UPLOAD_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback(result)
        }
      })
    )
  }
}

export function setLoadingVisible(visible) {
  return {
    type: SITE_CONFIG.SET_LOADING_VISIBLE,
    payload: visible
  }
}

export function getCaptcha() {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/personal/getCaptcha',
        requestAction: SITE_CONFIG.GET_CAPTCHA_REQUEST,
        successAction: SITE_CONFIG.GET_CAPTCHA_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function deleteAccount(data, callback) { // 删除账户
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/deleteAccount',
        method: 'POST',
        body: data,
        requestAction: SITE_CONFIG.DELELE_ACCOUNT_REQUEST,
        successAction: SITE_CONFIG.DELELE_ACCOUNT_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback()
        }
      })
    )
  }
}

export function updateAlias(data, callback) { // 修改昵称
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/updateAccountAlias',
        method: 'POST',
        body: data,
        requestAction: SITE_CONFIG.UPDATE_ALIAS_REQUEST,
        successAction: SITE_CONFIG.UPDATE_ALIAS_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback()
        }
      })
    )
  }
}

export function autoRechargeAdvance(data, callback) {
  // 自动充值推进
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/autoRecharge/advance',
        method: 'POST',
        body: data,
        requestAction: SITE_CONFIG.AUTO_RECHARGE_ADVANCE_REQUEST,
        successAction: SITE_CONFIG.AUTO_RECHARGE_ADVANCE_SUCCESS,
        success: function(result) {
          // typeof callback === 'function' && callback()
          const { openStatus, configId, failMsg } = result // 成功返回configId,失败返回错误原因
          if (openStatus === 'SUCCESS') {
            dispatch(replace(`/topUp/auto-recharge/result?configId=${configId}`))
          } else if (openStatus === 'FAIL') {
            dispatch(replace(`/topUp/auto-recharge/result?msg=${failMsg}`))
          }
        }
      })
    )
  }
}

export function queryAreaCode() {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cmsii/country/queryAreaCode',
        method: 'POST',
        body: {
          scene: 'LOGIN',
        },
        requestAction: SITE_CONFIG.QUERY_AREACODE_REQUEST,
        successAction: SITE_CONFIG.QUERY_AREACODE_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function queryConfigItem(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cmsii/v1/app/configurationItem',
        method: 'POST',
        body: data,
        requestAction: SITE_CONFIG.QUERY_CONFIG_ITEM_REQUEST,
        successAction: SITE_CONFIG.QUERY_CONFIG_ITEM_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function beforeCheck(data) {
  // 前置公共功能校验接口
  // http://wiki.test2pay.com/pages/viewpage.action?pageId=10231757
  return (dispatch) => {
    return dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/beforeCheck',
        method: 'POST',
        body: data,
        requestAction: SITE_CONFIG.BEFORE_CHECK_REQUEST,
        successAction: SITE_CONFIG.BEFORE_CHECK_SUCCESS,
        success: function(result) {
          console.log(result)
          switch (result.status) {
            case 'TIPS':
            case 'REJECT': {
              if (result.status === 'REJECT' && !result.routing.tips) {
                // 当状态未REJECT，但是routing依然包含tips的情况下，弹窗提示
                openNative(result.routing.routingKey)
                break
              }
              const ACTIONS = [
                {
                  text: result.routing.confirmBtn,
                  onPress: () => openNative(result.routing.routingKey),
                },
              ]
              if (result.status !== 'REJECT') {
                ACTIONS.unshift({
                  text: result.routing.cancelBtn,
                })
              }
              Modal.alert('', result.routing.tips, ACTIONS)
              break
            }
            default:
              break
          }
        }
      })
    )
  }
}

export function checkExistAppeal(data, callback) { // 查询是否存在申诉事件
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/appeal/v1/auth/check-exist-appeal',
        method: 'POST',
        body: data,
        requestAction: SITE_CONFIG.CHECK_EXIST_APPEAL_REQUEST,
        successAction: SITE_CONFIG.CHECK_EXIST_APPEAL_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback(result)
        }
      })
    )
  }
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
const ACTION_HANDLERS = {
  [SITE_CONFIG.LANGUAGE_CHANGE]: (state, action) =>
    Object.assign({}, state, { language: action.payload }),
  [SITE_CONFIG.APPAUTHORIZE_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.APPAUTHORIZE_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload, {
      loginFlag: true,
    }),
  [SITE_CONFIG.CHECKISLOGIN_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.CHECKISLOGIN_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.SAVE_ACCESSINFO]: (state, action) =>
    Object.assign({}, state, { accessInfo: action.payload }),
  [SITE_CONFIG.SENDSMS_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.SENDSMS_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.GET_SALT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.GET_SALT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.REMOVE_SALT]: (state, action) =>
    Object.assign({}, state, { salt: undefined }),
  [SITE_CONFIG.QUERY_KYC_STATUS_REQUEST]: (state, action) => state,
  [SITE_CONFIG.QUERY_KYC_STATUS_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.QUERY_AGREEMENTS_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.QUERY_AGREEMENTS_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.QUERY_NOTICE_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.QUERY_NOTICE_SUCCESS]: (state, action) =>
    Object.assign({}, state, { notices: action.payload.messages }),
  [SITE_CONFIG.OAUTH_BY_AUTHCODE_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.OAUTH_BY_AUTHCODE_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.QUERY_BILL_INFO_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.QUERY_BILL_INFO_SUCCESS]: (state, action) =>
    Object.assign({}, state, { billInfo: action.payload }),
  [SITE_CONFIG.UPLOAD_REQUEST]: (state, action) => state,
  [SITE_CONFIG.UPLOAD_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.SET_LOADING_VISIBLE]: (state, action) =>
    Object.assign({}, state, { loadingVisible: action.payload }),
  [SITE_CONFIG.GET_CAPTCHA_REQUEST]: (state, action) => state,
  [SITE_CONFIG.GET_CAPTCHA_SUCCESS]: (state, action) =>
    Object.assign({}, state, { captcha: action.payload }),
  [SITE_CONFIG.DELELE_ACCOUNT_REQUEST]: (state, action) => state,
  [SITE_CONFIG.DELELE_ACCOUNT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.UPDATE_ALIAS_REQUEST]: (state, action) => state,
  [SITE_CONFIG.UPDATE_ALIAS_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.AUTO_RECHARGE_ADVANCE_REQUEST]: (state, action) => state,
  [SITE_CONFIG.AUTO_RECHARGE_ADVANCE_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.QUERY_AREACODE_REQUEST]: (state, action) => state,
  [SITE_CONFIG.QUERY_AREACODE_SUCCESS]: (state, action) =>
    Object.assign({}, state, { areaCode: action.payload.list }),
  [SITE_CONFIG.QUERY_CONFIG_ITEM_REQUEST]: (state, action) => state,
  [SITE_CONFIG.QUERY_CONFIG_ITEM_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload.content),
  [SITE_CONFIG.BEFORE_CHECK_REQUEST]: (state, action) => state,
  [SITE_CONFIG.BEFORE_CHECK_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SITE_CONFIG.CHECK_EXIST_APPEAL_REQUEST]: (state, action) => state,
  [SITE_CONFIG.CHECK_EXIST_APPEAL_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer and set default language
// ------------------------------------
const language = getCurrentLanguage()
setHtmlLangAndDir(language)
const initialState = {
  language: language,
  messages: messages,
  showHeader: true,
  accessInfo: {}
}

function reducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default {
  id: 'siteConfig',
  reducerMap: {
    siteConfig: reducer,
  },
  // Actions to fire when this module is added/removed
  // initialActions: [],
  // finalActions: []
}
