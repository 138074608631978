import { defineMessages } from 'react-intl'

export default defineMessages({
  rules: {
    id: 'components.Vouchers.VoucherCard.rules',
    defaultMessage: 'Rules of Use',
    description: '使用规则'
  },
  off: {
    id: 'components.Vouchers.VoucherCard.off',
    defaultMessage: 'OFF',
    description: '折扣'
  },
  complete: {
    id: 'components.Vouchers.VoucherCard.complete',
    defaultMessage: 'Complete your profile to redeem the voucher',
    description: '完成实名使用优惠券'
  },
  more: {
    id: 'components.Vouchers.VoucherCard.more',
    defaultMessage: 'View More',
    description: '展开'
  },
  collapse: {
    id: 'components.Vouchers.VoucherCard.collapse',
    defaultMessage: 'Collapse',
    description: '收起'
  }
})
