import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { languageChange } from 'store/siteConfig'
// ------------------------------------
// Constants
// ------------------------------------
export const PIC_ABOUT_NFC = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'PIC_ABOUT_NFC')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    return dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/upic/card/v1/auth/card-status',
        requestAction: PIC_ABOUT_NFC.INIT_REQUEST,
        successAction: PIC_ABOUT_NFC.INIT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PIC_ABOUT_NFC.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [PIC_ABOUT_NFC.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topicAboutNfc',
    reducerMap: {
      topicAboutNfcState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)],
    // Actions to fire when this module is added/removed
    initialActions: [languageChange('en')],
    // finalActions: []
  }
}
