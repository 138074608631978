import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { languageChange } from 'store/siteConfig'

// ------------------------------------
// Constants
// ------------------------------------
export const SPLIT_BILL = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'SPLIT_BILL')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/socialpay/sb/queryBillInfoByToken',
        method: 'POST',
        body: data,
        requestAction: SPLIT_BILL.INIT_REQUEST,
        successAction: SPLIT_BILL.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SPLIT_BILL.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [SPLIT_BILL.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'routesSplitBill',
    reducerMap: {
      routesSplitBillState: reducer,
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)],
    // Actions to fire when this module is added/removed
    initialActions: [languageChange('en')],
    // finalActions: []
  }
}
