import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { isPayBy } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------
export const UTILITY_TRANSACTION_DETAIL = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'UTILITY_TRANSACTION_DETAIL')

// ------------------------------------
// Actions
// ------------------------------------
const status = ['WAIT_PAY', 'PAY_SUCCESS', 'DEPOSIT_ING', 'SETTLED_ING']
var timer
export function init(data, callback) {
  return (dispatch, getState) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: isPayBy()
          ? '/cgs/api/lifecenter/queryOrderDetail'
          : '/cgs/api/lifecenter/v1/unauth/query-order-detail',
        method: 'POST',
        body: data,
        requestAction: UTILITY_TRANSACTION_DETAIL.INIT_REQUEST,
        successAction: UTILITY_TRANSACTION_DETAIL.INIT_SUCCESS,
        success: function(result) {
          console.log(status.indexOf(result.orderInfo.orderStatus))
          typeof callback === 'function' && callback(result.orderInfo)
          // 非处理中中止轮训
          if (status.indexOf(result.orderInfo.orderStatus) === -1) {
            return false
          }
          // 每5s轮询一次
          timer = setTimeout(() => {
            dispatch(init(data))
          }, 5000)
        }
      })
    )
  }
}

export function clearTimeOut() {
  return dispatch => {
    clearTimeout(timer)
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UTILITY_TRANSACTION_DETAIL.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [UTILITY_TRANSACTION_DETAIL.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'utilityTransactionDetail',
    reducerMap: {
      utilityTransactionDetailState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
