import React from 'react'
import { Route, Switch } from 'react-router-dom'
// import PageLayout from 'layouts/PageLayout'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path='/topic/upay'
        render={routeProps => (
          <div>
            {require('./Usage').default(routeProps)}
          </div>
        )}
      />
    </Switch>
  )
}

export default AppRouter
