import React from 'react'
import connect from 'redux-connect-decorator'
import Div100vh from 'react-div-100vh'
import { Helmet } from 'react-helmet'
import { Flex, WhiteSpace, WingBlank } from 'antd-mobile'
import { Button } from 'components'
import { closeWebView, openByBrowser } from 'utils/JSBridge'
import { languageChange } from 'store/siteConfig'
import { customEvent } from 'utils'

@connect(
  (state) => ({
    siteConfig: state.siteConfig,
  }),
  { languageChange }
)
class Remittance extends React.Component {
  constructor(props) {
    super(props)
    languageChange('en')
    customEvent({
      page_name: 'payby_remittance_redirectpage',
      event_name: 'display',
      element_name: 'pageview',
    })
  }

  render() {
    return (
      <Div100vh>
        <Helmet>
          <title>International Transfer</title>
        </Helmet>
        <Flex
          direction='column'
          justify='center'
          align='center'
          style={{
            height: '100%',
            position: 'relative',
          }}
        >
          <img
            style={{ position: 'absolute', width: '100%', top: 0, zIndex: -1 }}
            src={require('./images/image 51.png')}
          />
          <Flex.Item>
            <Flex
              direction='column'
              justify='center'
              align='center'
              style={{ height: '100%' }}
            >
              <WingBlank className='text-center'>
                <img height={64} src={require('./images/Frame 1087124.png')} />
                <WhiteSpace size='xl' />
                <WhiteSpace size='xl' />
                <div>
                  <b>Send money worldwide with ease on Botim</b>
                </div>
                <WhiteSpace />
                <div className='gray font-sm'>
                  Simplify your international transfer, use Botim
                </div>
                <WhiteSpace size='xl' />
                <WhiteSpace size='xl' />
                <Button
                  type='primary'
                  name='custom'
                  onClick={() => {
                    customEvent({
                      page_name: 'payby_remittance_redirectpage',
                      event_name: 'click',
                      element_name: 'continue',
                    })
                    openByBrowser(
                      'https://botim.me/mp/b/?app=me.botim.pay.remittanceapp%2Findex.html%23%2Fremittance'
                    )
                  }}
                >
                  Continue
                </Button>
                <WhiteSpace />
                <Button
                  name='custom'
                  className='am-button-gray'
                  onClick={() => closeWebView()}
                >
                  Cancel
                </Button>
                <WhiteSpace size='xl' />
                <WhiteSpace size='xl' />
                <WingBlank>
                  <div className='font-xs gray'>
                    You will be redirected to Botim app for seamless
                    communication and enabling PayBy's International Transfer
                    feature within Botim for your convenience.
                  </div>
                </WingBlank>
              </WingBlank>
            </Flex>
          </Flex.Item>
          <div className='text-center'>
            <div className='font-10'>
              Powered by{' '}
              <img
                style={{ verticalAlign: 'middle' }}
                height={24}
                src='/images/logo-payby-green.png'
              />
            </div>
            <WhiteSpace size='xs' />
            <div className='font-xs'>
              <a
                className='green'
                href='https://alioss.payby.com/PayByUserAgreement_LATEST.html'
              >
                Terms and Conditions
              </a>{' '}
              |{' '}
              <a
                className='green'
                href='https://alioss.payby.com/BasePrivacyPolicy_LATEST.html'
              >
                Privacy Policy
              </a>
            </div>
            <WhiteSpace size='xl' />
          </div>
        </Flex>
      </Div100vh>
    )
  }
}

export default Remittance
