import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const RISK_MAIN = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  APPEAL_COUNT_REQUEST: null,
  APPEAL_COUNT_SUCCESS: null,
  CHECK_EXIST_APPEAL_REQUEST: null,
  CHECK_EXIST_APPEAL_SUCCESS: null
}, 'RISK_MAIN')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/appeal/queryRejectOrderList',
        method: 'POST',
        requestAction: RISK_MAIN.INIT_REQUEST,
        successAction: RISK_MAIN.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function appealCount() {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/appeal/appealCount',
        method: 'POST',
        requestAction: RISK_MAIN.APPEAL_COUNT_REQUEST,
        successAction: RISK_MAIN.APPEAL_COUNT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function checkExistAppeal(data, callback) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/appeal/v1/auth/check-exist-appeal',
        method: 'POST',
        body: data,
        requestAction: RISK_MAIN.CHECK_EXIST_APPEAL_REQUEST,
        successAction: RISK_MAIN.CHECK_EXIST_APPEAL_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback(result)
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RISK_MAIN.INIT_REQUEST]: (state, action) => state,
  [RISK_MAIN.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [RISK_MAIN.APPEAL_COUNT_REQUEST]: (state, action) => state,
  [RISK_MAIN.APPEAL_COUNT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [RISK_MAIN.CHECK_EXIST_APPEAL_REQUEST]: (state, action) => state,
  [RISK_MAIN.CHECK_EXIST_APPEAL_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'riskMain',
    reducerMap: {
      riskMainState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
