import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import _ from 'lodash'
import queryString from 'query-string'
import { Modal } from 'components'
// import result from './client-app.json'

// ------------------------------------
// Constants
// ------------------------------------
export const OAUTH_AUTHORIZE = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  AUTHORIZE_REQUEST: null,
  AUTHORIZE_SUCCESS: null,
  UPDATE_BUTTON_STATE: null
}, 'OAUTH_AUTHORIZE')

// ------------------------------------
// Actions
// ------------------------------------

export function init(data) { // 根据clientId获取第三方应用信息
  return (dispatch, getState) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: '/cgs/api/oauth2/resource/app-by-client-id',
      body: {
        clientId: data.clientId
      },
      method: 'POST',
      requestAction: OAUTH_AUTHORIZE.INIT_REQUEST,
      successAction: OAUTH_AUTHORIZE.INIT_SUCCESS,
      success: function(result) {
        if (_.get(result, 'token.token')) {
          // 已授权的用户，直接跳转
          dispatch(
            redirectToMerchant({
              ...result
            })
          )
          return false
        }
        if (result.memberVip === 'Y' || !result.manualAuthorize || (result.memberVip !== 'Y' && result.scopes.length === 1 && result.scopes[0].value === 'member_id')) {
          // 需要静默授权的商户直接调用静默授权流程
          dispatch(getAuthorize(result))
        }
      }
    }))
  }
}

export function redirectToMerchant(data) {
  return (dispatch, getState) => {
    // 检查是否自定义了redirect_url，并做校验
    const customRedirectUrl = queryString.parseUrl(window.location.href).query.redirect_url
    if (customRedirectUrl) {
      // 检查自定义redirect_url是否有效
      let isMatch = false
      for (let i = 0; i < data.secureUrlList.length; i++) {
        if (
          new RegExp(data.secureUrlList[i]).test(
            decodeURIComponent(customRedirectUrl)
          )
        ) {
          isMatch = true
          break
        }
      }
      if (!isMatch) {
        dispatch(updateButtonState(true))
        Modal.alert('', 'Redirect url is not in the trust list', [
          {
            text: 'OK',
          },
        ])
        return false
      }
      // 覆盖配置的redirect_url
      data.redirectUri = customRedirectUrl
    }

    // 构建新的redirect_url
    data.redirectUri = decodeURIComponent(data.redirectUri)
    const [urlPath, urlParams = {}] = data.redirectUri.split('?')
    const params = queryString.parse(urlParams)
    params.access_token = data.token.token
    params.refresh_token = data.token.refreshToken
    console.log(urlPath)
    window.location.replace(`${urlPath}?${queryString.stringify(params)}`)
  }
}

export function getAuthorize(data) { // 隐式授权
  const scope = _.map(data.scopes, 'value').join(' ')
  return (dispatch, getState) => {
    // const { accessInfo } = getState().siteConfig
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: '/cgs/api/oauth2/authz/code',
      body: {
        responseType: 'token',
        scope: scope,
        clientId: data.clientId,
        redirectUri: data.redirectUri,
        state: Math.random().toString(16).slice(-8) // accessInfo.codeVerifier,
      },
      method: 'POST',
      requestAction: OAUTH_AUTHORIZE.AUTHORIZE_REQUEST,
      successAction: OAUTH_AUTHORIZE.AUTHORIZE_SUCCESS,
      success: function(result) {
        console.log('/cgs/api/oauth2/authz/code', result)
        dispatch(
          redirectToMerchant({
            ...data,
            token: result
          })
        )
      }
    }))
  }
}

export function updateButtonState(value) {
  return {
    type: OAUTH_AUTHORIZE.UPDATE_BUTTON_STATE,
    payload: {
      disabled: value
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [OAUTH_AUTHORIZE.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [OAUTH_AUTHORIZE.INIT_SUCCESS]: (state, action) => {
    const needAuthorize = !_.get(action.payload, 'token.token')
    return Object.assign({ needAuthorize }, state, action.payload)
  },
  [OAUTH_AUTHORIZE.AUTHORIZE_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [OAUTH_AUTHORIZE.AUTHORIZE_SUCCESS]: (state, action) => {
    state.needAuthorize = false
    return Object.assign({}, state, action.payload)
  },
  [OAUTH_AUTHORIZE.UPDATE_BUTTON_STATE]: (state, action) => Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  disabled: false
}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'oauthAuthorize',
    reducerMap: {
      oauthAuthorizeState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
