import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { Flex } from 'antd-mobile'
import { Button } from 'components'
import { downloadApp, isPayBy, deeplink } from 'utils'
import { withRouter } from 'react-router'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const imgList = {
  PayBy: require('./images/PayBy.png'),
}

class StickyDownload extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  handleDownload = () => {
    const {
      platform = 'PayBy',
      position,
      page,
      location,
      openNative = {},
    } = this.props
    console.log(location)
    window.firebase.analytics().logEvent(
      'download',
      {
        page_position: page || location.pathname,
        icon_position: position || 'bottom',
        event_callback: function(params) {
          console.log(params)
          if (openNative.target) {
            deeplink(openNative.target)
          } else {
            downloadApp(platform)
          }
        },
        event_timeout: 3000
      }
    )
  }

  render() {
    const { children, btnName = 'download', className, imgUrl, openNative = {} } = this.props
    if (isPayBy()) {
      return null
    }
    var defaultClassName = classes.componentsStickyDownload
    if (className) {
      defaultClassName += ` ${className}`
    }
    return (
      <Flex className={defaultClassName} align='center' justify='between'>
        <img src={imgUrl || imgList.PayBy} height={34} />
        {children}
        {openNative.shortCode ? (
          <CopyToClipboard text={openNative.shortCode}>
            <Button
              type='primary'
              name={btnName}
              onClick={this.handleDownload}
            />
          </CopyToClipboard>
        ) : (
          <Button type='primary' name={btnName} onClick={this.handleDownload} />
        )}
      </Flex>
    )
  }
}

export default withRouter(StickyDownload)

// eg:
/* <StickyDownload platform='PayBy' location={location}>
  <div className='text-left'>
    <h5>PayBy</h5>
    <p>All Payments by phone</p>
  </div>
</StickyDownload> */
