import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { InputItem, Tabs } from 'antd-mobile'

export default class VoucherTabs extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }

  handleChange = (tab) => {
    const queryType = tab.value
    const { handleChange } = this.props
    // 首次进入discounts红点提示
    if (queryType === 'd' && !localStorage.getItem('view')) {
      localStorage.setItem('view', true)
    }
    if (typeof handleChange === 'function') {
      handleChange(queryType)
    } else {
      this.props.form.setFieldsValue({
        queryType,
        pageNum: 0,
        pageSize: queryType === 'd' ? 10 : 30
      })
      this.props.query()
    }
  }

  render() {
    const { form, tabs, index = 0, page = 2, ...resProps } = this.props
    return (
      <div className={classes.componentsVouchersVoucherTabs}>
        {form.getFieldDecorator('queryType', {
          initialValue: tabs[index].value
        })(<InputItem className='dn' />)}
        <Tabs
          tabs={tabs}
          initialPage={index}
          tabBarTextStyle={{ fontSize: '14px' }}
          renderTabBar={(props) => <Tabs.DefaultTabBar {...props} page={page} />}
          onChange={this.handleChange}
          {...resProps}
        />
      </div>
    )
  }
}
