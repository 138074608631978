import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { WingBlank, Flex, WhiteSpace } from 'antd-mobile'
import { intl } from 'utils'

export default class PCIPrompt extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { text = intl.formatMessage(messages.des) } = this.props
    return (
      <WingBlank>
        <WhiteSpace size='lg' />
        <Flex className={classes.componentsPciPrompt}>
          <img height={33} src={require('./images/PCI.png')} />
          <Flex.Item className='font-xs gray'>{text}</Flex.Item>
        </Flex>
        <WhiteSpace size='lg' />
      </WingBlank>
    )
  }
}
