import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, push } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const CASHIN_QRCODE = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  QUERY_REQUEST: null,
  QUERY_SUCCESS: null,
  CHECK_PROCESSORDER_REQUEST: null,
  CHECK_PROCESSORDER_SUCCESS: null,
}, 'CASHIN_QRCODE')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  console.log('QRCode:submit', data)
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/jollychic-service/cashin/submit',
        method: 'POST',
        body: {
          accessToken: sessionStorage.getItem('accessToken')
        },
        requestAction: CASHIN_QRCODE.INIT_REQUEST,
        successAction: CASHIN_QRCODE.INIT_SUCCESS,
        success: function(result) {
          const globalId = result.cashInOrder.globalId
          if (globalId) {
            dispatch(query(globalId))
          }
        }
      })
    )
  }
}

export function checkProcessOrder(data) {
  console.log('QRCode:checkProcessOrder', data)
  sessionStorage.setItem('accessToken', data.authToken)
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/jollychic-service/cashin/processingOrder',
        method: 'POST',
        body: {
          accessToken: data.authToken
        },
        requestAction: CASHIN_QRCODE.CHECK_PROCESSORDER_REQUEST,
        successAction: CASHIN_QRCODE.CHECK_PROCESSORDER_SUCCESS,
        success: function(result) {
          const status = result.status
          const globalId = result.globalId
          if (status === 'LOADING' || status === 'PROCESSING') {
            // 已扫码, 付款中
            return dispatch(push(`/cashIn/scanned?globalId=${globalId}`))
          }
          if (status === 'WAITING_CONFIRM') {
            // 待确认
            return dispatch(push(`/cashIn/confirm?globalId=${globalId}`))
          }
          return dispatch(init())
        }
      })
    )
  }
}

export function query(globalId) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/jollychic-service/cashin/order',
        method: 'POST',
        body: {
          globalId: globalId,
          accessToken: sessionStorage.getItem('accessToken')
        },
        requestAction: CASHIN_QRCODE.QUERY_REQUEST,
        successAction: CASHIN_QRCODE.QUERY_SUCCESS,
        success: function(result) {
          if (state.router.location.pathname !== '/cashIn/qrcode') {
            return false
          }
          if (result.status === 'LOADING' || result.status === 'PROCESSING') {
            // 已扫码, 付款中
            return dispatch(push(`/cashIn/scanned?globalId=${globalId}`))
          }
          if (result.status === 'WAITING_CONFIRM') {
            // 待确认
            return dispatch(push(`/cashIn/confirm?globalId=${globalId}`))
          }
          setTimeout(() => {
            dispatch(query(globalId))
          }, 2000)
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CASHIN_QRCODE.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_QRCODE.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_QRCODE.QUERY_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_QRCODE.QUERY_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_QRCODE.CHECK_PROCESSORDER_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_QRCODE.CHECK_PROCESSORDER_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'cashInQrCode',
    reducerMap: {
      cashInQrCodeState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
