import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'routes.TopUp.AutoRecharge.List.title',
    defaultMessage: 'Auto Recharge',
    description: '备注: 页面title'
  },
  setDate: {
    id: 'routes.TopUp.AutoRecharge.Activate.setDate',
    defaultMessage: 'Set a monthly {name} date',
    description: '设置自动扣款日期'
  },
  setAmount: {
    id: 'routes.TopUp.AutoRecharge.Activate.setAmount',
    defaultMessage: 'Set the {name} amount',
    description: '设置自动扣款金额'
  },
  activate: {
    id: 'routes.TopUp.AutoRecharge.Activate.activate',
    defaultMessage: 'Activate',
    description: '启用'
  },
  save: {
    id: 'routes.TopUp.AutoRecharge.Activate.save',
    defaultMessage: 'Save',
    description: '保存'
  },
  autoRecharge: {
    id: 'routes.TopUp.AutoRecharge.Activate.autoRecharge',
    defaultMessage: 'auto-recharge',
    description: '自动充值'
  },
  autoPayment: {
    id: 'routes.TopUp.AutoRecharge.Activate.autoPayment',
    defaultMessage: 'auto-payment',
    description: '自动支付'
  },
  message: {
    id: 'routes.TopUp.AutoRecharge.Activate.message',
    defaultMessage: 'You can disable it at anytime.',
    description: '可随时禁用'
  },
  phonePlaceholder: {
    id: 'routes.TopUp.AutoRecharge.Activate.phonePlaceholder',
    defaultMessage: 'Enter Phone number',
    description: '手机号placeholder'
  },
  really: {
    id: 'routes.TopUp.AutoRecharge.Activate.really',
    defaultMessage: 'Really?',
    description: 'modal标题'
  },
  keepConfig: {
    id: 'routes.TopUp.AutoRecharge.Activate.keepConfig',
    defaultMessage: 'Keep {name} can save your time.',
    description: '保持配置'
  },
  disable: {
    id: 'routes.TopUp.AutoRecharge.Activate.disable',
    defaultMessage: 'Disable',
    description: '禁用'
  },
  keep: {
    id: 'routes.TopUp.AutoRecharge.Activate.keep',
    defaultMessage: 'Keep',
    description: '保持'
  },
  full: {
    id: 'routes.TopUp.AutoRecharge.Activate.full',
    defaultMessage: 'Pay full amount',
    description: '支付所有金额'
  },
  special: {
    id: 'routes.TopUp.AutoRecharge.Activate.special',
    defaultMessage: 'Pay a specific amount',
    description: '支付部分金额'
  },
  repeat: {
    id: 'routes.TopUp.AutoRecharge.Activate.repeat',
    defaultMessage: 'Top up repeatedly?',
    description: '重复充值'
  },
  tryNow: {
    id: 'routes.TopUp.AutoRecharge.Activate.tryNow',
    defaultMessage: 'Try auto-recharge service Now!',
    description: '尝试自动充值'
  },
  postpaid: {
    id: 'routes.TopUp.AutoRecharge.Activate.postpaid',
    defaultMessage: 'If you have postpaid card',
    description: '后付费卡'
  },
  paid: {
    id: 'routes.TopUp.AutoRecharge.Activate.paid',
    defaultMessage: 'Your monthly bill will be paid automatically every month.',
    description: '每月自动支付账单'
  },
  prepaid: {
    id: 'routes.TopUp.AutoRecharge.Activate.prepaid',
    defaultMessage: 'If you have prepaid card',
    description: '预付费卡'
  },
  recharge: {
    id: 'routes.TopUp.AutoRecharge.Activate.recharge',
    defaultMessage:
      'Your mobile will be recharged with chosen amount automatically every month',
    description: '每月自动充值'
  },
  noCost: {
    id: 'routes.TopUp.AutoRecharge.Activate.noCost',
    defaultMessage: 'NO COST! CANCEL ANYTIME!',
    description: '免费'
  },
  setNickName: {
    id: 'routes.TopUp.AutoRecharge.Activate.setNickName',
    defaultMessage: 'Set a nickname',
    description: '设置昵称'
  },
  phoneNumber: {
    id: 'routes.TopUp.AutoRecharge.Activate.phoneNumber',
    defaultMessage: 'Phone number',
    description: '手机号'
  },
  cancel: {
    id: 'routes.TopUp.AutoRecharge.Activate.cancel',
    defaultMessage: 'Cancel',
    description: '取消'
  },
  confirm: {
    id: 'routes.TopUp.AutoRecharge.Activate.confirm',
    defaultMessage: 'Confirm',
    description: '确认'
  },
  enter: {
    id: 'routes.TopUp.AutoRecharge.Activate.enter',
    defaultMessage: 'Enter the amount you’d like to pay',
    description: '输入金额'
  },
  minQuota: {
    id: 'routes.TopUp.AutoRecharge.Activate.minQuota',
    defaultMessage: 'Min: AED {amount}',
    description: '最小限额'
  },
  maxQuota: {
    id: 'routes.TopUp.AutoRecharge.Activate.maxQuota',
    defaultMessage: 'Max: AED {amount}',
    description: '最大限额'
  },
  activated: {
    id: 'routes.TopUp.AutoRecharge.Activate.activated',
    defaultMessage: 'This number has already activated auto-recharge.',
    description: '该号码已开通自动充值'
  },
  amountMsg: {
    id: 'routes.TopUp.components.BillInfo.amountMsg',
    defaultMessage: 'Please enter the amount',
    description: '请输入金额'
  }
})
