import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { closeWebView, intl } from 'utils'
import { Toast } from 'antd-mobile'
import messages from './messages'

// ------------------------------------
// Constants
// ------------------------------------
export const FEEDBACK_QRCODE = keyMirror(
  {
    INIT_REQUEST: null,
    INIT_SUCCESS: null,
    SUBMIT_REQUEST: null,
    SUBMIT_SUCCESS: null
  },
  'FEEDBACK_QRCODE'
)

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: 'test.json',
      requestAction: FEEDBACK_QRCODE.INIT_REQUEST,
      successAction: FEEDBACK_QRCODE.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

export function submit(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/appeal/submitAppeal',
        method: 'POST',
        body: {
          appealType: 'USER_FEEDBACK',
          ...data
        },
        requestAction: FEEDBACK_QRCODE.SUBMIT_REQUEST,
        successAction: FEEDBACK_QRCODE.SUBMIT_SUCCESS,
        success: function(result) {
          Toast.info(intl.formatMessage(messages.thanks), 2, () => {
            closeWebView()
          })
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FEEDBACK_QRCODE.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [FEEDBACK_QRCODE.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [FEEDBACK_QRCODE.SUBMIT_REQUEST]: (state, action) => state,
  [FEEDBACK_QRCODE.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'feedbackQrcode',
    reducerMap: {
      feedbackQrcodeState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
