import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { getSalt } from 'store/siteConfig'
import { encrypt } from 'utils/rsa'
import { Toast } from 'antd-mobile'
import { ToPayRequest } from 'utils'
import _ from 'lodash'
import queryString from 'query-string'

// ------------------------------------
// Constants
// ------------------------------------
export const TOP_UP_INTERNATIONAL_RECHARGE = keyMirror(
  {
    INIT_REQUEST: null,
    INIT_SUCCESS: null,
    SUBMIT_REQUEST: null,
    SUBMIT_SUCCESS: null,
    INIT_PAGE: null,
    QUERY_ACCOUNT_RULE_REQUEST: null,
    QUERY_ACCOUNT_RULE_SUCCESS: null
  },
  'TOP_UP_INTERNATIONAL_RECHARGE'
)

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  Toast.loading('', 0)
  return (dispatch) => {
    dispatch(getSalt()).then((res) => {
      const { salt } = res.payload
      console.log(salt)
      const { phone, ...resData } = data
      console.log(phone)
      resData.phone = encrypt(phone, salt)
      dispatch(
        callAPI({
          dispatch: dispatch,
          endpoint: '/cgs/api/lifecenter/queryMobileGoodsList',
          method: 'POST',
          body: {
            ...resData,
            packageType: 'Prepaid',
            queryType: 'MOBILE_TOPUP_INT'
          },
          requestAction: TOP_UP_INTERNATIONAL_RECHARGE.INIT_REQUEST,
          successAction: TOP_UP_INTERNATIONAL_RECHARGE.INIT_SUCCESS,
          success: function(result) {
            dispatch(initPage(false))
            Toast.hide()
          }
        })
      )
    })
  }
}

export function submit(data, callback) {
  // 下单
  Toast.loading('', 0)
  return (dispatch) => {
    dispatch(getSalt()).then((res) => {
      const { salt } = res.payload
      console.log(salt)
      const { phone, ...resData } = data
      console.log(phone)
      resData.targetPhone = encrypt(phone, salt)
      dispatch(
        callAPI({
          dispatch: dispatch,
          method: 'POST',
          body: {
            ...resData,
            packageType: 'Prepaid'
          },
          endpoint: '/cgs/api/lifecenter/createMobileTopUpOrder',
          requestAction: TOP_UP_INTERNATIONAL_RECHARGE.SUBMIT_REQUEST,
          successAction: TOP_UP_INTERNATIONAL_RECHARGE.SUBMIT_SUCCESS,
          success: function(result) {
            typeof callback === 'function' && callback()
            const tokenUrl = _.chain(result).get('token').value()
            const token = queryString.parse(tokenUrl).ft
            ToPayRequest(token, (res) => {
              if (res.status === 'success' || res.status === 'paying') {
                dispatch(
                  replace(`/topUp/result/${result.orderNo}?accountType=MOBILE_TOPUP_INT`)
                )
              }
            })
          },
          error: function(result, msg) {
            Toast.info(msg)
            typeof callback === 'function' && callback()
          }
        })
      )
    })
  }
}

export function queryAccountRule(data) {
  return (dispatch) => {
    const { countryCode, serviceProvider } = data
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/queryProviderAccountRule',
        method: 'POST',
        body: { countryCode, serviceProvider },
        requestAction: TOP_UP_INTERNATIONAL_RECHARGE.QUERY_ACCOUNT_RULE_REQUEST,
        successAction: TOP_UP_INTERNATIONAL_RECHARGE.QUERY_ACCOUNT_RULE_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function initPage(initial, data) {
  console.log(data)
  return {
    type: TOP_UP_INTERNATIONAL_RECHARGE.INIT_PAGE,
    payload: {
      initPage: !!initial,
      ...data
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_UP_INTERNATIONAL_RECHARGE.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_INTERNATIONAL_RECHARGE.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_INTERNATIONAL_RECHARGE.SUBMIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_INTERNATIONAL_RECHARGE.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_INTERNATIONAL_RECHARGE.QUERY_ACCOUNT_RULE_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_INTERNATIONAL_RECHARGE.QUERY_ACCOUNT_RULE_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_INTERNATIONAL_RECHARGE.INIT_PAGE]: (state, action) => {
    const { initPage } = action.payload // 查询商品时页面默认商品置灰
    if (initPage) {
      action.payload.goodsInfos = []
    }
    return Object.assign({}, state, action.payload)
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  initPage: true
}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topUpInternationalRecharge',
    reducerMap: {
      topUpInternationalRechargeState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
