import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, goBack } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import sha256 from 'sha256'
// import toPay from './ToPayJSBridge'
// console.log(toPay)

// ------------------------------------
// Constants
// ------------------------------------
export const OAUTH_LOGIN = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'OAUTH_LOGIN')

// ------------------------------------
// Actions
// ------------------------------------
export function login() {
  return (dispatch) => {
    const random = Math.random().toString(16).slice(-8)
    console.log((sha256(random)))
    window.ToPayJSBridge.invoke(
      'getVerifyToken', {
        codeChallenge: sha256(random),
        appDomain: window.location.hostname
      },
      function(data) {
        console.log('data', data)
        console.log(typeof data)
        if (data) {
          const res = JSON.parse(data)
          console.log(typeof res)
          console.log('ToPayJSBridge:res', res)
          if (res && res.verifyToken) {
            dispatch(init(Object.assign({}, res, {
              codeVerifier: random
            })))
          }
        }
      }
    )
  }
}

export function init(data) { // app授权
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      headers: {
        'Content-Language': 'en',
        'Utc-Offset-Seconds': 14400,
        platform: 4
      },
      endpoint: '/cgs/api/h5app/auth/verify',
      body: {
        verifyToken: data.verifyToken,
        codeVerifier: data.codeVerifier
      },
      method: 'POST',
      requestAction: OAUTH_LOGIN.INIT_REQUEST,
      successAction: OAUTH_LOGIN.INIT_SUCCESS,
      success: function(result) {
        sessionStorage.setItem('loginFlag', true)
        sessionStorage.setItem('accessInfo', JSON.stringify(data))
        dispatch(goBack())
      }
    }))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [OAUTH_LOGIN.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [OAUTH_LOGIN.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'oauthLogin',
    reducerMap: {
      oauthLoginState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
