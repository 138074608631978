import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path='/tips/collect'
        render={(routeProps) => require('./Collect').default(routeProps)}
      />
      <Route>
        <Redirect to='/platform/payby/landing' />
      </Route>
    </Switch>
  )
}

export default AppRouter
