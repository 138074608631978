import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { Toast } from 'antd-mobile'
import { setLoadingVisible } from 'store/siteConfig'
import _ from 'lodash'
import queryString from 'query-string'
import { transferStatus } from 'utils'

const { login } = queryString.parseUrl(window.location.href).query

// ------------------------------------
// Constants
// ------------------------------------
export const RISK_DETAIL = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null
}, 'RISK_DETAIL')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: `/cgs/api/personal/appeal/queryAppealInfo${login === 'N' ? 'Un' : ''}`,
        method: 'POST',
        body: data,
        requestAction: RISK_DETAIL.INIT_REQUEST,
        successAction: RISK_DETAIL.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function submit(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: `/cgs/api/personal/appeal/appendAppealData${login === 'N' ? 'Un' : ''}`,
        method: 'POST',
        body: data,
        requestAction: RISK_DETAIL.SUBMIT_REQUEST,
        successAction: RISK_DETAIL.SUBMIT_SUCCESS,
        success: function(result) {
          dispatch(setLoadingVisible(false))
          if (login === 'N') { // 非登录状态去结果页，隐藏按钮
            dispatch(replace('/risk/result?login=N'))
          } else {
            dispatch(replace('/risk/history'))
          }
        },
        error: function(result, msg) {
          Toast.info(msg)
          dispatch(setLoadingVisible(false))
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RISK_DETAIL.INIT_REQUEST]: (state, action) => state,
  [RISK_DETAIL.INIT_SUCCESS]: (state, action) => {
    const {
      appealDataList = [],
      appealTitle,
      appealStatus,
      orderList = []
    } = action.payload // 提取补充信息
    action.payload.firstAttachmentIndex = _.findIndex(
      appealDataList,
      (item) => item.code.indexOf('ATTACHMENT') !== -1
    ) // 单次提交默认展示所有数据，多次采用折叠面板
    action.payload.additionalList = _.chain(appealDataList)
      .remove((item) => item.type === 'TXT')
      .reverse()
      .value()
    console.log(appealDataList)
    if (appealTitle === 'Unauthorized payments report') {
      action.payload.appealStatus = transferStatus(appealStatus)
      _.map(orderList, (item) => {
        item.appealStatus = transferStatus(item.appealStatus)
      })
    }
    return Object.assign({}, state, action.payload)
  },
  [RISK_DETAIL.SUBMIT_REQUEST]: (state, action) => state,
  [RISK_DETAIL.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'riskDetail',
    reducerMap: {
      riskDetailState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
