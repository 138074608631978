import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { InputItem } from 'antd-mobile'
import { MapMarker } from 'components'
import GoogleMapReact from 'google-map-react'
import queryString from 'query-string'

export default class MapCanvas extends React.Component {
  constructor(props) {
    super(props)
    const { lat, lng } = queryString.parse(window.location.search)
    this.state = {
      defaultCenter: {
        lat: Number(lat) || 24.4489113,
        lng: Number(lng) || 54.3832813,
      },
      defaultSelected: {
        lat: lat ? Number(lat) : undefined,
        lng: lng ? Number(lng) : undefined
      },
      defaultZoom: 15,
    }
  }

  componentDidMount = () => {
    // 从列表返回优先定位选中的门店
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const personLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        const { lat, lng } = queryString.parse(window.location.search)
        if (!lat || !lng) {
          this.props.form.setFieldsValue({
            center: personLocation,
            personLocation: personLocation,
          })
        } else {
          this.props.form.setFieldsValue({
            personLocation: personLocation,
          })
        }
        this.props.query()
      }, () => {
        this.props.query()
      }, {
        timeout: 3000
      })
    } else {
      this.props.query()
    }
  }

  initMap(map, maps, _self) {
    map.addListener('idle', function() {
      _self.setBounds(map)
    })
  }

  setBounds = (map) => {
    const bounds = map.getBounds()
    var ne = bounds.getNorthEast()
    var sw = bounds.getSouthWest()
    this.props.form.setFieldsValue({
      bounds: [
        { lat: ne.lat(), lng: ne.lng() },
        { lat: ne.lat(), lng: sw.lng() },
        { lat: sw.lat(), lng: sw.lng() },
        { lat: sw.lat(), lng: ne.lng() },
      ],
    })
    this.props.query()
  }

  onChildClick = (key, childProps) => {
    const positon = { lat: childProps.lat, lng: childProps.lng }
    this.props.form.setFieldsValue({ selected: positon })
  }

  render() {
    const { form, storeList = [] } = this.props
    const { defaultCenter, defaultZoom, defaultSelected } = this.state
    const selected = form.getFieldValue('selected') || defaultSelected
    const center = form.getFieldValue('center')
    const personLocation = form.getFieldValue('personLocation')
    return (
      <div className={classes.componentsMapCanvas}>
        {form.getFieldDecorator('personLocation')(<InputItem className='dn' />)}
        {form.getFieldDecorator('bounds')(<InputItem className='dn' />)}
        {form.getFieldDecorator('selected')(<InputItem className='dn' />)}
        {form.getFieldDecorator('center')(<InputItem className='dn' />)}
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyDto4wxr8DbQx_9J_9LtyO-Gz-A21QMH1g',
          }}
          options={{
            disableDefaultUI: true,
            gestureHandling: 'greedy',
          }}
          defaultCenter={defaultCenter}
          center={center}
          defaultZoom={defaultZoom}
          onChildClick={this.onChildClick}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.initMap(map, maps, this)}
        >
          {personLocation && (
            <MapMarker
              lat={personLocation.lat}
              lng={personLocation.lng}
              selected={selected}
              data={{
                activeIcon: require('./images/location.png'),
                defaultIcon: require('./images/location.png'),
              }}
            />
          )}
          {storeList.map((item, index) => {
            const { lat, lng } = item.location || {}
            return (
              <MapMarker
                key={index}
                lat={lat}
                lng={lng}
                selected={selected}
                data={item}
              />
            )
          })}
        </GoogleMapReact>
      </div>
    )
  }
}
