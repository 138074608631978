import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const CASHIN_CONFIRM = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  REJECT_REQUEST: null,
  REJECT_SUCCESS: null,
  CONFIRM_REQUEST: null,
  CONFIRM_SUCCESS: null,
}, 'CASHIN_CONFIRM')

// ------------------------------------
// Actions
// ------------------------------------
export function init(globalId) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/jollychic-service/cashin/order',
        method: 'POST',
        body: {
          globalId: globalId,
          accessToken: sessionStorage.getItem('accessToken')
        },
        requestAction: CASHIN_CONFIRM.INIT_REQUEST,
        successAction: CASHIN_CONFIRM.INIT_SUCCESS,
        success: function(result) {
          if (result.status === 'FINISHED ' || result.status === 'CLOSED') {
            return dispatch(replace(`/cashIn/result?globalId=${globalId}`))
          }
        }
      })
    )
  }
}

export function reject(globalId) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/jollychic-service/cashin/reject',
        method: 'POST',
        body: {
          globalId: globalId,
          accessToken: sessionStorage.getItem('accessToken')
        },
        requestAction: CASHIN_CONFIRM.REJECT_REQUEST,
        successAction: CASHIN_CONFIRM.REJECT_SUCCESS,
        success: function(result) {
          dispatch(replace(`/cashIn/result?globalId=${globalId}`))
        }
      })
    )
  }
}

export function confirm(globalId) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/jollychic-service/cashin/confirm',
        method: 'POST',
        body: {
          globalId: globalId,
          accessToken: sessionStorage.getItem('accessToken')
        },
        requestAction: CASHIN_CONFIRM.CONFIRM_REQUEST,
        successAction: CASHIN_CONFIRM.CONFIRM_SUCCESS,
        success: function(result) {
          dispatch(replace(`/cashIn/result?globalId=${globalId}`))
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CASHIN_CONFIRM.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_CONFIRM.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_CONFIRM.REJECT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_CONFIRM.REJECT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_CONFIRM.CONFIRM_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [CASHIN_CONFIRM.CONFIRM_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'cashInConfirm',
    reducerMap: {
      cashInConfirmState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
