import React from 'react'
import PropTypes from 'prop-types'
import classes from './styles.module.scss'
import messages from './messages'
import { injectIntl } from 'react-intl'
import { Modal } from 'components'
import { InputItem, Icon, SearchBar, List, Popover, Toast } from 'antd-mobile'
import { intlMobileRule } from 'utils/validateRules'
import _ from 'lodash'

const defaultCountry = {
  countrySimpleName: 'UAE',
  englishName: 'United Arab Emirates',
  areaCode: '+971',
  countryCode: 'AE',
  countryFullName: 'United Arab Emirates',
}
// 区号 国家名称 国家代码 手机号长度限制 手机号格式正则

class PhoneInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleModal: false,
      injected: false,
      searchValue: '',
      countries: [],
      country: (props.data && props.data[0]) || defaultCountry,
      name: props.name || 'tel',
    }
    Toast.loading('', 0)
  }

  handleSearch = (value) => {
    // 根据输入的国家码过滤选择列表
    const filtered = _.filter(this.props.data, (item) => {
      return (
        item.areaCode.indexOf(value) !== -1 ||
        _.toLower(item.countryFullName).indexOf(_.toLower(value)) !== -1 ||
        item.countryCode.indexOf(_.toUpper(value)) !== -1
      )
    })
    this.setState({
      searchValue: value,
      countries: !value ? this.props.data : filtered,
    })
  }

  handleSelect = (country) => {
    console.log(country)
    if (!country) {
      return this.setState({
        visible: false,
        visibleModal: false,
        searchValue: '',
        countries: this.props.data,
      })
    }
    // 选择国家后设置state，并且重置手机号的输入表单
    this.setState(
      {
        visible: false,
        visibleModal: false,
        searchValue: '',
        country: country,
        countries: this.props.data,
      },
      () => {
        this.props.form.resetFields([this.state.name, 'tempInput'])
      }
    )
  }

  showMobileModal = () => {
    this.setState({ visibleModal: true }, () => {
      this.mobileSearch.focus()
    })
  }

  showPCPopover = (visible) => {
    this.setState({ visible }, () => {
      visible && this.PCSearch.focus()
    })
  }

  formatPhoneNo = (value) => {
    // 拼接国家码和手机号
    const data = {}
    data[this.state.name] = `${this.state.country.areaCode}-${value.replace(
      / /g,
      ''
    )}`
    this.props.form.setFieldsValue(data)
  }

  componentDidUpdate = (prevProps, prevState) => {
    // 更新获取到的支持国家列表数据
    const { data } = this.props
    if (!this.state.injected && _.isArray(data) && data.length) {
      Toast.hide()
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        injected: true,
        countries: data,
      })
    }
  }

  render() {
    const { intl, form } = this.props
    const { country, name, countries, searchValue } = this.state
    return (
      <React.Fragment>
        {form.getFieldDecorator(
          name,
          intlMobileRule(country.countryCode, false)
        )(<InputItem className='dn' />)}
        {form.getFieldDecorator('tempInput', {
          ...intlMobileRule(country.countryCode),
          onChange: this.formatPhoneNo,
        })(
          <InputItem
            className={classes.componentsPhoneInput}
            type='text'
            placeholder={intl.formatMessage(messages['input.placeholder'])}
          >
            {/* H5版本的国家选择 */}
            <div
              className={classes.mobileSelect}
              onClick={this.showMobileModal}
            >
              {country.areaCode}
              <Icon type='down' size='xs' />
            </div>
            <Modal
              visible={this.state.visibleModal}
              className={classes.componentsPhoneInputModal}
            >
              <SearchBar
                maxLength={20}
                placeholder='Search'
                showCancelButton
                ref={(ref) => (this.mobileSearch = ref)}
                value={searchValue}
                onChange={this.handleSearch}
                onCancel={() => this.handleSelect()}
              />
              <List className='no-border am-list-small'>
                {countries.map((item) => (
                  <List.Item
                    key={item.countryCode}
                    extra={item.areaCode}
                    onClick={() => this.handleSelect(item)}
                  >
                    <b>{item.countryFullName}</b>
                  </List.Item>
                ))}
              </List>
            </Modal>
            {/* PC版本的国家选择 */}
            <Popover
              visible={this.state.visible}
              overlay={
                <div className={classes.componentsPhoneInputPCModal}>
                  <SearchBar
                    maxLength={20}
                    placeholder='Search'
                    ref={(ref) => (this.PCSearch = ref)}
                    value={searchValue}
                    onChange={this.handleSearch}
                  />
                  {countries.length ? (
                    countries.map((item) => (
                      <Popover.Item key={item.countryCode} value={item}>
                        {item.countryFullName}
                        <span className='pull-right gray'>{item.areaCode}</span>
                      </Popover.Item>
                    ))
                  ) : (
                    <div className={classes.empty}>No Data</div>
                  )}
                </div>
              }
              placement='bottomLeft'
              onSelect={(e) => this.handleSelect(e.props.value)}
              onVisibleChange={this.showPCPopover}
            >
              <div className={classes.pcSelect}>
                {country.areaCode}
                <Icon type='down' size='xs' />
              </div>
            </Popover>
          </InputItem>
        )}
      </React.Fragment>
    )
  }
}

export default injectIntl(PhoneInput)

PhoneInput.propTypes = {
  form: PropTypes.object.isRequired
}
