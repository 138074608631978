import { defineMessages } from 'react-intl'

export default defineMessages({
  error: {
    id: 'components.Loading.error',
    defaultMessage: 'Loadable Error',
    description: '加载失败'
  },
  try: {
    id: 'components.Loading.try',
    defaultMessage: 'please try later!',
    description: '稍后重试'
  },
  retry: {
    id: 'components.Loading.retry',
    defaultMessage: 'Retry',
    description: '重试'
  },
  time: {
    id: 'components.Loading.time',
    defaultMessage: 'Taking a long time',
    description: '重试'
  }
})
