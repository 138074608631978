/* eslint-disable max-len */
import Encrypt from 'jsencrypt'
// import CryptoJS from 'crypto-js'

const PublicKeyTest = `-----BEGIN PRIVATE KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArQBG4bYMrohTXe4noNpqDzBTPY+RVr2jDkya2InlYarmHwQiD12qypw+Lh0KYXbD8kctAkewPbU9R08SdpCWq7Kh3Gt/YfgJxgjMANzwVe7R57Kb4ruFBAdbmbx3baO7Pym2fyiRHQlqU6JnNHKB18IA3iN1AjWCsv8AcuSVRbt+j1nsHULcdSAUzfcc7TPRUGDXj6TWa5GhHopvuzMxGF4qSjMuiQe1GrHm0IrW+XCkgOI5g0Gi8PCrRdQwAzu386lN833EyZQ94ciNW2giB4YLVVzzuJOJPt32R+Ns1E0S5P/yBAEqCw/NJP0EgHrguc0PYTFg7BS7gveZI5oONQIDAQAB
-----END PRIVATE KEY-----`

const PublicKeyProd = `-----BEGIN PRIVATE KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoY221tbXlKE6rseAquXzxSzu4FHcMKDAj+XsEKCmfCZ/n6FEnWd3HWJpLADk7gTSLFTfqZc9/VLxm7zF7P+li1k3OXHO1h773Gi0m6EKWmxCcPNAp1UCwu4Qy+ESHWBt9ACwcij0MeVxJaS473rr+rk3JM9zNabeY9XOp7G92OOdip/sPBNgoCQvjnfrU9mhE42KqVm0RTAUkX22Wny1gauDDuUUCKNF/V6jlSSh0KOT3mRuu5I6s5fuIYt5xjBbei/CvWUwCCYO8Ism8NHNI/bxrRZwt35sg4tirxWxPrk2o4eOTn2Q1mnWtmYe+IG36ysKYHihHgidhN7Tc5J2ZQIDAQAB
-----END PRIVATE KEY-----`

const PublicKey =
  window.location.hostname === 'm.payby.com'
    ? PublicKeyProd
    : PublicKeyTest

const RSA = new Encrypt()
RSA.setPublicKey(PublicKey)

export function encrypt(data, salt) {
  return RSA.encrypt(data + salt)
}

export function decrypt(data, salt) {
  return RSA.decrypt(data + salt)
}
