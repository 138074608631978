import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { Toast } from 'antd-mobile'
import { setLoadingVisible } from 'store/siteConfig'

// ------------------------------------
// Constants
// ------------------------------------
export const RISK_UNAUTH_REPORT = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null
}, 'RISK_UNAUTH_REPORT')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: 'test.json',
      requestAction: RISK_UNAUTH_REPORT.INIT_REQUEST,
      successAction: RISK_UNAUTH_REPORT.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

export function submit(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/appeal/submitAppeal',
        method: 'POST',
        body: data,
        requestAction: RISK_UNAUTH_REPORT.SUBMIT_REQUEST,
        successAction: RISK_UNAUTH_REPORT.SUBMIT_SUCCESS,
        success: function(result) {
          dispatch(setLoadingVisible(false))
          dispatch(replace('/risk/result?type=unauth'))
        },
        error: function(result, msg) {
          Toast.info(msg)
          dispatch(setLoadingVisible(false))
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RISK_UNAUTH_REPORT.INIT_REQUEST]: (state, action) => state,
  [RISK_UNAUTH_REPORT.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [RISK_UNAUTH_REPORT.SUBMIT_REQUEST]: (state, action) => state,
  [RISK_UNAUTH_REPORT.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'riskUnauthReport',
    reducerMap: {
      riskUnauthReportState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
