import React from 'react'
import { withRouter } from 'react-router-dom'
// import classes from './styles.module.scss'
// import { FormattedMessage, injectIntl } from 'react-intl'
import { NavBar } from 'antd-mobile'
class Header extends React.Component {
  // constructor (props: Props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }

  handleClick = () => {
    this.props.history.goBack()
  }

  render() {
    const { children, history, location, match, staticContext, ...restProps } = this.props
    return (
      <NavBar
        mode='light'
        icon={<span className='iconfont iconarrow-long' />}
        onLeftClick={this.handleClick}
        {...restProps}
      >
        <b>{children}</b>
      </NavBar>
    )
  }
}

export default withRouter(Header)
