import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  UNSUBSCRIBE_REQUEST: null,
  UNSUBSCRIBE_SUCCESS: null,
  OPEN_REQUEST: null,
  OPEN_SUCCESS: null,
  CLOSE_REQUEST: null,
  CLOSE_SUCCESS: null,
}, 'SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/fixqr/mgmt/subscription/queryPage',
        body: data,
        requestAction: SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.INIT_REQUEST,
        successAction: SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.INIT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function unsubscribe(data, callback) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/fixqr/mgmt/subscription/unsubscribe',
        body: data,
        requestAction: SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.UNSUBSCRIBE_REQUEST,
        successAction: SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.UNSUBSCRIBE_SUCCESS,
        success: function(result) {
          callback()
        },
      })
    )
  }
}

export function open(data, callback) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/fixqr/mgmt/subscription/open',
        body: data,
        requestAction: SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.OPEN_REQUEST,
        successAction: SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.OPEN_SUCCESS,
        success: function(result) {
          callback()
        },
      })
    )
  }
}

export function close(data, callback) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/fixqr/mgmt/subscription/close',
        body: data,
        requestAction: SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.CLOSE_REQUEST,
        successAction: SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.CLOSE_SUCCESS,
        success: function(result) {
          callback()
        },
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.UNSUBSCRIBE_REQUEST]: (state, action) => state,
  [SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.UNSUBSCRIBE_SUCCESS]: (state, action) => state,
  [SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.OPEN_REQUEST]: (state, action) => state,
  [SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.OPEN_SUCCESS]: (state, action) => state,
  [SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.CLOSE_REQUEST]: (state, action) => state,
  [SMART_CODE_SERVICE_MANAGE_NOTIFICATIONS.CLOSE_SUCCESS]: (state, action) => state,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'smartCodeServiceManageNotifications',
    reducerMap: {
      smartCodeServiceManageNotificationsState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
