import React from 'react'
import { Flex } from 'antd-mobile'
import Div100vh from 'react-div-100vh'
import classes from './styles.module.scss'

export default class PageLayout extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    const { children } = this.props
    return (
      <Div100vh>
        <Flex direction='column' align='stretch' className={classes.content}>
          {children}
        </Flex>
      </Div100vh>
    )
  }
}

/*
  @@ props: showHeader
  对children构造额外的, 在路由组件中可根据此字段控制是否显示头部导航
*/
