import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
// import { Icon } from 'antd-mobile'

export default class MapMarker extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { data, selected = {}, lat, lng } = this.props
    const isSelected = `${selected.lat}${selected.lng}` === `${lat}${lng}`
    return (
      <div className={classes.componentsMapMarker}>
        <img
          width={isSelected ? 36 : 24}
          src={isSelected ? data.activeIcon : data.defaultIcon}
        />
        {isSelected && <span>{data.merchantName || data.name}</span>}
      </div>
    )
  }
}
