import moment from 'moment'
import _ from 'lodash'
import signHandler from '../sign'
import { getCurrentLanguage } from 'utils'

export function urlencoded(body) {
  const result = []
  // eslint-disable-next-line no-unused-vars
  for (const item in body) {
    // 去除数据中的undefined 和 空格
    if (body[item] && body[item] !== 'undefined') {
      const value = body[item].toString().replace(/ /g, '')
      result.push(`${item}=${encodeURIComponent(value)}`)
    }
  }
  return result.join('&')
}

export function formatFormData(body) {
  const formData = new FormData()
  // eslint-disable-next-line no-unused-vars
  for (const item in body) {
    if (body[item] && body[item] !== 'undefined') {
      formData.append(item, body[item])
    }
  }
  return formData
}

export default function header({ method, endpoint, body = {}, type, header }) {
  const { requestHeader, signature, ...data } = body
  // console.log(body)
  if (_.endsWith(endpoint, '.json')) {
    method = 'GET'
    type = 'dev'
    endpoint = `/api${endpoint}`
  }
  console.log(signature)
  if (signature) {
    requestHeader.sign = signHandler(data)
  }
  console.log(requestHeader)
  const reqHeaders = {
    JSON: {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Content-Language': getCurrentLanguage(),
        platform: 4,
        'Utc-Offset-Seconds': moment().utcOffset() * 60,
        ...requestHeader,
        ...header
      },
      credentials: 'include',
      body: method === 'POST' ? JSON.stringify(data) : undefined
    },
    'x-www-form-urlencoded': {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      credentials: 'include',
      body: method === 'POST' && urlencoded(data)
    },
    'multipart/form-data': {
      headers: {
        'Content-Language': getCurrentLanguage(),
        platform: 4,
        'Utc-Offset-Seconds': moment().utcOffset() * 60
      },
      credentials: 'include',
      body: formatFormData(body)
    },
    dev: {
      credentials: 'omit'
    }
  }
  return Object.assign({}, reqHeaders[type], {
    method: method,
    endpoint: `${endpoint}`
  })
}
