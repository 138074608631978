import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const COUPON_DETAIL = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'COUPON_DETAIL')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/coupon/offers/v1/auth/query-list/v/detail',
        // endpoint: '/cgs/api/coupon/offers/v1/auth/query-list/detail.json',
        method: 'POST',
        body: data,
        requestAction: COUPON_DETAIL.INIT_REQUEST,
        successAction: COUPON_DETAIL.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [COUPON_DETAIL.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [COUPON_DETAIL.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'couponDetail',
    reducerMap: {
      couponDetailState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
