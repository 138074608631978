import 'moment/locale/zh-cn'
// import 'moment/locale/ar'
// 引入moment的多语言，默认包含英文

export default {
  'react-intl': {
    zh: {
      messages: require('./zh.json'),
      locale: require('@formatjs/intl-relativetimeformat/dist/locale-data/zh')
    },
    en: {
      messages: require('./en.json'),
      locale: require('@formatjs/intl-relativetimeformat/dist/locale-data/en')
    },
    ar: {
      messages: require('./ar.json'),
      locale: require('@formatjs/intl-relativetimeformat/dist/locale-data/ar')
    }
  },
  antd: {
    zh: undefined,
    en: require('antd-mobile/lib/locale-provider/en_US'),
    ar: require('antd-mobile/lib/locale-provider/en_US')
  },
  moment: {
    zh: 'zh-CN',
    en: 'en-US',
    ar: 'ar'
  }
}

export { default as messages } from './messages'
