import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PageLayout from 'layouts/PageLayout'

const AppRouter = (route) => {
  return (
    <Switch>
      <Route
        path={route.match.path}
        render={(routeProps) => (
          <PageLayout>
            {require('./Main').default(routeProps)}
            {require('./Order').default(routeProps)}
            {require('./History').default(routeProps)}
            {require('./Report').default(routeProps)}
            {require('./Result').default(routeProps)}
            {require('./Detail').default(routeProps)}
            {require('./Event').default(routeProps)}
            {require('./Select').default(routeProps)}
            {require('./Unauth').default(routeProps)}
          </PageLayout>
        )}
      />
    </Switch>
  )
}

export default AppRouter
