import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PageLayout from 'layouts/PageLayout'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path='/topic/takeaway'
        render={(routeProps) => (
          <PageLayout>
            {require('./OrderInfo').default(routeProps)}
          </PageLayout>
        )}
      />
    </Switch>
  )
}

export default AppRouter
