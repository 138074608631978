import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const COUPON_LIST = keyMirror(
  {
    QUERY_LIST_REQUEST: null,
    QUERY_LIST_SUCCESS: null,
    QUERY_COUPON_LIST_REQUEST: null,
    QUERY_COUPON_LIST_SUCCESS: null,
    CLEAR_VOUCHER_LIST: null
  },
  'COUPON_LIST'
)

// ------------------------------------
// Actions
// ------------------------------------
export function queryList(data, callback) {
  return (dispatch) => {
    const { queryType, ...resData } = data
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: `/cgs/api/coupon/offers/v1/auth/query-list/${queryType}`,
        method: 'POST',
        body: resData,
        requestAction: COUPON_LIST.QUERY_LIST_REQUEST,
        successAction: COUPON_LIST.QUERY_LIST_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback()
          const { objType, objList = [] } = result
          console.log(objList, objType)
          if (objType === 'DD') { // 查询商户券
            const { couponNum, supplierId, objList: couponList } = _.head(objList)
            couponNum > 2 &&
              dispatch(
                queryCouponList({
                  supplierId,
                  couponEntityIds: _.chain(couponList).map('couponId').value()
                })
              )
          }
        }
      })
    )
  }
}

export function queryCouponList(data, callback) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/coupon/offers/v1/auth/supplier/coupon-list',
        method: 'POST',
        body: {
          pageNum: 0,
          pageSize: 100,
          ...data
        },
        requestAction: COUPON_LIST.QUERY_COUPON_LIST_REQUEST,
        successAction: COUPON_LIST.QUERY_COUPON_LIST_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback()
        }
      })
    )
  }
}

export function clearVoucherList() {
  return (dispatch) => {
    dispatch({
      type: COUPON_LIST.CLEAR_VOUCHER_LIST
    })
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [COUPON_LIST.QUERY_LIST_REQUEST]: (state, action) => state,
  [COUPON_LIST.QUERY_LIST_SUCCESS]: (state, action) => {
    const { objType, objList } = action.payload
    if (!objList) {
      action.payload.objList = []
    }
    if (objType === 'DD') {
      action.payload.objList = objList[0].objList
    }
    return Object.assign({}, state, action.payload)
  },
  [COUPON_LIST.QUERY_COUPON_LIST_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [COUPON_LIST.QUERY_COUPON_LIST_SUCCESS]: (state, action) => {
    // console.log(state, action.payload)
    // const supplierId = sessionStorage.getItem('supplierId')
    // const index = _.findIndex(objList, { supplierId })
    // objList[index].objList = _.uniqWith(
    //   objList[index].objList.concat(couponList),
    //   _.isEqual
    // )
    const { objList } = action.payload
    if (_.size(objList)) {
      action.payload.objList = state.objList.concat(objList) // 合并两次查询数据
    }
    return Object.assign({}, state, action.payload)
  },
  [COUPON_LIST.CLEAR_VOUCHER_LIST]: (state, action) => {
    console.log('clear')
    return Object.assign({}, state, { objList: [] })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'couponList',
    reducerMap: {
      couponListState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
