import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import _ from 'lodash'
import queryString from 'query-string'
import { isPayBy, ToPayRequest, getDomainName } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------
const DICT = {
  parking: '/cgs/api/lifecenter/createParkingBillOrder',
  utilities: '/cgs/api/lifecenter/createUnitiesOrder'
}

export const UTILITY_BILL = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null
}, 'UTILITY_BILL')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: 'test.json',
      requestAction: UTILITY_BILL.INIT_REQUEST,
      successAction: UTILITY_BILL.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

export function submit(data) {
  const { type, ...resData } = data
  if (!isPayBy()) {
    resData.redirectUrl = `${getDomainName('mtopay')}/utility/transaction/detail/{0}`
    resData.orderSource = 'HUAWEI_PETAL_SEARCH'
  }
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: isPayBy() ? DICT[type] : '/cgs/api/lifecenter/v1/unauth/create-unities-order',
        method: 'POST',
        body: resData,
        requestAction: UTILITY_BILL.SUBMIT_REQUEST,
        successAction: UTILITY_BILL.SUBMIT_SUCCESS,
        success: function(result) {
          const tokenUrl = _.chain(result).get('token').value()
          if (isPayBy()) {
            const token = queryString.parse(tokenUrl).ft
            ToPayRequest(token, (res) => {
              if (res.status === 'success' || res.status === 'paying') {
                // 订单成功或处理中跳转详情页，其他停留在当前页面
                dispatch(replace(`/utility/transaction/detail/${result.orderNo}`))
              }
            })
          } else {
            window.location.href = tokenUrl
          }
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UTILITY_BILL.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UTILITY_BILL.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UTILITY_BILL.SUBMIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [UTILITY_BILL.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'utilityBill',
    reducerMap: {
      utilityBillState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
