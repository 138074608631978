import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import configureStore from 'store'
import { isPayBy } from 'utils'
import * as serviceWorker from './serviceWorker'
import 'url-search-params-polyfill'

const history = createBrowserHistory()
const store = configureStore({}, history)

if (isPayBy()) {
  // PayBy环境下，全局注册Bridge初始化
  const onBridgeReady = () => {
    window.ToPayJSBridge.init(function(message, responseCallback) {})
  }
  if (typeof ToPayJSBridge === 'undefined') {
    document.addEventListener('ToPayJSBridgeReady', onBridgeReady, false)
  } else {
    onBridgeReady()
  }
}

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
