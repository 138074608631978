import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import _ from 'lodash'
import queryString from 'query-string'
import { getSalt, getAppSalt } from 'store/siteConfig'
import { encrypt } from 'utils/rsa'
import { Toast } from 'antd-mobile'
import { ToPayRequest, isPayBy, getUUID, getDomainName } from 'utils'

const index = isPayBy() ? 1 : 0
const DICT = {
  queryOperatorList: [
    '/cgs/api/lifecenter/v1/unauth/query-mobile-supplier',
    '/cgs/api/lifecenter/queryMobilePackageChoose'
  ],
  queryMobilePackage: [
    '/cgs/api/lifecenter/v1/unauth/query-mobile-package',
    '/cgs/api/lifecenter/queryMobilePackage'
  ],
  queryGoods: [
    '/cgs/api/lifecenter/v1/unauth/query-mobile-topup-goods',
    '/cgs/api/lifecenter/queryMobileTopUpGoodsSection'
  ],
  createOrder: [
    '/cgs/api/lifecenter/v1/unauth/create-mobile-topup-order',
    '/cgs/api/lifecenter/createMobileTopUpOrder'
  ]
}
// ------------------------------------
// Constants
// ------------------------------------
export const TOP_UP_MOBILE = keyMirror(
  {
    INIT_REQUEST: null,
    INIT_SUCCESS: null,
    QUERY_GOODS_REQUEST: null,
    QUERY_GOODS_SUCCESS: null,
    QUERY_MOBILE_PACKAGE_REQUEST: null,
    QUERY_MOBILE_PACKAGE_SUCCESS: null,
    SUBMIT_REQUEST: null,
    SUBMIT_SUCCESS: null,
    INIT_PAGE: null,
    QUERY_OPERATOR_LIST_REQUEST: null,
    QUERY_OPERATOR_LIST_SUCCESS: null,
    QUERY_POSTPAID_BILL_RECORD_REQUEST: null,
    QUERY_POSTPAID_BILL_RECORD_SUCCESS: null
  },
  'TOP_UP_MOBILE'
)

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  Toast.loading('', 0)
  const { packageType = 'Prepaid' } = queryString.parseUrl(window.location.href).query
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/initMobileTopUpPageV2',
        method: 'POST',
        body: {
          queryChannelFlag: false,
          packageTypeCode: packageType,
        },
        requestAction: TOP_UP_MOBILE.INIT_REQUEST,
        successAction: TOP_UP_MOBILE.INIT_SUCCESS,
        success: function(result) {
          const { defaultUserPackage = {} } = result
          // 本地存储数据
          if (defaultUserPackage.phone) {
            if (
              defaultUserPackage.serviceProvider &&
              defaultUserPackage.serviceProvider !== 'OTHER' // 过滤渠道不能识别的运营商
            ) {
              defaultUserPackage.chosenIconUrl = defaultUserPackage.serviceProviderIcon
              defaultUserPackage.title = defaultUserPackage.title.split('·')[0]
              if (defaultUserPackage.packageType !== packageType) {
                dispatch(diffPackageType(defaultUserPackage))
                return
              }
              dispatch(queryGoods(defaultUserPackage))
              Toast.hide()
              return
            }
            // 无套餐信息主动弹窗让用户自行选择
            setTimeout(() => {
              dispatch({
                type: TOP_UP_MOBILE.INIT_SUCCESS,
                payload: {
                  defaultUserPackage: {
                    ...defaultUserPackage,
                    selectOperator: true
                  }
                }
              })
            }, 500)
          }
          Toast.hide()
        },
      })
    )
  }
}

export function queryGoods(data, callback) {
  // 查询话费商品
  Toast.loading('', 0)
  return (dispatch) => {
    let header = {}
    const saltKey = getUUID()
    if (!isPayBy()) {
      header = {
        'Salt-Key': saltKey
      }
    }
    dispatch(isPayBy() ? getSalt() : getAppSalt(saltKey)).then((res) => {
      const { salt } = res.payload
      console.log(salt)
      const { phone, chosenIconUrl, title, ...resData } = data
      console.log(phone)
      resData.phone = encrypt(phone, salt)
      dispatch(
        callAPI({
          dispatch: dispatch,
          endpoint: DICT.queryGoods[index],
          method: 'POST',
          header,
          body: resData,
          requestAction: TOP_UP_MOBILE.QUERY_GOODS_REQUEST,
          successAction: TOP_UP_MOBILE.QUERY_GOODS_SUCCESS,
          success: function(result) {
            Toast.hide()
            if (typeof callback === 'function') {
              callback()
            } else {
              sessionStorage.setItem('defaultUserPackage', JSON.stringify(data))
              dispatch(
                initPage(false, {
                  defaultUserPackage: data
                })
              )
            }
          },
          error: function(result) {
            Toast.hide()
            dispatch(
              initPage(false, {
                goodsSectionList: [],
                lifeBillInfo: {},
                defaultUserPackage: data,
                msg: result.head.msg
              })
            )
          }
        })
      )
    })
  }
}

export function queryMobilePackage(data) {
  // 查询套餐信息
  Toast.loading('', 0)
  const { packageType = 'Prepaid' } = queryString.parseUrl(window.location.href).query
  return (dispatch) => {
    let header = {}
    const saltKey = getUUID()
    if (!isPayBy()) {
      header = {
        'Salt-Key': saltKey
      }
    }
    dispatch(isPayBy() ? getSalt() : getAppSalt(saltKey)).then(res => {
      const { salt } = res.payload
      console.log(salt)
      const phone = encrypt(data.phone, salt)
      console.log(data.phone)
      dispatch(
        callAPI({
          dispatch: dispatch,
          endpoint: DICT.queryMobilePackage[index],
          method: 'POST',
          header,
          body: {
            phone,
            packageTypeCode: packageType,
            queryChannelFlag: false
          },
          requestAction: TOP_UP_MOBILE.QUERY_MOBILE_PACKAGE_REQUEST,
          successAction: TOP_UP_MOBILE.QUERY_MOBILE_PACKAGE_SUCCESS,
          success: function(result) {
            const {
              serviceProviderCode,
              packageTypeCode,
              serviceProviderIconPath,
              serviceProviderTitle
            } = result
            if (serviceProviderCode) {
              const defaultUserPackage = {
                serviceProvider: serviceProviderCode,
                packageType: packageTypeCode,
                chosenIconUrl: serviceProviderIconPath,
                title: serviceProviderTitle,
                phone: data.phone
              }
              if (packageTypeCode !== packageType) {
                dispatch(diffPackageType(defaultUserPackage))
                return
              }
              dispatch(queryGoods(defaultUserPackage))
              return
            }
            // 无套餐信息主动弹窗让用户自行选择
            dispatch({
              type: TOP_UP_MOBILE.QUERY_MOBILE_PACKAGE_SUCCESS,
              payload: {
                defaultUserPackage: {
                  phone: data.phone,
                  selectOperator: true
                }
              }
            })
            Toast.hide()
          }
        })
      )
    })
  }
}

export function diffPackageType(data) { // 查出手机套餐与预设不一致时，展示提示信息
  return (dispatch, getState) => {
    setTimeout(() => {
      sessionStorage.setItem('defaultUserPackage', JSON.stringify(data))
      dispatch({
        type: TOP_UP_MOBILE.QUERY_MOBILE_PACKAGE_SUCCESS,
        payload: {
          defaultUserPackage: {
            different: true,
            ...data
          }
        }
      })
      Toast.hide()
    }, 100)
  }
}

export function submit(data, callback) { // 下单
  Toast.loading('', 0)
  return (dispatch) => {
    let header = {}
    const saltKey = getUUID()
    if (!isPayBy()) {
      header = {
        'Salt-Key': saltKey
      }
    }
    dispatch(isPayBy() ? getSalt() : getAppSalt(saltKey)).then((res) => {
      const { salt } = res.payload
      console.log(salt)
      const { phone, ...resData } = data
      console.log(phone)
      resData.targetPhone = encrypt(phone, salt)
      if (!isPayBy()) {
        resData.redirectUrl = `${getDomainName('mtopay')}/topUp/result/{0}`
        resData.orderSource = 'HUAWEI_PETAL_SEARCH'
      }
      dispatch(
        callAPI({
          dispatch: dispatch,
          method: 'POST',
          body: resData,
          header,
          endpoint: DICT.createOrder[index],
          requestAction: TOP_UP_MOBILE.SUBMIT_REQUEST,
          successAction: TOP_UP_MOBILE.SUBMIT_SUCCESS,
          success: function(result) {
            typeof callback === 'function' && callback()
            const tokenUrl = _.chain(result).get('token').value()
            if (isPayBy()) {
              const token = queryString.parse(tokenUrl).ft
              ToPayRequest(token, (res) => {
                if (res.status === 'success' || res.status === 'paying') {
                  dispatch(replace(`/topUp/result/${result.orderNo}`))
                }
              })
            } else {
              window.location.href = tokenUrl
            }
          }
        })
      )
    })
  }
}

export function initPage(initial, data) {
  console.log(data)
  return {
    type: TOP_UP_MOBILE.INIT_PAGE,
    payload: {
      initPage: !!initial,
      ...data
    }
  }
}

export function queryOperatorList(data) {
  // 查询运营商列表
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: DICT.queryOperatorList[index],
        method: 'POST',
        body: data,
        requestAction: TOP_UP_MOBILE.QUERY_OPERATOR_LIST_REQUEST,
        successAction: TOP_UP_MOBILE.QUERY_OPERATOR_LIST_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function queryPostpaidBillRecord(data) {
  // 根据id查询后付费手机号
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/queryPostpaidBillRecord',
        method: 'POST',
        body: data,
        requestAction: TOP_UP_MOBILE.QUERY_POSTPAID_BILL_RECORD_REQUEST,
        successAction: TOP_UP_MOBILE.QUERY_POSTPAID_BILL_RECORD_SUCCESS,
        success: function(result) {
          const { accountNumber } = result
          if (accountNumber) {
            dispatch(
              queryMobilePackage({
                phone: accountNumber
              })
            )
          }
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_UP_MOBILE.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE.QUERY_GOODS_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE.QUERY_GOODS_SUCCESS]: (state, action) => {
    // 过滤流量商品
    const { goodsSectionList = [], lifeBillInfo } = action.payload
    if (!_.size(lifeBillInfo) && _.size(goodsSectionList)) {
      action.payload.goodsSectionList = _.filter(
        goodsSectionList,
        (item) => item.categoryCode === '000100010001' && !item.activityCode
      )
    }
    return Object.assign({}, state, action.payload)
  },
  [TOP_UP_MOBILE.QUERY_MOBILE_PACKAGE_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE.QUERY_MOBILE_PACKAGE_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE.SUBMIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE.INIT_PAGE]: (state, action) => {
    const { initPage } = action.payload // 查询商品时页面默认商品置灰
    if (initPage) {
      action.payload.defaultUserPackage = {}
      action.payload.goodsSectionList = []
    }
    return Object.assign({}, state, action.payload)
  },
  [TOP_UP_MOBILE.QUERY_OPERATOR_LIST_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE.QUERY_OPERATOR_LIST_SUCCESS]: (state, action) => {
    const { packages = [] } = action.payload
    packages.map((item) => (item.title = item.title.split('·')[0]))
    return Object.assign({}, state, action.payload)
  },
  [TOP_UP_MOBILE.QUERY_POSTPAID_BILL_RECORD_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE.QUERY_POSTPAID_BILL_RECORD_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  initPage: true
}

function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topUpMobile',
    reducerMap: {
      topUpMobileState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
