import React from 'react'
import { Route, Switch } from 'react-router-dom'

const AppRouter = route => {
  return (
    <Switch>
      <Route
        path={route.match.path}
        render={routeProps => (
          <React.Fragment>
            {require('./Main').default(routeProps)}
            {require('./Parking').default(routeProps)}
            {require('./Bill').default(routeProps)}
            {require('./SetAlias').default(routeProps)}
            {require('./Transaction').default(routeProps)}
            {require('./Utilities').default(routeProps)}
          </React.Fragment>
        )}
      />
    </Switch>
  )
}

export default AppRouter
