import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const TOPIC_EATM = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'TOPIC_EATM')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: 'test.json',
      requestAction: TOPIC_EATM.INIT_REQUEST,
      successAction: TOPIC_EATM.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOPIC_EATM.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [TOPIC_EATM.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topicEAtm',
    reducerMap: {
      topicEAtmState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
