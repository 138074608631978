import React from 'react'
import classes from './styles.module.scss'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { Flex, WhiteSpace, WingBlank, List, Toast } from 'antd-mobile'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { intl } from 'utils'

export default class CustomerService extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { items = [] } = this.props
    return (
      <Flex.Item className={classes.componentsCustomerService}>
        <WhiteSpace className='bg-gray' />
        <WingBlank>
          <List className='list-separator no-border'>
            {items.indexOf('botim') > -1 && (
              <List.Item
                thumb={require('./images/BOTIM.png')}
                extra={
                  <CopyToClipboard
                    onCopy={() => {
                      Toast.info(intl.formatMessage(messages.tips), 1)
                    }}
                    text='+971585776808'
                  >
                    <span>
                      +971585776808 <img src={require('./images/copy.png')} />
                    </span>
                  </CopyToClipboard>
                }
              >
                <b>BOTIM</b>
              </List.Item>
            )}
            {items.indexOf('email') > -1 && (
              <div className='text-center'>
                <WhiteSpace size='xl' />
                <img width={80} src={require('./images/payby.png')} />
                <p className='font-sm '>
                  <FormattedMessage {...messages.contactPayBy} />
                </p>
                <div className='font-sm'>
                  <CopyToClipboard
                    onCopy={() => {
                      Toast.info(intl.formatMessage(messages.tips), 1)
                    }}
                    text='help@payby.com'
                  >
                    <div>
                      <span className={classes.copyEmail}>
                        <FormattedMessage {...messages.pleaseContact} />
                      </span>
                      <span className={classes.copyEmail}>
                        <b>help@payby.com </b>
                        <img src={require('./images/copy.png')} />
                      </span>
                    </div>
                  </CopyToClipboard>
                </div>
                <WhiteSpace size='xs' />
                <div className='font-sm'>
                  <CopyToClipboard
                    onCopy={() => {
                      Toast.info(intl.formatMessage(messages.tips), 1)
                    }}
                    text='+971 585076088'
                  >
                    <div>
                      <span className={classes.copyEmail}>
                        <FormattedMessage {...messages.whatsApp} />
                      </span>
                      <span className={classes.copyEmail}>
                        <b>+971 585076088</b>
                        <img src={require('./images/copy.png')} />
                      </span>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            )}
            {items.indexOf('phoneNo') > -1 && (
              <div className='text-center'>
                <WhiteSpace size='sm' />
                <WhiteSpace size='xl' />
                <img width={30} src={require('./images/Phone.png')} />
                <div className='font-sm '>
                  <CopyToClipboard
                    onCopy={() => {
                      Toast.info(intl.formatMessage(messages.tips), 1)
                    }}
                    text='+971585776808'
                  >
                    <div>
                      <span className={classes.copyEmail}>
                        <FormattedMessage {...messages.phone} />:
                      </span>
                      <span className={classes.copyEmail}>
                        <b>+971585776808 </b>
                        <img src={require('./images/copy.png')} />
                      </span>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </List>
          <WhiteSpace size='md' />
          <WhiteSpace size='xl' />
          <div className='bg-gray text-center'>
            <WhiteSpace />
            <WhiteSpace />
            <p>
              <b>
                <FormattedMessage {...messages.text1} />
              </b>
            </p>
            <p className='font-xs'>
              <FormattedMessage {...messages.text2} />
            </p>
            <p className='gray font-xs'>
              <FormattedMessage {...messages.text3} />
            </p>
            <WhiteSpace />
            <WhiteSpace />
          </div>
        </WingBlank>
      </Flex.Item>
    )
  }
}
