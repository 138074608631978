import { Toast } from 'antd-mobile'
import { intl, isPayBy } from 'utils'
import messages from 'languages/messages'
import { replace, push } from 'connected-react-router'
import { login } from 'store/siteConfig'
import { Modal } from 'components'

export default function resultHandle(result, dispatch, success, error) {
  const status =
    (result.head && (result.head.applyStatus || result.head.code)) ||
    result.applyStatus
  const body = result.body || result.result || {}
  switch (status) {
    case '200':
    case 'SUCCESS':
      success(body)
      break
    case '401':
    case '403':
    case '409':
    case '406':
      dispatch(login())
      break
    case '64700':
      dispatch(
        replace(`/error?desc=${result.head.msg}&error=${result.head.traceCode}`)
      )
      break
    case 'FIX_QR_CODE_NOT_FOUND':
    case 'FIX_QR_INVALID':
      dispatch(
        replace(
          '/error?desc=Due%20to%20reasons%20related%20with%20merchant%2C%20this%20payment%20has%20to%20be%20stopped.&error=Ops%2C%20error!'
        )
      )
      break
    case '17403': // 风控限额
      Toast.hide()
      Modal.alert('', result.head.msg, [
        {
          text: intl.formatMessage(messages['app.text.close'])
        },
        {
          text: intl.formatMessage(messages['app.text.viewQuota']),
          onPress: () => {
            if (isPayBy()) {
              dispatch(push('/topUp/quota'))
            } else {
              dispatch(push('/kyc/quota?payment=paypage'))
            }
          }
        }
      ])
      break
    default: {
      // 有回调方法的时候不处理弹出错误
      const msg =
        (result.head && `${result.head.msg} [${result.head.traceCode}]`) ||
        result.message ||
        intl.formatMessage(messages['app.error.system'])
      if (typeof error === 'function') {
        error(result, msg)
      } else {
        Toast.info(msg)
      }
      break
    }
  }
  return Object.assign(body, {
    isFetching: false
  })
}
