import React from 'react'
import { connect } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import modules, { init, receive } from './modules'
import Loading from 'components/Loading'
import { checkIsLogin } from 'store/siteConfig'

export default function(route) {
  const Collect = Loadable({
    loader: () => import('./Collect'),
    render(loaded, props) {
      const Component = connect(
        (state) => ({
          tipsCollectState: state.tipsCollectState,
          siteConfig: state.siteConfig,
        }),
        {
          init,
          checkIsLogin,
          receive,
        }
      )(loaded.default)
      return <Component {...props} />
    },
    loading: Loading
  })

  return (
    <Route
      path={route.match.path}
      render={(routeProps) => (
        <DynamicModuleLoader modules={[modules(routeProps.history)]}>
          <Collect {...routeProps} />
        </DynamicModuleLoader>
      )}
    />
  )
}
