import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { ImagePicker as BaseImagePicker, InputItem, Toast } from 'antd-mobile'
import { requiredRule } from 'utils/validateRules'
import _ from 'lodash'
import imageCompression from 'browser-image-compression'
export default class ImagePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValue: props.initialValue,
      files: ImagePicker.files(props.initialValue)
    }
  }

  static files(initialValue) {
    const files = []
    initialValue &&
      initialValue.split(',').map((item) =>
        files.push({
          url: item
        })
      )
    return files
  }

  handleFormValue = ({ fileId, index }) => {
    console.log(fileId, index)
    const { name, form } = this.props
    const data = form.getFieldValue(name)
    const value = {}
    if (!data) { // 首次上传
      value[name] = fileId
    } else {
      const array = data.split(',')
      if (index >= 0) { // 删除图片
        _.pullAt(array, [index])
      } else {
        array.push(fileId)
      }
      value[name] = array.join(',')
    }
    form.setFieldsValue(value)
  }

  handleUpload = (file, files) => {
    this.props.upload({ file: file, ...this.props.submitData }, (data) => {
      Toast.hide()
      this.handleFormValue(data)
      this.setState({
        files
      })
    })
  }

  handleCompression = async (file) => {
    console.log(file)
    Toast.loading('', 0)
    if (file.size < 1 * 1024 * 1024) {
      return file
    }
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 1,
      maxWidthOrHeight: 4000
    })
    console.log(compressedFile)
    return compressedFile
  }

  handleChange = async (files, type, index) => {
    console.log(files, type, index)
    if (type === 'add') {
      const file = await this.handleCompression(_.last(files).file)
      _.map(files, (item) => { item.orientation = 1 }) // 阻止图片旋转
      console.log(file)
      this.handleUpload(file, files)
    }
    if (type === 'remove') {
      this.handleFormValue({ index })
      this.setState({
        files
      })
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.initialValue !== prevState.initialValue) {
      // 编辑时获取到数据后，更新当前state的数据
      return {
        initialValue: nextProps.initialValue,
        files: ImagePicker.files(nextProps.initialValue)
      }
    }
    return null
  }

  render() {
    const { files } = this.state
    // console.log(this.props)
    const {
      form,
      name,
      initialValue,
      notRequired,
      length = 1,
      selectable = 1,
      multiple = false,
      disableDelete = false
    } = this.props
    return (
      <React.Fragment>
        {form.getFieldDecorator(
          name,
          Object.assign(
            { initialValue },
            !notRequired && requiredRule('Please upload documents', true)
          )
        )(<InputItem className='dn' />)}
        <BaseImagePicker
          length={length}
          files={files}
          multiple={multiple}
          onChange={this.handleChange}
          selectable={files.length < selectable}
          accept='image/*' // 这里必须为image/*，不然无法呼起安卓原生相机
          disableDelete={disableDelete}
          className={classes.componentsImagePicker}
        />
      </React.Fragment>
    )
  }
}
