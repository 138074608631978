import React from 'react'
import { connect } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import modules, { init, queryResult } from './modules'
import Loading from 'components/Loading'

export default function(route) {
  const Use = Loadable({
    loader: () => import('./Use'),
    render(loaded, props) {
      const Component = connect(
        (state) => ({
          couponUseState: state.couponUseState,
          siteConfig: state.siteConfig
        }),
        {
          init,
          queryResult
        }
      )(loaded.default)
      return <Component {...props} />
    },
    loading: Loading
  })

  return (
    <Route
      path={`${route.match.path}/use`}
      render={routeProps => (
        <DynamicModuleLoader modules={[modules(routeProps.history)]}>
          <Use {...routeProps} />
        </DynamicModuleLoader>
      )}
    />
  )
}
