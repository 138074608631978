import React from 'react'
import { Header } from 'components'

export default class FirstLevelHeader extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { children, exclude, icon, onLeftClick, staticContext, ...restProps } = this.props
    console.log(restProps)
    return (
      <Header icon={icon} onLeftClick={onLeftClick} {...restProps}>
        {this.props.children}
      </Header>
    )
  }
}
