import React from 'react'
import { connect } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import modules, { init, sendSms, verifySms } from './modules'
import Loading from 'components/Loading'
import { languageChange, getCaptcha, queryAreaCode } from 'store/siteConfig'

export default function(route) {
  const Login = Loadable({
    loader: () => import('./Login'),
    render(loaded, props) {
      const Component = connect(
        (state) => ({
          loginState: state.loginState,
          siteConfig: state.siteConfig,
        }),
        {
          init,
          sendSms,
          verifySms,
          languageChange,
          getCaptcha,
          queryAreaCode,
        }
      )(loaded.default)
      return <Component {...props} />
    },
    loading: Loading
  })

  return (
    <Route
      path='/login'
      render={(routeProps) => (
        <DynamicModuleLoader modules={[modules(routeProps.history)]}>
          <Login {...routeProps} />
        </DynamicModuleLoader>
      )}
    />
  )
}
