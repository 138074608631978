import React from 'react'
import { connect } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import modules, { init, updateAlias } from './modules'
import Loading from 'components/Loading'
import { languageChange } from 'store/siteConfig'

export default function(route) {
  const SetAlias = Loadable({
    loader: () => import('./SetAlias'),
    render(loaded, props) {
      const Component = connect(
        state => ({
          utilitySetAliasState: state.utilitySetAliasState,
          siteConfig: state.siteConfig
        }),
        {
          init,
          updateAlias,
          languageChange
        }
      )(loaded.default)
      return <Component {...props} />
    },
    loading: Loading
  })

  return (
    <Route
      path={`${route.match.path}/set-alias`}
      render={routeProps => (
        <DynamicModuleLoader modules={[modules(routeProps.history)]}>
          <SetAlias {...routeProps} />
        </DynamicModuleLoader>
      )}
    />
  )
}
