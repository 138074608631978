import React from 'react'
import { connect } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import modules, { init, open, close, unsubscribe } from './modules'
import Loading from 'components/Loading'
import PageLayout from 'layouts/PageLayout'
import { languageChange } from 'store/siteConfig'

export default function(route) {
  const ManageNotifications = Loadable({
    loader: () => import('./ManageNotifications'),
    render(loaded, props) {
      const Component = connect(
        (state) => ({
          smartCodeServiceManageNotificationsState:
            state.smartCodeServiceManageNotificationsState,
          siteConfig: state.siteConfig,
        }),
        {
          init,
          open,
          close,
          unsubscribe,
          languageChange
        }
      )(loaded.default)
      return (
        <PageLayout>
          <Component {...props} />
        </PageLayout>
      )
    },
    loading: Loading
  })

  return (
    <Route
      path={`${route.match.path}/manage-notifications`}
      render={routeProps => (
        <DynamicModuleLoader modules={[modules(routeProps.history)]}>
          <ManageNotifications {...routeProps} />
        </DynamicModuleLoader>
      )}
    />
  )
}
