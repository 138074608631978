import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PageLayout from 'layouts/PageLayout'

const AppRouter = route => {
  return (
    <Switch>
      <Route
        path={route.match.path}
        render={(routeProps) => (
          <PageLayout>
            {require('./List').default(routeProps)}
            {require('./Details').default(routeProps)}
            {require('./Expired').default(routeProps)}
            {require('./Use').default(routeProps)}
            {require('./Detail').default(routeProps)}
          </PageLayout>
        )}
      />
    </Switch>
  )
}

export default AppRouter
