import React from 'react'
import { Route, Switch } from 'react-router-dom'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path='/topic'
        render={routeProps => (
          <div>
            {require('./Launch').default(routeProps)}
            {require('./RedPackage').default(routeProps)}
            {require('./CashIn').default(routeProps)}
            {require('./CashOut').default(routeProps)}
            {require('./EAtm').default(routeProps)}
            {require('./ComingSoon').default(routeProps)}
            {require('./OnlineBill').default(routeProps)}
            {require('./GreenPoints').default(routeProps)}
            {require('./UPay').default(routeProps)}
            {require('./AboutGp').default(routeProps)}
            {require('./AboutNFC').default(routeProps)}
            {require('./Supermarket').default(routeProps)}
            {require('./PCRTest').default(routeProps)}
            {require('./PowerBankRental').default(routeProps)}
            <Route
              path='/topic/takeaway'
              component={require('./Takeaway').default}
            />
          </div>
        )}
      />
    </Switch>
  )
}

export default AppRouter
