import React from 'react'
import { Route, Switch } from 'react-router-dom'

const AppRouter = (route) => {
  return (
    <Switch>
      <Route
        path={route.match.path}
        render={(routeProps) => (
          <React.Fragment>
            {require('./Receive').default(routeProps)}
          </React.Fragment>
        )}
      />
    </Switch>
  )
}

export default AppRouter
