import { createIntl, createIntlCache } from 'react-intl'
import AppLanguage from 'languages'
import { getCurrentLanguage } from 'utils'

const cache = createIntlCache()
const currentLanguage = getCurrentLanguage()
export default createIntl(
  {
    locale: currentLanguage,
    messages: AppLanguage['react-intl'][currentLanguage].messages
  },
  cache
)
