// import React from 'react'
// import { Route, Switch } from 'react-router-dom'

const AppRouter = (route) => {
  window.location.href = 'https://mpaypage.payby.com/maps/merchants'
  return false
  // return (
  //   <Switch>
  //     <Route
  //       path={route.match.path}
  //       render={(routeProps) => (
  //         <React.Fragment>
  //           {require('./List').default(routeProps)}
  //           {require('./Map').default(routeProps)}
  //         </React.Fragment>
  //       )}
  //     />
  //   </Switch>
  // )
}

export default AppRouter
