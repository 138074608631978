import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { Toast } from 'antd-mobile'

// ------------------------------------
// Constants
// ------------------------------------
export const KYC_BASIC_REFERRER = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  QUERY_RECORDS_REQUEST: null,
  QUERY_RECORDS_SUCCESS: null,
  QUERY_REWARDS_REQUEST: null,
  QUERY_REWARDS_SUCCESS: null,
  SHOW_PAGE: null
}, 'KYC_BASIC_REFERRER')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: '/cgs/api/marketing/kyc/commission/info',
      method: 'POST',
      body: {
        commissionType: 'basic'
      },
      requestAction: KYC_BASIC_REFERRER.INIT_REQUEST,
      successAction: KYC_BASIC_REFERRER.INIT_SUCCESS,
      success: function(result) {
        dispatch({
          type: KYC_BASIC_REFERRER.SHOW_PAGE
        })
        // dispatch(queryRecords({ eventId: result.detailInfo.eventId }))
        dispatch(queryRewards())
      },
      error: function(result, msg) {
        console.log(result.head)
        Toast.info(msg, 2, () => {
          dispatch({
            type: KYC_BASIC_REFERRER.SHOW_PAGE
          })
        })
        result.head.code === '70419' &&
          dispatch({
            type: KYC_BASIC_REFERRER.INIT_SUCCESS,
            payload: {
              overFlag: true
            }
          })
      }
    }))
  }
}

export function queryRecords(data) { // 查询邀请列表
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/marketing/kyc/commission/inviteeRecords',
        method: 'POST',
        body: data,
        requestAction: KYC_BASIC_REFERRER.QUERY_RECORDS_REQUEST,
        successAction: KYC_BASIC_REFERRER.QUERY_RECORDS_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function queryRewards(data) {
  // 查询奖励信息
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/marketing/reward/total',
        method: 'POST',
        requestAction: KYC_BASIC_REFERRER.QUERY_REWARDS_REQUEST,
        successAction: KYC_BASIC_REFERRER.QUERY_REWARDS_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function showPage() {
  return dispatch => {
    dispatch({
      type: KYC_BASIC_REFERRER.SHOW_PAGE
    })
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [KYC_BASIC_REFERRER.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [KYC_BASIC_REFERRER.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [KYC_BASIC_REFERRER.QUERY_RECORDS_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [KYC_BASIC_REFERRER.QUERY_RECORDS_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [KYC_BASIC_REFERRER.QUERY_REWARDS_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [KYC_BASIC_REFERRER.QUERY_REWARDS_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [KYC_BASIC_REFERRER.SHOW_PAGE]: (state, action) =>
    Object.assign({ showPage: true }, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: true,
  inviteFriendsCount: 0
}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'kycBasicReferrer',
    reducerMap: {
      kycBasicReferrerState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
