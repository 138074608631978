import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { getSalt } from 'store/siteConfig'
import { Toast } from 'antd-mobile'
import { signInWithRedirect } from 'utils'
import queryString from 'query-string'

// ------------------------------------
// Constants
// ------------------------------------
export const OAUTH_AUTO_REDIRECT = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  VERIFY_PASSWORD_REQUEST: null,
  VERIFY_PASSWORD_SUCCESS: null,
  BIND_ACCOUNT_REQUEST: null,
  BIND_ACCOUNT_SUCCESS: null,
}, 'OAUTH_AUTO_REDIRECT')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: 'test.json',
      requestAction: OAUTH_AUTO_REDIRECT.INIT_REQUEST,
      successAction: OAUTH_AUTO_REDIRECT.INIT_SUCCESS,
      success: function(result) {}
    }))
  }
}

export function verifyPassword(data, successCallback, errCallback) {
  return (dispatch, getState) => {
    const { platform } = queryString.parse(getState().router.location.search)
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: {
          ...data,
          requestId:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
        },
        endpoint: '/cgs/api/ups/v2/verify-payment-password',
        requestAction: OAUTH_AUTO_REDIRECT.VERIFY_PASSWORD_REQUEST,
        successAction: OAUTH_AUTO_REDIRECT.VERIFY_PASSWORD_SUCCESS,
        success: function(result) {
          signInWithRedirect(platform)
        },
        error: function(result, msg) {
          Toast.info(msg, 2, () => {
            dispatch(getSalt())
            errCallback()
          })
        },
      })
    )
  }
}

export function bindAccount(data) {
  return (dispatch, getState) => {
    const { returnUrl } = queryString.parse(getState().router.location.search)

    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: {
          ...data,
          requestId:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
        },
        endpoint: '/cgs/api/ups/v2/oauthclient/auth-info-by-access-token',
        requestAction: OAUTH_AUTO_REDIRECT.BIND_ACCOUNT_REQUEST,
        successAction: OAUTH_AUTO_REDIRECT.BIND_ACCOUNT_SUCCESS,
        success: function(result) {
          Toast.info('Bind successfully.', 2, () => {
            window.location.href = returnUrl
          })
        },
        error: function(result, msg) {
          Toast.info(msg, 2, () => {
            window.location.href = returnUrl
          })
        },
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [OAUTH_AUTO_REDIRECT.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [OAUTH_AUTO_REDIRECT.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [OAUTH_AUTO_REDIRECT.VERIFY_PASSWORD_REQUEST]: (state, action) => state,
  [OAUTH_AUTO_REDIRECT.VERIFY_PASSWORD_SUCCESS]: (state, action) => state,
  [OAUTH_AUTO_REDIRECT.BIND_ACCOUNT_REQUEST]: (state, action) => state,
  [OAUTH_AUTO_REDIRECT.BIND_ACCOUNT_SUCCESS]: (state, action) => state,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'oauthAutoRedirect',
    reducerMap: {
      oauthAutoRedirectState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
