import React from 'react'
import { Route, Switch } from 'react-router-dom'
// import PageLayout from 'layouts/PageLayout'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path='/topUp/auto-recharge'
        render={(routeProps) => (
          <div>
            {require('./Activate').default(routeProps)}
            {require('./List').default(routeProps)}
            {require('./Result').default(routeProps)}
            {require('./Sign').default(routeProps)}
          </div>
        )}
      />
    </Switch>
  )
}

export default AppRouter
