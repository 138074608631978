import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { languageChange } from 'store/siteConfig'

// ------------------------------------
// Constants
// ------------------------------------
export const STORES_LIST = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'STORES_LIST')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  const { pageNum, couponId } = data
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/coupon/coupon-detail/v1/auth/coupon-storeList',
        method: 'POST',
        body: {
          couponId,
          pageNum,
          pageSize: 10
        },
        requestAction: STORES_LIST.INIT_REQUEST,
        successAction: STORES_LIST.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [STORES_LIST.INIT_REQUEST]: (state, action) => state,
  [STORES_LIST.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'storesList',
    reducerMap: {
      storesListState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)],
    // Actions to fire when this module is added/removed
    initialActions: [languageChange('en')],
    // finalActions: []
  }
}
