export function init(params) {
  return new Promise((resolve, reject) => {
    function onBridgeReady() {
      try {
        // window.ToPayJSBridge.init 二次调用native的init方法会抛异常
        window.ToPayJSBridge.init(function(message, responseCallback) {})
      } catch (error) {
        console.log(error)
        // return reject(new Error('JSBridge: Bridge init failed'))
      }
      return resolve()
    }

    // 判断全局的Bridge对象，存在即直接调用，不存在则监听回调事件
    console.log(typeof ToPayJSBridge)
    if (typeof ToPayJSBridge === 'undefined') {
      document.addEventListener('ToPayJSBridgeReady', onBridgeReady, false) // ToPayJSBridgeReady
    } else {
      onBridgeReady()
    }
  })
}

export function recordEvent(data) {
  // appsFlyer数据埋点
  init().then((res) => {
    window.ToPayJSBridge.invoke('recordEvent', data)
  })
}

export function openNative(url, needCallBack, callback) {
  // h5打开native原生功能
  console.log(url, needCallBack)
  init().then((res) => {
    window.ToPayJSBridge.invoke(
      'openNative',
      { router: url, needCallback: needCallBack || false },
      function(data) {
        const res = JSON.parse(data)
        console.log(res)
        typeof callback === 'function' && callback(res)
      }
    )
  })
}

export function closeWebView() {
  // 关闭webView
  init().then((res) => {
    window.ToPayJSBridge.invoke('leaveWeb')
  })
}

export function scanQRCode() {
  // 呼起扫描二维码
  init().then((res) => {
    window.ToPayJSBridge.invoke('openNative', {
      router: 'route://native/sdk/scan',
      needCallback: false,
    })
  })
}

export function openByBrowser(url) {
  console.log(url)
  init().then((res) => {
    window.ToPayJSBridge.invoke('openByBrowser', { url })
  })
}
