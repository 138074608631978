import React from 'react'
import classes from './Loading.module.scss'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { Button, WhiteSpace } from 'antd-mobile'

function Loading(props) {
  const handleRetry = () => {
    props.retry()
  }
  if (props.error) {
    return (
      <div className={classes.componentsLoading}>
        <h3>
          <FormattedMessage {...messages.error} />
        </h3>
        <h5>
          <FormattedMessage {...messages.try} />
        </h5>
        <WhiteSpace />
        <WhiteSpace />
        <Button type='primary' onClick={handleRetry}>
          <b>
            <FormattedMessage {...messages.retry} />
          </b>
        </Button>
      </div>
    )
  } else if (props.timedOut) {
    return (
      <div className={classes.componentsLoading}>
        <h3>
          <FormattedMessage {...messages.time} />
        </h3>
        <h5>
          <FormattedMessage {...messages.try} />
        </h5>
        <WhiteSpace />
        <WhiteSpace />
        <Button type='primary' onClick={handleRetry}>
          <b>
            <FormattedMessage {...messages.retry} />
          </b>
        </Button>
      </div>
    )
  } else if (props.pastDelay) {
    return (
      <div className={classes.pastLoading}>
        {props.children || <div className='loading' />}
      </div>
    )
  } else if (props.hasMsg) { // 带message的loading组件
    return (
      <div className={classes.pastLoading}>
        <div className={classes.content}>
          {props.children}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Loading
