import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { Toast } from 'antd-mobile'

// ------------------------------------
// Constants
// ------------------------------------
export const RISK_UNAUTH_ORDER = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null
}, 'RISK_UNAUTH_ORDER')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/personal/appeal/v1/auth/query-trade-order-list',
        method: 'POST',
        body: data,
        requestAction: RISK_UNAUTH_ORDER.INIT_REQUEST,
        successAction: RISK_UNAUTH_ORDER.INIT_SUCCESS,
        success: function(result) {
          Toast.hide()
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RISK_UNAUTH_ORDER.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [RISK_UNAUTH_ORDER.INIT_SUCCESS]: (state, action) => {
    if (!action.payload.dataList) {
      action.payload.dataList = []
    }
    return Object.assign({}, state, action.payload)
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: true
}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'riskUnauthOrder',
    reducerMap: {
      riskUnauthOrderState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
