import React from 'react'
import { connect } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import modules, { init } from './modules'
import Loading from 'components/Loading'

export default function(route) {
  const BotimLogin = Loadable({
    loader: () => import('./BotimLogin'),
    render(loaded, props) {
      const Component = connect((state) => ({
        oauthBotimLoginState: state.oauthBotimLoginState,
        siteConfig: state.siteConfig
      }), {
        init
      })(loaded.default)
      return <Component {...props} />
    },
    loading: Loading
  })

  return (
    <Route
      path={`${route.match.path}/botim-login`}
      render={routeProps => (
        <DynamicModuleLoader modules={[modules(routeProps.history)]}>
          <BotimLogin {...routeProps} />
        </DynamicModuleLoader>
      )}
    />
  )
}
