import { Toast } from 'antd-mobile'
import { intl } from 'utils'
import messages from 'languages/messages'

export default function callAPIError(res, dispatch, error) {
  switch (res.status) {
    default:
      typeof error === 'function' && error()
      Toast.info(intl.formatMessage(messages['app.error.netWork']))
  }
  return {
    isFetching : false
  }
}
