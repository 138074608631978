import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

const AppRouter = route => {
  return (
    <Switch>
      <Route path='/platform/totok/customerService'>
        <Redirect to={`/platform/payby/customerService${route.location.search}`} />
      </Route>
      <Route path='/platform/botim/customerService'>
        <Redirect to={`/platform/payby/customerService${route.location.search}`} />
      </Route>
      <Route
        path={route.match.path}
        render={(routeProps) => (
          <React.Fragment>
            {require('./PayBy').default(routeProps)}
          </React.Fragment>
        )}
      />
    </Switch>
  )
}

export default AppRouter
