import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { Toast } from 'antd-mobile'

// ------------------------------------
// Constants
// ------------------------------------
export const TOPIC_ONLINEBILL = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  ORDER_STATUS: null,
}, 'TOPIC_ONLINEBILL')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: `/jollychic-service/acquire2/detail/v2${data.search}`,
        requestAction: TOPIC_ONLINEBILL.INIT_REQUEST,
        successAction: TOPIC_ONLINEBILL.INIT_SUCCESS,
        success: function(result) {
          dispatch({
            type: TOPIC_ONLINEBILL.ORDER_STATUS,
            payload: {
              EXPIRED: false
            }
          })
        },
        error: function(result) {
          Toast.hide()
          dispatch({
            type: TOPIC_ONLINEBILL.ORDER_STATUS,
            payload: {
              EXPIRED: true
            }
          })
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOPIC_ONLINEBILL.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [TOPIC_ONLINEBILL.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [TOPIC_ONLINEBILL.ORDER_STATUS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: true
}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topicOnlineBill',
    reducerMap: {
      topicOnlineBillState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
