import { defineMessages } from 'react-intl'

export default defineMessages({
  'input.placeholder': {
    id: 'components.PhoneInput.input.placeholder',
    defaultMessage: 'Mobile Number',
    description: '手机号码输入框的placeholder'
  },
  'input.errorMsg': {
    id: 'components.PhoneInput.input.errorMsg',
    defaultMessage: 'Please enter the valid mobile number',
    description: '请输入合法手机号'
  }
})
