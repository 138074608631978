import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const COUPON_EXPIRED = keyMirror({
  GET_HISTORY_REQUEST: null,
  GET_HISTORY_SUCCESS: null
}, 'COUPON_EXPIRED')

// ------------------------------------
// Actions
// ------------------------------------
export function getHistory(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/coupon/offers/v1/auth/coupon-his-list',
        method: 'POST',
        body: data,
        requestAction: COUPON_EXPIRED.GET_HISTORY_REQUEST,
        successAction: COUPON_EXPIRED.GET_HISTORY_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [COUPON_EXPIRED.GET_HISTORY_REQUEST]: (state, action) => state,
  [COUPON_EXPIRED.GET_HISTORY_SUCCESS]: (state, action) => {
    if (!action.payload.objList) {
      action.payload.objList = []
    }
    return Object.assign({}, state, action.payload)
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'couponExpired',
    reducerMap: {
      couponExpiredState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
