import React from 'react'
import { withRouter } from 'react-router'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { Tabs, InputItem } from 'antd-mobile'
import queryString from 'query-string'
import _ from 'lodash'

class MapTabs extends React.Component {
  constructor(props) {
    super(props)
    const params = queryString.parse(props.location.search)
    this.state = {
      ...params,
    }
  }

  handleChange = (tab) => {
    this.props.form.setFieldsValue({
      type: tab.value,
    })
    this.props.query()
  }

  render() {
    const { tabs, page = '2', form } = this.props
    var index = _.findIndex(tabs, { value: this.state.type })
    index = index > -1 ? index : 0
    return (
      <div
        className={`${classes.componentsMapTabs} ${
          this.state.hideNav === 'true' ? 'dn' : ''
        }`}
      >
        {form.getFieldDecorator('type', {
          initialValue: tabs[index].value
        })(<InputItem className='dn' />)}
        <Tabs
          tabs={tabs}
          initialPage={index}
          onChange={this.handleChange}
          renderTabBar={props => (
            <Tabs.DefaultTabBar {...props} page={page} />
          )}
        />
      </div>
    )
  }
}

export default withRouter(MapTabs)
