import React from 'react'
import classes from './styles.module.scss'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { WingBlank, Card, WhiteSpace, Icon, List, Toast } from 'antd-mobile'
import { handleDate, openNative } from 'utils'
import { Button } from 'components'
import { withRouter } from 'react-router'
import _ from 'lodash'

class VoucherCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      up: false
    }
  }

  // handleClick = (couponId) => {
  //   const { selectItem } = this.state
  //   if (selectItem === couponId) {
  //     this.setState({ selectItem: null })
  //   } else {
  //     this.setState({ selectItem: couponId })
  //   }
  // }

  handleUse = (item) => {
    const { couponId } = item
    const redirectUrl = item.detailUrl || item.useUrl
    if (_.startsWith(redirectUrl, 'http')) {
      if (redirectUrl.indexOf('/coupon/details') > -1) {
        // 跳转优惠券详情
        sessionStorage.setItem('couponInfo', JSON.stringify(item))
        this.props.history.push(`/coupon/details/${couponId}`)
        return
      }
      window.location.href = redirectUrl
    }
    if (_.startsWith(redirectUrl, 'route://native')) {
      // 跳转native
      openNative(redirectUrl)
    }
  }

  openKYC = () => {
    // 通过路由访问kyc
    openNative('route://native/kyc/startVerifyCenter', true, () => {
      this.props.queryKycStatus((res) => {
        if (res.kycStatus === 'KYC_FINISH') {
          window.location.reload()
        }
      })
    })
  }

  loadMore = () => {
    if (this.state.up) {
      this.setState({
        up: false
      })
    } else {
      Toast.loading('', 0)
      const { supplierId, objList } = this.props.data
      this.props.loadMore(
        {
          supplierId,
          couponEntityIds: _.chain(objList).slice(0, 2).map('couponId').value()
        },
        () => {
          this.setState({
            up: true
          })
          Toast.hide()
        }
      )
    }
  }

  renderCard = (data) => {
    if (data.styleType === 'IMG') {
      return (
        <img src={data.resourceUrl} width='100%' onClick={() => this.handleUse(data)} />
      )
    }
    const { kycStatus, invalid, type } = this.props
    // const isSelect = type === 'DD' ? this.state.selectItem === data.couponId : data.selected
    const isSelect = data.selected
    return (
      <React.Fragment>
        <Card
          className={`no-border-card ${classes.card} ${
            data.highlight && !invalid && classes.highlight
          } ${invalid && classes.invalid}`}
        >
          <Card.Body>
            <div className={classes.content}>
              {data.currency ? (
                <div className={`fontImpact ${classes.discount}`}>
                  <WhiteSpace />
                  <p className={classes.line}>{data.currency}</p>
                  <span
                    className={`${data.amount.indexOf('-') !== -1 ? classes.size22 : ''}`}
                  >
                    {data.amount && parseFloat(data.amount)}
                  </span>
                </div>
              ) : (
                <div className={`font-xs fontImpact ${classes.discount}`}>
                  <WhiteSpace />
                  {data.amount && (
                    <React.Fragment>
                      {data.amount.indexOf('%') !== -1 ? (
                        <React.Fragment>
                          <p className={classes.line}>
                            <FormattedMessage {...messages.off} />
                          </p>
                          <span>{data.amount.replace(/%/, '').replace('OFF', '')}</span>%
                        </React.Fragment>
                      ) : data.amount.indexOf('every') !== -1 ? (
                        <React.Fragment>
                          <p className={classes.line}>EVERY</p>
                          <p style={{ fontSize: '26px' }}>
                            {data.amount.replace(/\.00|every| /g, '').replace('off', '-')}
                          </p>
                        </React.Fragment>
                      ) : (
                        <span>{data.amount}</span>
                      )}
                    </React.Fragment>
                  )}
                </div>
              )}
              <WingBlank size='md' className={classes.detail}>
                <WingBlank size='sm'>
                  <h6>{data.couponName}</h6>
                  <p className={`font-xs ${classes.date}`}>
                    {handleDate(data.startTime, 'formatter4')} -{' '}
                    {handleDate(data.endTime, 'formatter4')}
                  </p>
                  <p
                    className='font-xs'
                    onClick={() => this.props.handleClick(data.couponId)}
                  >
                    <FormattedMessage {...messages.rules} />
                    <Icon type={isSelect ? 'up' : 'down'} className={classes.icon} />
                  </p>
                </WingBlank>
              </WingBlank>
              <div className={classes.btn}>
                {type === 'VD' && data.highlight && kycStatus === 'NON_KYC' ? (
                  <img src={require('./lock.png')} width='42' />
                ) : (
                  !invalid &&
                  (data.detailUrl || data.useUrl) && (
                    <React.Fragment>
                      <WhiteSpace size='sm' />
                      <Button
                        type='primary'
                        name='use'
                        size='xs'
                        onClick={() => this.handleUse(data)}
                      />
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
            {isSelect && (
              <WingBlank>
                <WhiteSpace size='sm' />
                <p
                  className={`font-xs ${classes.rules}`}
                  dangerouslySetInnerHTML={{
                    __html: data.useRules
                  }}
                />
              </WingBlank>
            )}
          </Card.Body>
        </Card>
        {data.highlight && kycStatus === 'NON_KYC' && (
          <List className='no-border no-body-border am-list-xs'>
            <List.Item
              extra={<i className='iconfont iconarrow-right-small black' />}
              onClick={this.openKYC}
            >
              <b className='font-xs'>
                <FormattedMessage {...messages.complete} />
              </b>
            </List.Item>
          </List>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { data, type } = this.props
    console.log(type)
    // if (type === 'DD') {
    //   // discounts
    //   const { up } = this.state
    //   const couponList = up ? data.objList : _.slice(data.objList, 0, 2)
    //   return (
    //     <WingBlank
    //       size='md'
    //       className={`${classes.componentsVouchersVoucherCard} ${classes.merchant}`}
    //     >
    //       <Card className={`no-border-card ${classes.card} ${classes.blueBg}`}>
    //         <WingBlank size='md'>
    //           <List className='no-side-padding am-list-middle bg-transparent'>
    //             <List.Item thumb={data.supplierLogo}>{data.supplierName}</List.Item>
    //           </List>
    //           {couponList.map((item) => (
    //             <div className={classes.coupon} key={item.couponId}>
    //               <div className='bg-white' style={{ borderRadius: '4px' }}>
    //                 {this.renderCard({ ...item, highlight: true })}
    //               </div>
    //               <WhiteSpace size='sm' />
    //             </div>
    //           ))}
    //           <WhiteSpace size='xs' />
    //           {data.couponNum > 2 && (
    //             <p
    //               className={`text-center font-xs black ${classes.more}`}
    //               onClick={() => this.loadMore(data.supplierId)}
    //             >
    //               <b>
    //                 {up ? (
    //                   <FormattedMessage {...messages.collapse} />
    //                 ) : (
    //                   <FormattedMessage {...messages.more} />
    //                 )}
    //               </b>
    //               <i
    //                 className={`iconfont iconarrow-drow-fill font-lg ${up && classes.up}`}
    //               />
    //             </p>
    //           )}
    //         </WingBlank>
    //       </Card>
    //     </WingBlank>
    //   )
    // }
    // vouchers
    return (
      <WingBlank className={classes.componentsVouchersVoucherCard}>
        {this.renderCard(data)}
      </WingBlank>
    )
  }
}

export default withRouter(VoucherCard)
