import { defineMessages } from 'react-intl'

export default defineMessages({
  changeMe: {
    id: 'components.MapCard.changeMe',
    defaultMessage: '请修改',
    description: '请修改'
  },
  hour: {
    id: 'components.MapCarousel.hour',
    defaultMessage: 'Business hour',
    description: '营业时间'
  }
})
