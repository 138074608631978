import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const E_ATM_MAP = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'E_ATM_MAP')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  const { personLocation, bounds, ...restData } = data
  restData.originPoint = personLocation
  if (bounds) {
    restData.polygonParam = { points: bounds }
  }
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/store/queryEatmStoreDistancePage',
        body: {
          pageParam: {
            number: 0,
            size: 50,
          },
          ...restData,
        },
        requestAction: E_ATM_MAP.INIT_REQUEST,
        successAction: E_ATM_MAP.INIT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [E_ATM_MAP.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [E_ATM_MAP.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  items: []
}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'eAtmMap',
    reducerMap: {
      eAtmMapState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
