import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const TOPIC_GREENPOINTS = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  ENROLL_REQUEST: null,
  ENROLL_SUCCESS: null
}, 'TOPIC_GREENPOINTS')

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cms/querySectionsByPageCode',
        method: 'POST',
        body: {
          pageCode: '20200413000000055',
          projectCode: '20200413000000054',
          requestHeader: {
            'Version-Code': '113'
          }
        },
        requestAction: TOPIC_GREENPOINTS.INIT_REQUEST,
        successAction: TOPIC_GREENPOINTS.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

export function enroll(data) { // 参加活动
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/marketing/event/enroll',
        method: 'POST',
        body: data,
        requestAction: TOPIC_GREENPOINTS.ENROLL_REQUEST,
        successAction: TOPIC_GREENPOINTS.ENROLL_SUCCESS,
        success: function(result) {
          if (result.ok) { // 报名成功后查询活动列表
            dispatch(init())
          }
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOPIC_GREENPOINTS.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [TOPIC_GREENPOINTS.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [TOPIC_GREENPOINTS.ENROLL_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [TOPIC_GREENPOINTS.ENROLL_SUCCESS]: (state, action) => Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topicGreenPoints',
    reducerMap: {
      topicGreenPointsState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
