import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { beforeCheck, getSalt, languageChange } from 'store/siteConfig'
import { encrypt } from 'utils/rsa'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const IPS_COLLECT = keyMirror(
  {
    INIT_REQUEST: null,
    INIT_SUCCESS: null,
    RECEIVE_REQUEST: null,
    RECEIVE_SUCCESS: null,
    TOGGLE_LOADING_STATUS: null,
  },
  'IPS_COLLECT'
)

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    return dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/socialpay/tips/v1/condition/receive-init',
        method: 'POST',
        body: data,
        requestAction: IPS_COLLECT.INIT_REQUEST,
        successAction: IPS_COLLECT.INIT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function receive(data) {
  return (dispatch, getState) => {
    console.log(getState().tipsCollectState.loading)
    if (getState().tipsCollectState.loading) {
      return false
    }
    return dispatch(toggleLoadingStatus(true))
      .then((res) => {
        return dispatch(beforeCheck({ scene: 'RECEIVE_TIPS' }))
      })
      .then((res) => {
        const status = _.get(res, 'payload.status')
        if (status === 'PASS') {
          return dispatch(getSalt())
        }
        throw new Error()
      })
      .then((res) => {
        const salt = _.get(res, 'payload.salt')
        const { ticket, ...restData } = data
        if (!ticket || !salt) {
          throw new Error()
        }
        restData.ticket = encrypt(ticket, salt)
        return dispatch(
          callAPI({
            dispatch: dispatch,
            endpoint: '/cgs/api/socialpay/tips/v1/auth/receive',
            method: 'POST',
            body: restData,
            requestAction: IPS_COLLECT.RECEIVE_REQUEST,
            successAction: IPS_COLLECT.RECEIVE_SUCCESS,
            success: function(result) {}
          })
        )
      })
      .then((res) => {
        dispatch(toggleLoadingStatus(false))
      })
      .catch((res) => {
        dispatch(toggleLoadingStatus(false))
      })
  }
}

export function toggleLoadingStatus(status) {
  return (dispatch) => {
    dispatch({
      type: IPS_COLLECT.TOGGLE_LOADING_STATUS,
      payload: {
        loading: status
      }
    })
    return Promise.resolve()
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [IPS_COLLECT.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [IPS_COLLECT.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [IPS_COLLECT.RECEIVE_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [IPS_COLLECT.RECEIVE_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [IPS_COLLECT.TOGGLE_LOADING_STATUS]: (state, action) =>
    Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'tipsCollect',
    reducerMap: {
      tipsCollectState: reducer,
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)],
    // Actions to fire when this module is added/removed
    initialActions: [languageChange('en')],
    // finalActions: []
  }
}
