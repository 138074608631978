import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'routes.SmartCode.title',
    defaultMessage: 'Order Info',
    description: '备注: 页面title'
  },
  remarks: {
    id: 'routes.SmartCode.remarks',
    defaultMessage: 'Add remarks',
    description: '添加评论'
  },
  less: {
    id: 'routes.SmartCode.less',
    defaultMessage: 'less than 32 characters',
    description: '至少32个字符'
  },
  manage: {
    id: 'routes.SmartCode.manage',
    defaultMessage: 'Code Management',
    description: '管理'
  },
  error: {
    id: 'routes.SmartCode.error',
    defaultMessage: 'An error occurred, please try again.',
    description: '报错'
  },
  amount: {
    id: 'routes.SmartCode.amount',
    defaultMessage: 'Amount',
    description: '金额'
  }
})
