import React from 'react'
import { connect } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import modules, { init } from './modules'
import Loading from 'components/Loading'
import { languageChange } from 'store/siteConfig'

export default function(route) {
  const Detail = Loadable({
    loader: () => import('./Detail'),
    render(loaded, props) {
      const Component = connect(
        (state) => ({
          couponDetailState: state.couponDetailState,
          siteConfig: state.siteConfig
        }),
        {
          init,
          languageChange
        }
      )(loaded.default)
      return <Component {...props} />
    },
    loading: Loading
  })

  return (
    <Route
      path={`${route.match.path}/detail`}
      render={routeProps => (
        <DynamicModuleLoader modules={[modules(routeProps.history)]}>
          <Detail {...routeProps} />
        </DynamicModuleLoader>
      )}
    />
  )
}
