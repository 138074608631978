import React from 'react'
import classes from './styles.module.scss'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { Card, WhiteSpace, List } from 'antd-mobile'
import { handleAmount, handleDate } from 'utils'

export default class RiskOrderInfo extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { data } = this.props
    console.log(data)
    return (
      <Card className={`no-border-card ${classes.componentsRiskOrderInfo}`}>
        <Card.Body>
          {data.paymentOrderNo ? (
            <React.Fragment>
              <WhiteSpace size='sm' />
              <div className='text-center'>
                <h6>{data.orderTitle}</h6>
                <h3>{handleAmount(data.amount)}</h3>
              </div>
              <WhiteSpace />
              <List className='no-border am-list-xs no-side-padding'>
                <List.Item extra={data.paymentMethod}>
                  <b className='font-sm'>
                    <FormattedMessage {...messages.payMethod} />
                  </b>
                </List.Item>
                <List.Item extra={handleDate(data.createTime)}>
                  <b className='font-sm'>
                    <FormattedMessage {...messages.createTime} />
                  </b>
                </List.Item>
                <List.Item extra={data.paymentOrderNo}>
                  <b className='font-sm'>
                    <FormattedMessage {...messages.orderNo} />
                  </b>
                </List.Item>
              </List>
            </React.Fragment>
          ) : (
            <List className='no-border am-list-xs no-side-padding'>
              <List.Item extra={data.eventDesc}>
                <b className='font-sm'>
                  <FormattedMessage {...messages.details} />
                </b>
              </List.Item>
              <List.Item extra={handleDate(data.eventTime)}>
                <b className='font-sm'>
                  <FormattedMessage {...messages.time} />
                </b>
              </List.Item>
            </List>
          )}
        </Card.Body>
      </Card>
    )
  }
}
