import React from 'react'
import classes from './styles.module.scss'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { List } from 'antd-mobile'
import _ from 'lodash'

export default class MapCard extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }

  call = (e, mobile) => {
    e.stopPropagation()
    window.location.href = `tel:${mobile}`
  }

  render() {
    const item = this.props
    const mobile = item.mobile || item.tel
    const name = item.merchantName || item.name
    const distance = item.distance || _.get(item, 'distanceParam.value')
    return (
      <List.Item className={classes.componentsMapCard} onClick={item.onClick}>
        <div className={classes.merchantName}>
          <b>{name}</b>
        </div>
        {item.businessHours && (
          <p className='font-xs black'>
            <FormattedMessage {...messages.hour} /> {item.businessHours}
          </p>
        )}
        {mobile && (
          <a onClick={(e) => this.call(e, mobile)}>
            <span className='iconfont iconMerchant-callphone green font-lg' />
          </a>
        )}
        <div className='gray font-xs'>
          {distance && `${parseInt(distance)}M - `}
          {item.address} {item.city && ` - ${item.city}`}
        </div>
      </List.Item>
    )
  }
}
