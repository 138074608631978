import React from 'react'
import { Route, Switch } from 'react-router-dom'

const AppRouter = route => {
  // 重定向至uat
  if (process.env.NODE_ENV === 'production' && window.location.hostname.indexOf('uat-') === -1) {
    window.location.replace(`https://uat-m.test2pay.com/uat/payment${window.location.search}`)
    return
  }
  return (
    <Switch>
      <Route
        path={route.match.path}
        render={routeProps => (
          <React.Fragment>
            {require('./Payment').default(routeProps)}
          </React.Fragment>
        )}
      />
    </Switch>
  )
}

export default AppRouter
