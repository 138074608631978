import { RSAA } from 'redux-api-middleware'
import resultHandle from './result'
import errorHandle from './error'
import headerHandler from './header'

const FAILURE = 'FAILURE'
export default function callAPI({ type = 'JSON', method = 'GET', endpoint, body, header, requestAction, successAction, success, error, dispatch }) {
  console.log('callAPI:body', JSON.stringify(body))
  const methods = headerHandler({
    type: type,
    method: method,
    endpoint: endpoint,
    body: body,
    header: header
  })
  return {
    [RSAA]: Object.assign({}, methods, {
      types: [
        {
          type: requestAction,
          payload: () => {
            return { isFetching: true }
          }
        },
        {
          type: successAction,
          payload: (action, state, res) => {
            return res.json().then(json => {
              const result = resultHandle(json, dispatch, success, error)
              return result
            })
          }
        },
        {
          type: FAILURE,
          payload: (action, state, res) => errorHandle(res, dispatch, error)
        }
      ]
    })
  }
}

export { default as syncSubmit } from './syncSubmit'
