import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, go } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
// import { handleIdentifyHint } from 'utils'
import { getSalt, removeSalt } from 'store/siteConfig'
import { Toast } from 'antd-mobile'
// ------------------------------------
// Constants
// ------------------------------------
export const OAUTH_ACCOUNT_MANAGE = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  BIND_ACCOUNT_REQUEST: null,
  BIND_ACCOUNT_SUCCESS: null,
  VERIFY_PASSWORD_REQUEST: null,
  VERIFY_PASSWORD_SUCCESS: null,
  SAVE_BINDING_INFO: null
}, 'OAUTH_ACCOUNT_MANAGE')

// ------------------------------------
// Actions
// ------------------------------------
export function init(callback) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: {
          requestId:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)
        },
        endpoint: '/cgs/api/ups/v2/oauthclient/query-partner-bind',
        requestAction: OAUTH_ACCOUNT_MANAGE.INIT_REQUEST,
        successAction: OAUTH_ACCOUNT_MANAGE.INIT_SUCCESS,
        success: function(result) {
          typeof callback === 'function' && callback()
        }
      })
    )
  }
}

export function bindAccount(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: {
          ...data,
          requestId:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)
        },
        endpoint: '/cgs/api/ups/v2/oauthclient/auth-info-by-access-token',
        requestAction: OAUTH_ACCOUNT_MANAGE.BIND_ACCOUNT_REQUEST,
        successAction: OAUTH_ACCOUNT_MANAGE.BIND_ACCOUNT_SUCCESS,
        success: function(result) {
          // const currentIdentifyHint = handleIdentifyHint(result.identifyHint)
          // if (currentIdentifyHint.method === 'PAYMENT_PASSWORD') {
          //   dispatch(saveAccessInfo(data))
          //   return dispatch(getSalt())
          // }
          Toast.info('Bind successfully.', 2, () => {
            dispatch(init(() => {
              setTimeout(() => {
                dispatch(go(-2)) // TODO
              }, 3000)
            }))
          })
        }
      })
    )
  }
}

export function verifyPassword(data, successCallback, errCallback) {
  return (dispatch, getState) => {
    // const { bindingInfo } = getState().oauthAccountManageState
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: {
          ...data,
          requestId:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)
        },
        endpoint: '/cgs/api/ups/v2/verify-payment-password',
        requestAction: OAUTH_ACCOUNT_MANAGE.VERIFY_PASSWORD_REQUEST,
        successAction: OAUTH_ACCOUNT_MANAGE.VERIFY_PASSWORD_SUCCESS,
        success: function(result) {
          successCallback()
          dispatch(removeSalt())
          // dispatch(bindAccount(bindingInfo)) // 验密成功去绑定
        },
        error: function(result, msg) {
          Toast.info(msg, 2, () => {
            dispatch(getSalt())
            errCallback()
          })
        }
      })
    )
  }
}

export function saveAccessInfo(data) {
  return {
    type: OAUTH_ACCOUNT_MANAGE.SAVE_BINDING_INFO,
    payload: data
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [OAUTH_ACCOUNT_MANAGE.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [OAUTH_ACCOUNT_MANAGE.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [OAUTH_ACCOUNT_MANAGE.BIND_ACCOUNT_REQUEST]: (state, action) => state,
  [OAUTH_ACCOUNT_MANAGE.BIND_ACCOUNT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [OAUTH_ACCOUNT_MANAGE.VERIFY_PASSWORD_REQUEST]: (state, action) => state,
  [OAUTH_ACCOUNT_MANAGE.VERIFY_PASSWORD_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [OAUTH_ACCOUNT_MANAGE.SAVE_BINDING_INFO]: (state, action) =>
    Object.assign({}, state, { bindingInfo: action.payload })
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'oauthAccountManage',
    reducerMap: {
      oauthAccountManageState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
