import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { redirectToOtherProject } from 'utils'
import Remittance from './Remittance'

const AppRouter = () => {
  const isBOTIM = navigator.userAgent.toLowerCase().indexOf('botim') !== -1
  const { pathname, search } = window.location
  if (isBOTIM && pathname.indexOf('smartcode') !== -1) {
    console.log(`${pathname}${search}`)
    redirectToOtherProject(`${pathname}${search}`, 'utilities')
  }
  return (
    <Switch>
      <Route
        path='/bills'
        render={(routeProps) => require('./Bills').default(routeProps)}
      />
      <Route
        path='/tips'
        render={(routeProps) => require('./Tips').default(routeProps)}
      />
      <Route
        path='/topic'
        render={(routeProps) => require('./Topic').default(routeProps)}
      />
      <Route
        path='/cashDelivery'
        render={(routeProps) => require('./CashDelivery').default(routeProps)}
      />
      <Route
        path='/cashIn'
        render={(routeProps) => require('./CashIn').default(routeProps)}
      />
      <Route
        path='/oauth'
        render={(routeProps) => require('./Oauth').default(routeProps)}
      />
      <Route
        path='/channel'
        render={(routeProps) => require('./Channel').default(routeProps)}
      />
      <Route
        path='/temporary'
        render={(routeProps) => require('./Temporary').default(routeProps)}
      />
      <Route
        path='/kyc'
        render={(routeProps) => require('./KYC').default(routeProps)}
      />
      <Route
        exact
        path='/login'
        render={(routeProps) => require('./Login').default(routeProps)}
      />
      <Route
        path='/platform'
        render={(routeProps) => require('./Platform').default(routeProps)}
      />
      <Route
        path='/rewards'
        render={(routeProps) => require('./Rewards').default(routeProps)}
      />
      <Route
        path='/topUp'
        render={(routeProps) => require('./TopUp').default(routeProps)}
      />
      <Route
        path='/agreements'
        render={(routeProps) => require('./Agreements').default(routeProps)}
      />
      <Route
        path='/feedback'
        render={(routeProps) => require('./Feedback').default(routeProps)}
      />
      <Route
        path='/smartcode'
        render={(routeProps) => require('./SmartCode').default(routeProps)}
      />
      <Route
        path='/smartcode-service'
        render={(routeProps) => {
          return require('./SmartCodeService').default(routeProps)
        }}
      />
      <Route
        path='/merchant-location'
        render={(routeProps) => {
          return require('./MerchantLocation').default(routeProps)
        }}
      />
      <Route
        path='/mobile-transfer'
        render={(routeProps) => require('./MobileTransfer').default(routeProps)}
      />
      <Route
        path='/error'
        render={(routeProps) => require('./Error').default(routeProps)}
      />
      <Route
        path='/payment-authorization'
        render={(routeProps) => {
          return require('./PaymentAuthorization').default(routeProps)
        }}
      />
      <Route
        path='/agreement'
        render={(routeProps) => require('./Agreement').default(routeProps)}
      />
      <Route
        path='/uat'
        render={(routeProps) => require('./Uat').default(routeProps)}
      />
      <Route
        path='/cash-back'
        render={(routeProps) => require('./CashBack').default(routeProps)}
      />
      <Route
        path='/coupon'
        render={(routeProps) => require('./Coupon').default(routeProps)}
      />
      <Route
        path='/e-atm'
        render={(routeProps) => require('./EAtm').default(routeProps)}
      />
      <Route
        path='/utility'
        render={(routeProps) => require('./Utility').default(routeProps)}
      />
      <Route
        path='/risk'
        render={(routeProps) => require('./Risk').default(routeProps)}
      />
      <Route
        path='/split-bill'
        render={(routeProps) => require('./SplitBill').default(routeProps)}
      />
      <Route
        path='/vam'
        render={(routeProps) => require('./VAM').default(routeProps)}
      />
      <Route
        path='/stores'
        render={(routeProps) => require('./Stores').default(routeProps)}
      />
      <Route
        path='/remittance'
        render={(routeProps) => <Remittance />}
      />
      <Route>
        <Redirect to='/platform/payby/landing' />
      </Route>
    </Switch>
  )
}

export default AppRouter
