import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const UTILITY_PARKING_FINE = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'UTILITY_PARKING_FINE')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data, callback) {
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: '/cgs/api/lifecenter/queryBillInfo',
      method: 'POST',
      body: data,
      requestAction: UTILITY_PARKING_FINE.INIT_REQUEST,
      successAction: UTILITY_PARKING_FINE.INIT_SUCCESS,
      success: function(result) {
        callback()
      }
    }))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UTILITY_PARKING_FINE.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [UTILITY_PARKING_FINE.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'utilityParkingFine',
    reducerMap: {
      utilityParkingFineState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
