import React from 'react'
import { Route, Switch } from 'react-router-dom'

const AppRouter = route => {
  return (
    <Switch>
      <Route
        path={route.match.path}
        render={(routeProps) => (
          <React.Fragment>
            {require('./Quota').default(routeProps)}
            {require('./Mobile').default(routeProps)}
            {require('./MobileNumber').default(routeProps)}
            {require('./Data').default(routeProps)}
            {require('./Result').default(routeProps)}
            {require('./AutoRecharge').default(routeProps)}
            {require('./International').default(routeProps)}
            {require('./Main').default(routeProps)}
          </React.Fragment>
        )}
      />
    </Switch>
  )
}

export default AppRouter
