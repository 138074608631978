import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYMENT_AUTHORIZATION_CALL_APP_PROCESS = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'PAYMENT_AUTHORIZATION_CALL_APP_PROCESS')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    return dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: data,
        endpoint: '/cgs/api/cashdesk/v1/unauth/queryH5ProtocolApply',
        requestAction: PAYMENT_AUTHORIZATION_CALL_APP_PROCESS.INIT_REQUEST,
        successAction: PAYMENT_AUTHORIZATION_CALL_APP_PROCESS.INIT_SUCCESS,
        success: function(result) {},
        error: function(result) {
          dispatch(
            replace(
              `/error?desc=${result.head.msg}&error=${result.head.traceCode}`
            )
          )
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PAYMENT_AUTHORIZATION_CALL_APP_PROCESS.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [PAYMENT_AUTHORIZATION_CALL_APP_PROCESS.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'paymentAuthorizationCallAppProcess',
    reducerMap: {
      paymentAuthorizationCallAppProcessState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
