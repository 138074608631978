export const authScopes = {
  google: ['profile', 'email'],
  facebook: [
    'user_friends',
    'user_hometown',
    'user_likes',
    'user_location',
    'groups_access_member_info',
    'user_photos',
    'user_posts',
    'instagram_basic',
    'instagram_manage_insights',
    'user_videos',
  ],
}

export default function(platform) {
  const firebase = window.firebase
  switch (platform) {
    case 'google':
      var googleProvider = new firebase.auth.GoogleAuthProvider()
      googleProvider.setCustomParameters({
        prompt: 'select_account',
      })
      // googleProvider.addScope('https://www.googleapis.com/auth/gmail.labels')
      // googleProvider.addScope('https://www.googleapis.com/auth/gmail.metadata')
      // googleProvider.addScope('https://www.googleapis.com/auth/gmail.readonly')
      // googleProvider.addScope('https://www.googleapis.com/auth/youtube.readonly')
      // googleProvider.addScope('https://www.googleapis.com/auth/yt-analytics.readonly')
      // googleProvider.addScope('profile')
      // googleProvider.addScope('email')
      authScopes.google.map((item) => {
        googleProvider.addScope(item)
      })
      firebase.auth().signInWithRedirect(googleProvider)
      break
    case 'facebook':
      var facebookProvider = new firebase.auth.FacebookAuthProvider()
      facebookProvider.setCustomParameters({
        prompt: 'select_account',
      })
      // facebookProvider.addScope('https://www.googleapis.com/auth/gmail.labels')
      // facebookProvider.addScope('https://www.googleapis.com/auth/gmail.metadata')
      // facebookProvider.addScope('https://www.googleapis.com/auth/gmail.readonly')
      // facebookProvider.addScope('https://www.googleapis.com/auth/youtube.readonly')
      // facebookProvider.addScope('https://www.googleapis.com/auth/yt-analytics.readonly')
      // // p0
      // facebookProvider.addScope('email')
      // // p1
      // facebookProvider.addScope('user_friends')
      // facebookProvider.addScope('user_hometown')
      // facebookProvider.addScope('user_likes')
      // facebookProvider.addScope('user_location')
      // // p2
      // facebookProvider.addScope('groups_access_member_info')
      // facebookProvider.addScope('user_photos')
      // facebookProvider.addScope('user_posts')
      // // p3
      // facebookProvider.addScope('instagram_basic')
      // facebookProvider.addScope('instagram_manage_insights')
      // facebookProvider.addScope('user_videos')
      authScopes.facebook.map((item) => {
        facebookProvider.addScope(item)
      })
      firebase.auth().signInWithRedirect(facebookProvider)
      break

    default:
      break
  }
}
