import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const SMART_CODE_SERVICE_MAIN = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBSCRIB_REQUEST: null,
  SUBSCRIB_SUCCESS: null,
  CHANGE_SUBSCRIB_STATUS: null,
  QUERY_HISTORY_REQUEST: null,
  QUERY_HISTORY_SUCCESS: null,
}, 'SMART_CODE_SERVICE_MAIN')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/fixqr/mgmt/subscription/get',
        body: data,
        requestAction: SMART_CODE_SERVICE_MAIN.INIT_REQUEST,
        successAction: SMART_CODE_SERVICE_MAIN.INIT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function subscrib(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/fixqr/mgmt/subscription/subscribe',
        body: data,
        requestAction: SMART_CODE_SERVICE_MAIN.SUBSCRIB_REQUEST,
        successAction: SMART_CODE_SERVICE_MAIN.SUBSCRIB_SUCCESS,
        success: function(result) {
          dispatch({
            type: SMART_CODE_SERVICE_MAIN.CHANGE_SUBSCRIB_STATUS,
          })
        },
      })
    )
  }
}

export function queryHistory(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/fixqr/mgmt/order/queryPage',
        body: data,
        requestAction: SMART_CODE_SERVICE_MAIN.QUERY_HISTORY_REQUEST,
        successAction: SMART_CODE_SERVICE_MAIN.QUERY_HISTORY_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SMART_CODE_SERVICE_MAIN.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SMART_CODE_SERVICE_MAIN.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [SMART_CODE_SERVICE_MAIN.SUBSCRIB_REQUEST]: (state, action) => state,
  [SMART_CODE_SERVICE_MAIN.SUBSCRIB_SUCCESS]: (state, action) => state,
  [SMART_CODE_SERVICE_MAIN.CHANGE_SUBSCRIB_STATUS]: (state, action) => {
    return Object.assign({}, state, { subscribed: true })
  },
  [SMART_CODE_SERVICE_MAIN.QUERY_HISTORY_REQUEST]: (state, action) => state,
  [SMART_CODE_SERVICE_MAIN.QUERY_HISTORY_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'smartCodeServiceMain',
    reducerMap: {
      smartCodeServiceMainState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
