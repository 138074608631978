import { defineMessages } from 'react-intl'

// Tips: 全局配置语言添加app前缀做区分

const demo = defineMessages({
  'app.demo.text': {
    id: 'app.demo.text',
    description: '示例',
    defaultMessage: 'app demo text'
  }
})

const error = defineMessages({
  'app.error.netWork': {
    id: 'app.error.netWork',
    description: '网络错误',
    defaultMessage: 'Network error, please try again later！'
  },
  'app.error.system': {
    id: 'app.error.system',
    description: '系统错误',
    defaultMessage: 'System error, please try it later!'
  },
  'app.error.openUriToastInfo': {
    id: 'app.error.openUriToastInfo',
    defaultMessage: 'Only support Android and iOS system!',
    description: 'open Uri Toast Info'
  },
  'app.error.downloadNotSupport': {
    id: 'app.error.downloadNotSupport',
    defaultMessage: 'Operation System not available yet!',
    description: 'download Not Support'
  },
  'app.error.getCode': {
    id: 'app.error.getCode',
    defaultMessage: 'Please get your code.',
    description: 'get code'
  },
  'app.error.enableAccess': {
    id: 'app.error.enableAccess',
    defaultMessage:
      'Please enable the Contacts Access from the settings menu of your mobile device.',
    description: 'enable the Contacts Access'
  },
  'app.error.noDueAmount': {
    id: 'app.error.noDueAmount',
    defaultMessage: 'Hmm. Currently, you do not have an amount due.',
    description: 'enable the Contacts Access'
  },
  'app.error.failed': {
    id: 'app.error.failed',
    defaultMessage: 'Get auth code failed.',
    description: '获取botim授权失败'
  },
  'app.error.getFailed': {
    id: 'app.error.getFailed',
    defaultMessage: 'Get user info failed.',
    description: '获取用户信息失败'
  },
  'app.error.expired': {
    id: 'app.error.expired',
    defaultMessage:
      'Sorry, The verification information is expired. Please try again later.',
    description: '登录过期'
  }
})

const text = defineMessages({
  'app.text.close': {
    id: 'app.text.close',
    defaultMessage: 'Close',
    description: '关闭提示'
  },
  'app.text.viewQuota': {
    id: 'app.text.viewQuota',
    defaultMessage: 'View Quota',
    description: '查看限额'
  },
  'app.text.pending': {
    id: 'app.text.pending',
    defaultMessage: 'Dispute in progress',
    description: '审核中'
  },
  'app.text.supplement': {
    id: 'app.text.supplement',
    defaultMessage: 'Need more docs',
    description: '补充资料'
  },
  'app.text.reject': {
    id: 'app.text.reject',
    defaultMessage: 'Rejected',
    description: '拒绝'
  },
  'app.text.pass': {
    id: 'app.text.pass',
    defaultMessage: 'Approved',
    description: '通过'
  },
  'app.text.copySuccess': {
    id: 'app.text.copySuccess',
    defaultMessage: 'Copy successfully',
    description: '复制成功'
  },
  'app.text.accept': {
    id: 'app.text.accept',
    defaultMessage: 'Approved',
    description: '通过'
  },
  'app.text.deny': {
    id: 'app.text.deny',
    defaultMessage: 'Deny',
    description: '拒绝'
  }
})

const rules = defineMessages({
  numberRule: {
    id: 'app.rules.numberRule',
    defaultMessage: 'Please enter your account number',
    description: '请输入账号'
  },
  requiredRule: {
    id: 'app.rules.requiredRule',
    defaultMessage: '{item} is required, Please Check it!',
    description: '非空校验'
  },
  mobileRule: {
    id: 'app.rules.mobileRule',
    defaultMessage: 'Please enter the valid mobile number',
    description: '手机号校验'
  }
})

export default {
  ...demo,
  ...error,
  ...text,
  ...rules
}
