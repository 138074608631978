import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import queryString from 'query-string'

// ------------------------------------
// Constants
// ------------------------------------
export const REWARDS_MAIN = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'REWARDS_MAIN')

// ------------------------------------
// Actions
// ------------------------------------
export function init(pageNum = 0) {
  return (dispatch, getState) => {
    const params = queryString.parse(getState().router.location.search) // 根据场景码区分活动
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        body: {
          pageSize: 20,
          pageNum,
          ...params
        },
        endpoint: '/cgs/api/marketing/reward/list',
        requestAction: REWARDS_MAIN.INIT_REQUEST,
        successAction: REWARDS_MAIN.INIT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function getRewardInfo() {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/marketing/reward/total',
        requestAction: REWARDS_MAIN.INIT_REQUEST,
        successAction: REWARDS_MAIN.INIT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

export function checkIsCashier() {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        method: 'POST',
        endpoint: '/cgs/api/customerFrontend/cashier/isCashier',
        requestAction: REWARDS_MAIN.INIT_REQUEST,
        successAction: REWARDS_MAIN.INIT_SUCCESS,
        success: function(result) {},
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [REWARDS_MAIN.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [REWARDS_MAIN.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'rewardsMain',
    reducerMap: {
      rewardsMainState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
