import emojiRegex from 'emoji-regex/RGI_Emoji'
import { formatIncompletePhoneNumber, parseIncompletePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import { intl } from 'utils'
import messages from 'languages/messages'

export function requiredRule(item, isMsg) {
  return {
    rules: [
      {
        required: true,
        message: isMsg ? item : intl.formatMessage(messages.requiredRule, { item })
      }
    ]
  }
}
export function amountRule(item, isMsg) {
  return {
    normalize: (value, prev) => {
      if (/^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/.test(value) || value === '') {
        return value
      }
      return prev
    },
    rules: [
      {
        required: true,
        message: isMsg ? item : intl.formatMessage(messages.requiredRule, { item })
      }
    ]
  }
}
export function requiredArrayRule(item) {
  return {
    rules: [
      {
        required: true,
        type: 'array',
        min: 1,
        message: intl.formatMessage(messages.requiredRule, { item })
      }
    ]
  }
}
export function emailRule(required, msg) {
  return {
    rules: [
      {
        required: !!required,
        type: 'email',
        message: msg || 'Please Check The E-mail Format'
      }
    ]
  }
}
export function removeEmoji(msg, required) {
  return {
    normalize: (value, prev) => {
      const regex = emojiRegex()
      if (regex.test(value)) {
        return prev
      }
      return value
    },
    rules: [
      {
        required: !!required,
        message: msg,
      },
    ],
  }
}

export function mobileRule() {
  return {
    validateTrigger: 'onBlur',
    normalize: (value = '', prev = '') => {
      const country = 'AE'
      if (value.length === 1 && value !== '0') {
        // 首位补0
        return `0${value}`
      }
      if (value === prev) {
        return value
      }
      // 调用国际化组件按国家/地区的格式对输入手机号格式化
      if (
        parseIncompletePhoneNumber(value) === parseIncompletePhoneNumber(prev)
      ) {
        return parseIncompletePhoneNumber(value)
      }
      return formatIncompletePhoneNumber(value, country)
    },
    rules: [
      {
        required: true,
        len: 12,
        pattern: /^05[0-9 ]{10}$/,
        message: intl.formatMessage(messages.mobileRule)
      }
    ]
  }
}

export function intlMobileRule(countryCode, needFormat = true) {
  return {
    validateTrigger: 'onBlur',
    normalize: (value = '', prev = '') => {
      // 调用libphonenumber按国家/地区的格式对输入手机号格式化
      if (value === prev || !needFormat) {
        return value
      }
      if (countryCode === 'AE' && value.length === 1 && value !== '0') {
        // UAE的手机号自动补0，以便匹配格式化
        return `0${value}`
      }
      if (
        parseIncompletePhoneNumber(value) === parseIncompletePhoneNumber(prev)
      ) {
        return parseIncompletePhoneNumber(value)
      }
      return formatIncompletePhoneNumber(value, countryCode)
    },
    rules: [
      {
        required: true,
        message: intl.formatMessage(messages.mobileRule),
      },
      {
        validator: (rules, value, callback) => {
          // 调用libphonenumber做手机号格式的校验
          const errors = []
          if (!isValidPhoneNumber(value, countryCode)) {
            errors.push(new Error(intl.formatMessage(messages.mobileRule)))
          }
          callback(errors)
        },
      },
    ],
  }
}

export function numberRule(msg) {
  return {
    validateTrigger: 'onBlur',
    normalize: (value, prev) => {
      if (/^[0-9]*$/.test(value)) {
        return value
      }
      return prev
    },
    rules: [
      {
        required: true,
        pattern: /^[0-9]{10}$/,
        message: msg || intl.formatMessage(messages.numberRule)
      }
    ]
  }
}
