import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { getPublicConfig } from 'utils'
import { Toast } from 'antd-mobile'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYMENT_AUTHORIZATION_AUTO_DEBIT = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  SUBMIT_REQUEST: null,
  SUBMIT_SUCCESS: null,
  SIGN_REQUEST: null,
  SIGN_SUCCESS: null
}, 'PAYMENT_AUTHORIZATION_AUTO_DEBIT')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cashdesk/protocol/querySimpleApply',
        method: 'POST',
        body: data,
        requestAction: PAYMENT_AUTHORIZATION_AUTO_DEBIT.INIT_REQUEST,
        successAction: PAYMENT_AUTHORIZATION_AUTO_DEBIT.INIT_SUCCESS,
        success: function(result) {
          if (result.applyInfo) { // 缓存签约详情，结果页展示用
            sessionStorage.setItem(
              'protocolInfo',
              JSON.stringify(result.applyInfo)
            )
          }
        }
      })
    )
  }
}

export function submit(data) {
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cashdesk/protocol/signConfirm',
        method: 'POST',
        body: data,
        requestAction: PAYMENT_AUTHORIZATION_AUTO_DEBIT.SUBMIT_REQUEST,
        successAction: PAYMENT_AUTHORIZATION_AUTO_DEBIT.SUBMIT_SUCCESS,
        success: function(result) {
          console.log(result)
          if (result.identifyHint) { // 调用native核身方法
            const signData = data
            window.ToPayJSBridge.invoke(
              'openRiskIdentify',
              {
                eventType: 'PAYMENT',
                ...result.identifyHint
              },
              function(data) {
                if (data) {
                  const res = JSON.parse(data)
                  if (res.result === 'pass') {
                    console.log(signData)
                    getPublicConfig(publicInfo => {
                      console.log(publicInfo)
                      dispatch(
                        sign({
                          ...signData,
                          ...publicInfo
                        })
                      )
                    })
                  } else {
                    res.message && Toast.info(res.message)
                  }
                }
              }
            )
          }
        }
      })
    )
  }
}

export function sign(data) {
  console.log(data)
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/cashdesk/protocol/sign',
        method: 'POST',
        body: data,
        requestAction: PAYMENT_AUTHORIZATION_AUTO_DEBIT.SIGN_REQUEST,
        successAction: PAYMENT_AUTHORIZATION_AUTO_DEBIT.SIGN_SUCCESS,
        success: function(result) {
          dispatch(replace('/payment-authorization/result'))
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PAYMENT_AUTHORIZATION_AUTO_DEBIT.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [PAYMENT_AUTHORIZATION_AUTO_DEBIT.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [PAYMENT_AUTHORIZATION_AUTO_DEBIT.SUBMIT_REQUEST]: (state, action) => state,
  [PAYMENT_AUTHORIZATION_AUTO_DEBIT.SUBMIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [PAYMENT_AUTHORIZATION_AUTO_DEBIT.SIGN_REQUEST]: (state, action) => state,
  [PAYMENT_AUTHORIZATION_AUTO_DEBIT.SIGN_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'paymentAuthorizationAutoDebit',
    reducerMap: {
      paymentAuthorizationAutoDebitState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
