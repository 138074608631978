import { defineMessages } from 'react-intl'

export default defineMessages({
  payMethod: {
    id: 'components.RiskOrderInfo.payMethod',
    defaultMessage: 'Payment Method',
    description: '支付方式'
  },
  createTime: {
    id: 'components.RiskOrderInfo.createTime',
    defaultMessage: 'Creation Time',
    description: '创建时间'
  },
  orderNo: {
    id: 'components.RiskOrderInfo.orderNo',
    defaultMessage: 'Order No.',
    description: '订单号'
  },
  details: {
    id: 'components.RiskOrderInfo.details',
    defaultMessage: 'Issue details',
    description: '申诉详情'
  },
  time: {
    id: 'components.RiskOrderInfo.time',
    defaultMessage: 'Time',
    description: '创建时间'
  }
})
