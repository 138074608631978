import { defineMessages } from 'react-intl'

export default defineMessages({
  submit: {
    id: 'components.Button.submit',
    defaultMessage: 'SUBMIT',
    description: '提交'
  },
  next: {
    id: 'components.Button.next',
    defaultMessage: 'NEXT',
    description: '下一步'
  },
  confirm: {
    id: 'components.Button.confirm',
    defaultMessage: 'CONFIRM',
    description: '确定'
  },
  back: {
    id: 'components.Button.back',
    defaultMessage: 'BACK',
    description: '返回'
  },
  download: {
    id: 'components.Button.download',
    defaultMessage: 'DOWNLOAD',
    description: '下载'
  },
  downloadNormalSize: {
    id: 'components.Button.downloadNormalSize',
    defaultMessage: 'Download',
    description: '下载'
  },
  ok: {
    id: 'components.Button.ok',
    defaultMessage: 'OK',
    description: '同意'
  },
  reject: {
    id: 'components.Button.reject',
    defaultMessage: 'DON’T ALLOW',
    description: '拒绝'
  },
  cancel: {
    id: 'components.Button.cancel',
    defaultMessage: 'CANCEL',
    description: '取消'
  },
  done: {
    id: 'components.Button.done',
    defaultMessage: 'DONE',
    description: '完成'
  },
  pay: {
    id: 'components.Button.pay',
    defaultMessage: 'PAY',
    description: '支付'
  },
  visit: {
    id: 'components.Button.visit',
    defaultMessage: 'Visit Your PayBy',
    description: '支付'
  },
  share: {
    id: 'components.Button.share',
    defaultMessage: 'Share',
    description: '支付'
  },
  collect: {
    id: 'components.Button.collect',
    defaultMessage: 'Collect Your Money',
    description: '支付'
  },
  check: {
    id: 'components.Button.check',
    defaultMessage: 'Check balance',
    description: '检查余额'
  },
  downloadPayBy: {
    id: 'components.Button.downloadPayBy',
    defaultMessage: 'Download PayBy',
    description: '检查余额'
  },
  existAndQuit: {
    id: 'components.Button.existAndQuit',
    defaultMessage: 'Exist and quit',
    description: '退出'
  },
  follow: {
    id: 'components.Button.follow',
    defaultMessage: 'FOLLOW',
    description: '关注'
  },
  acceptTransfer: {
    id: 'components.Button.acceptTransfer',
    defaultMessage: 'ACCEPT TRANSFER',
    description: '接收转账'
  },
  agreeAndContinue: {
    id: 'components.Button.agreeAndContinue',
    defaultMessage: 'AGREE AND CONTINUE',
    description: '签约'
  },
  closeService: {
    id: 'components.Button.closeService',
    defaultMessage: 'CLOSE SERVICE',
    description: '解约'
  },
  tell: {
    id: 'components.Button.tell',
    defaultMessage: 'Tell Your Friends!',
    description: '告诉朋友'
  },
  cashGift: {
    id: 'components.Button.cashGift',
    defaultMessage: 'Collect Your Cash Gift',
    description: '领红包'
  },
  checkBalance: {
    id: 'components.Button.checkBalance',
    defaultMessage: 'Check Your balance',
    description: '检查余额'
  },
  payNow: {
    id: 'components.Button.payNow',
    defaultMessage: 'PAY NOW',
    description: '支付'
  },
  complete: {
    id: 'components.Button.complete',
    defaultMessage: 'To Complete',
    description: '去实名'
  },
  use: {
    id: 'components.Button.use',
    defaultMessage: 'Use',
    description: '使用'
  },
  expired: {
    id: 'components.Button.expired',
    defaultMessage: 'Expired',
    description: '已过期'
  },
  completeProfile: {
    id: 'components.Button.completeProfile',
    defaultMessage: 'Complete Profile For 10AED',
    description: '完成实名领红包'
  },
  payWithPayBy: {
    id: 'components.Button.payWithPayBy',
    defaultMessage: 'Pay With PayBy',
    description: '使用PayBy'
  },
  invite: {
    id: 'components.Button.invite',
    defaultMessage: 'Invite Friends',
    description: '邀请好友'
  },
  send: {
    id: 'components.Button.send',
    defaultMessage: 'Send',
    description: '发送'
  },
  add: {
    id: 'components.Button.add',
    defaultMessage: 'Add a new number',
    description: '添加账号'
  }
})
