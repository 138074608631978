import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'routes.Feedback.Qrcode.title',
    defaultMessage: 'Feedback',
    description: '备注: 页面title'
  },
  feeling: {
    id: 'routes.Feedback.Qrcode.feeling',
    defaultMessage: 'Tell us your feeling',
    description: '使用感受'
  },
  tell_flag: {
    id: 'routes.Feedback.Qrcode.tellFlag',
    defaultMessage: 'Tell us why?',
    description: '原因'
  },
  feedback: {
    id: 'routes.Feedback.Qrcode.feedback',
    defaultMessage: 'Open to hear from here:',
    description: '问题反馈'
  },
  contact: {
    id: 'routes.Feedback.Qrcode.contact',
    defaultMessage: 'Contact Us',
    description: '联系我们'
  },
  service: {
    id: 'routes.Feedback.Qrcode.service',
    defaultMessage: 'Customer Service',
    description: '客服电话'
  },
  inquiry: {
    id: 'routes.Feedback.Qrcode.inquiry',
    defaultMessage: 'General Inquiry',
    description: '问询'
  },
  merchant: {
    id: 'routes.Feedback.Qrcode.merchant',
    defaultMessage: 'Merchant',
    description: '商户'
  },
  with: {
    id: 'routes.Feedback.Qrcode.with',
    defaultMessage: 'With you, we thrive @PayBy',
    description: '有你我们才更强大'
  },
  submit: {
    id: 'routes.Feedback.Qrcode.submit',
    defaultMessage: 'Submit',
    description: '提交'
  },
  feedbackPlaceholder: {
    id: 'routes.Feedback.Qrcode.feedbackPlaceholder',
    defaultMessage: 'Maximum for 10 words',
    description: '最多10个单词'
  },
  stress: {
    id: 'routes.Feedback.Qrcode.stress',
    defaultMessage: 'Bugs stress',
    description: 'bug太多'
  },
  slow: {
    id: 'routes.Feedback.Qrcode.slow',
    defaultMessage: 'Too Slow',
    description: '太慢'
  },
  safe: {
    id: 'routes.Feedback.Qrcode.safe',
    defaultMessage: 'Smooth and safe',
    description: '流畅又安全'
  },
  thanks: {
    id: 'routes.Feedback.Qrcode.thanks',
    defaultMessage: 'Thank you for feedback, we are much appreciated to your voice.',
    description: '感谢反馈'
  }
})
