import { createStore } from 'redux-dynamic-modules'
import { getThunkExtension } from 'redux-dynamic-modules-thunk'
import { connectRouter } from 'connected-react-router'
import siteConfigModule from './siteConfig'

export default function configureStore(initialState, history) {
  const router = {
    id: 'router',
    reducerMap: {
      router: connectRouter(history)
    }
  }
  const extensions = [getThunkExtension()]
  const enhancers = []

  // 创建store
  const store = createStore(
    {
      initialState: initialState,
      extensions: extensions,
      enhancers: enhancers
    },
    siteConfigModule,
    router
  )

  // 添加全局的modules
  store.addModules([siteConfigModule])

  // 备注：对于需要引入middleware的，在对应的module配置的地方引用
  // 返回store
  return store
}
