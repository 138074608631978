import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, goBack } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import { Toast } from 'antd-mobile'

// ------------------------------------
// Constants
// ------------------------------------
export const COUPON_DETAILS = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
}, 'COUPON_DETAILS')

// ------------------------------------
// Actions
// ------------------------------------
let timer
export function init(data) { // 根据券Id生产付款码
  return (dispatch) => {
    dispatch(callAPI({
      dispatch: dispatch,
      endpoint: '/cgs/api/couponPayCode/build',
      method: 'POST',
      body: data,
      requestAction: COUPON_DETAILS.INIT_REQUEST,
      successAction: COUPON_DETAILS.INIT_SUCCESS,
      success: function(result) {
        timer = setTimeout(() => {
          dispatch(init(data))
        }, 900000)
      },
      error: function(result, msg) {
        Toast.info(msg, 2, () => {
          dispatch(goBack())
        })
      }
    }))
  }
}

export function clearTimeOut() {
  return dispatch => {
    clearTimeout(timer)
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [COUPON_DETAILS.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [COUPON_DETAILS.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'couponDetails',
    reducerMap: {
      couponDetailsState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
