import React from 'react'
import PropTypes from 'prop-types'
import classes from './styles.module.scss'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { Button as BaseButton } from 'antd-mobile'

export default class Button extends React.Component {
  render() {
    const { intl, name = 'submit', size, children, className, ...restProps } = this.props
    var defaultClassName = classes.componentsButton
    if (size) {
      defaultClassName += ` am-button-${size}`
    }
    if (className) {
      defaultClassName += ` ${className}`
    }
    return (
      <BaseButton className={defaultClassName} {...restProps}>
        <b>{children || <FormattedMessage {...messages[name]} />}</b>
      </BaseButton>
    )
  }
}

Button.propTypes = {
  name: PropTypes.oneOf([
    'custom',
    'submit',
    'next',
    'confirm',
    'back',
    'download',
    'downloadNormalSize',
    'ok',
    'reject',
    'cancel',
    'done',
    'pay',
    'visit',
    'share',
    'collect',
    'check',
    'downloadPayBy',
    'existAndQuit',
    'follow',
    'acceptTransfer',
    'agreeAndContinue',
    'closeService',
    'tell',
    'cashGift',
    'checkBalance',
    'payNow',
    'complete',
    'use',
    'expired',
    'completeProfile',
    'payWithPayBy',
    'invite',
    'send',
    'add'
  ]).isRequired
}
