import React from 'react'
import PropTypes from 'prop-types'
import classes from './styles.module.scss'
import { FormattedMessage, injectIntl } from 'react-intl'
import messages from './messages'
import { InputItem } from 'antd-mobile'
import Countdown from 'react-countdown-now'
import { checkFormError } from 'utils'

class SMSCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      counting: true,
      date: Date.now()
    }
  }

  renderer = ({ seconds, completed }) => {
    // 允许传入自定义发送验证码按钮样式
    const button = this.props.button || (
      <span className={classes.sendBtn}>
        <FormattedMessage {...messages.getCode} />
      </span>
    )
    if (completed) {
      return React.cloneElement(button, {
        onClick: this.handleSendSMS
      })
    } else {
      return React.cloneElement(button, {
        disabled: true
      }, `${seconds} s`)
    }
  }

  handleSendSMS = () => {
    const { form, required = [], getSMS } = this.props
    form.validateFields(
      [...required],
      (error, value) => {
        if (checkFormError(error)) {
          getSMS(value, () => {
            // 添加回调函数，重置倒计时
            this.setState({ date: Date.now() })
          })
          this.setState({
            date: Date.now() + 59000
          })
        }
      }
    )
  }

  render() {
    const { form, intl, name, onFocus, placeholder } = this.props
    return (
      <div className={classes.componentsSmsCode}>
        {form.getFieldDecorator(name, {
          rules: [
            {
              type: 'string',
              required: true,
              len: 6,
              message: intl.formatMessage(messages.errMsg)
            }
          ]
        })(
          <InputItem
            type='tel'
            maxLength={6}
            onFocus={onFocus}
            placeholder={placeholder || intl.formatMessage(messages.placeholder)}
            extra={
              <Countdown
                key={this.state.date}
                date={this.state.date}
                renderer={this.renderer}
              />
            }
          />
        )}
      </div>
    )
  }
}

export default injectIntl(SMSCode)

SMSCode.propTypes = {
  form: PropTypes.object.isRequired
}
