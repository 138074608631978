import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const CASHDELIVERY_BOOK = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null,
  QUERY_BALANCE_REQUEST: null,
  QUERY_BALANCE_SUCCESS: null,
}, 'CASHDELIVERY_BOOK')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: `/jollychic-service/cashdeliver/isDisplayComingSoon?accessToken=${data.accessToken}`,
        requestAction: CASHDELIVERY_BOOK.INIT_REQUEST,
        successAction: CASHDELIVERY_BOOK.INIT_SUCCESS,
        success: function(result) {
          console.log(result)
          if (!result.isPass) {
            return dispatch(replace('/topic/comingSoon'))
          }
          dispatch(queryBalance(data))
        }
      })
    )
  }
}

export function queryBalance(data) {
  return dispatch => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: `/jollychic-service/cashdeliver/balance?accessToken=${data.accessToken}`,
        requestAction: CASHDELIVERY_BOOK.QUERY_BALANCE_REQUEST,
        successAction: CASHDELIVERY_BOOK.QUERY_BALANCE_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CASHDELIVERY_BOOK.INIT_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [CASHDELIVERY_BOOK.INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [CASHDELIVERY_BOOK.QUERY_BALANCE_REQUEST]: (state, action) => Object.assign({}, state, action.payload),
  [CASHDELIVERY_BOOK.QUERY_BALANCE_SUCCESS]: (state, action) => Object.assign({ loaded: true }, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'cashDeliveryBook',
    reducerMap: {
      cashDeliveryBookState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
