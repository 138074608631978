import React from 'react'
import classes from './styles.module.scss'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { Flex, WhiteSpace, WingBlank } from 'antd-mobile'
import _ from 'lodash'
import { Button } from 'components'
import { openNative } from 'utils/JSBridge'
import { customEvent } from 'utils'

export default class MapCard2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  call = (e, mobile) => {
    e.stopPropagation()
    window.location.href = `tel:${mobile}`
  }

  handleQRToPay = () => {
    customEvent({
      page_name: 'pcr_home',
      event_name: 'click',
      element_name: 'show_qr'
    })
    openNative('route://native/paycode/paycode')
  }

  componentDidMount = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          getLocationSuccess: true,
        })
      },
      () => {},
      {
        timeout: 3000,
      }
    )
  }

  render() {
    const item = this.props
    const mobile = item.mobile || item.tel
    const name = item.merchantName || item.name
    const distance = item.distance || _.get(item, 'distanceParam.value')
    return (
      <div className={classes.componentsMapCard2} onClick={item.onClick}>
        <Flex direction='row' align='stretch'>
          <Flex.Item>
            <div className={classes.merchantName}>
              <b>{name}</b>
              <WhiteSpace size='sm' />
            </div>
            {item.businessHours && (
              <div className='font-xs black'>
                <Flex>
                  <img width='13px' src={require('./images/time.png')} />
                  <Flex.Item>{item.businessHours}</Flex.Item>
                </Flex>
                <WhiteSpace size='sm' />
              </div>
            )}
            <div className='font-xs'>
              <Flex align='start'>
                <img width='13px' src={require('./images/location.png')} />
                <Flex.Item>
                  {distance && this.state.getLocationSuccess && (
                    <React.Fragment>
                      <span className='yellow'>
                        {distance < 1
                          ? `${_.round(distance * 1000, 0)}m`
                          : `${_.round(distance, 2)}km`}
                      </span>
                      {' · '}
                    </React.Fragment>
                  )}
                  {item.address} {item.city && ` - ${item.city}`}
                </Flex.Item>
              </Flex>
            </div>
          </Flex.Item>
          <div style={{ width: 40 }}>
            <Flex
              direction='column'
              align='end'
              justify='center'
              style={{ height: '100%' }}
            >
              {mobile && (
                <React.Fragment>
                  <a onClick={(e) => this.call(e, mobile)}>
                    <img width='22px' src={require('./images/phone.png')} />
                  </a>
                </React.Fragment>
              )}
              {item.locateDirectUrl && (
                <React.Fragment>
                  <WhiteSpace />
                  <a
                    href={item.locateDirectUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      width='24px'
                      src={require('./images/navigation.png')}
                    />
                  </a>
                </React.Fragment>
              )}
            </Flex>
          </div>
          <div className={classes.fixToBottom}>
            <WingBlank>
              <Button
                type='primary'
                name='custom'
                className='round'
                onClick={this.handleQRToPay}
              >
                Show QR to Pay
              </Button>
            </WingBlank>
            <WhiteSpace />
          </div>
          <WhiteSpace />
        </Flex>
      </div>
    )
  }
}
