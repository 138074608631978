import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'

// ------------------------------------
// Constants
// ------------------------------------
export const TOP_UP_MOBILE_NUMBER = keyMirror({
  INIT_REQUEST: null,
  INIT_SUCCESS: null
}, 'TOP_UP_MOBILE_NUMBER')

// ------------------------------------
// Actions
// ------------------------------------
export function init(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/queryAccount',
        method: 'POST',
        body: {
          ...data,
          accountTypes: ['MOBILE_TOP_UP']
        },
        requestAction: TOP_UP_MOBILE_NUMBER.INIT_REQUEST,
        successAction: TOP_UP_MOBILE_NUMBER.INIT_SUCCESS,
        success: function(result) {}
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_UP_MOBILE_NUMBER.INIT_REQUEST]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_MOBILE_NUMBER.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topUpMobileNumber',
    reducerMap: {
      topUpMobileNumberState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
