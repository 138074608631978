import { defineMessages } from 'react-intl'

export default defineMessages({
  noVoucher: {
    id: 'components.Vouchers.VoucherBlank.noVoucher',
    defaultMessage: "Hmm. There's no voucher.",
    description: '无券'
  },
  noExpiredVoucher: {
    id: 'components.Vouchers.VoucherBlank.noExpiredVoucher',
    defaultMessage: "Hmm. There's no expired voucher.",
    description: '无过期券'
  },
  noRedeemedVoucher: {
    id: 'components.Vouchers.VoucherBlank.noRedeemedVoucher',
    defaultMessage: "Hmm. There's no redeemed voucher.",
    description: '无已使用券'
  }
})
