import React from 'react'
import { Route, Switch } from 'react-router-dom'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path='/smartcode-service'
        render={(routeProps) => (
          <div>
            {require('./Main').default(routeProps)}
            {require('./ManageNotifications').default(routeProps)}
            {require('./Download').default(routeProps)}
          </div>
        )}
      />
    </Switch>
  )
}

export default AppRouter
