import React from 'react'
// import classes from './styles.module.scss'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { Flex, WhiteSpace } from 'antd-mobile'

export default class VoucherBlank extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     foo: 'foo'
  //   }
  // }
  render() {
    const { text = 'noVoucher' } = this.props
    return (
      <Flex direction='column' justify='center' style={{ height: '100%' }}>
        <div className='text-center' style={{ marginTop: '-40px' }}>
          <img src={require('./no voucher.png')} width='100' />
          <WhiteSpace size='sm' />
          <p className='gray'>
            <FormattedMessage {...messages[text]} />
          </p>
        </div>
      </Flex>
    )
  }
}
