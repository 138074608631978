import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware, replace } from 'connected-react-router'
import callAPI from 'utils/callAPI'
import keyMirror from 'pkeymirror'
import _ from 'lodash'
import { openRiskIdentify } from 'utils'
import { autoRechargeAdvance } from 'store/siteConfig'

// ------------------------------------
// Constants
// ------------------------------------
export const TOP_UP_AUTO_RECHARGE_LIST = keyMirror(
  {
    INIT_REQUEST: null,
    INIT_SUCCESS: null,
    CLOSE_REQUEST: null,
    CLOSE_SUCCESS: null,
    REOPEN_REQUEST: null,
    REOPEN_SUCCESS: null
  },
  'TOP_UP_AUTO_RECHARGE_LIST'
)

// ------------------------------------
// Actions
// ------------------------------------
export function init() {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/autoRecharge/queryConfigList',
        method: 'POST',
        requestAction: TOP_UP_AUTO_RECHARGE_LIST.INIT_REQUEST,
        successAction: TOP_UP_AUTO_RECHARGE_LIST.INIT_SUCCESS,
        success: function(result) { // 无数据重定向至开通页
          !_.size(result.configs) &&
            dispatch(replace('/topUp/auto-recharge/activate'))
        }
      })
    )
  }
}

export function close(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/autoRecharge/close',
        method: 'POST',
        body: {
          operatorType: 'CLOSE',
          ...data
        },
        requestAction: TOP_UP_AUTO_RECHARGE_LIST.CLOSE_REQUEST,
        successAction: TOP_UP_AUTO_RECHARGE_LIST.CLOSE_SUCCESS,
        success: function(result) {
          dispatch(init())
        }
      })
    )
  }
}

export function reOpen(data) {
  return (dispatch) => {
    dispatch(
      callAPI({
        dispatch: dispatch,
        endpoint: '/cgs/api/lifecenter/autoRecharge/reOpen',
        method: 'POST',
        body: {
          ...data,
          accountType: 'MOBILE_TOP_UP'
        },
        requestAction: TOP_UP_AUTO_RECHARGE_LIST.REOPEN_REQUEST,
        successAction: TOP_UP_AUTO_RECHARGE_LIST.REOPEN_SUCCESS,
        success: function(result) {
          const { openStatus, pendingStatus, identifyHint, protocolToken, requestNo } = result
          if (openStatus === 'SUCCESS') {
            dispatch(init())
            return
          }
          switch (pendingStatus) {
            case 'AUTO_DEBIT_IDENTIFY_HINT':
              // 调用native核身方法
              openRiskIdentify(
                {
                  eventType: 'PAYMENT', // TODO
                  ...identifyHint
                },
                () => {
                  dispatch(autoRechargeAdvance({ requestNo }))
                }
              )
              break
            case 'PROTOCOL_SIGN':
              dispatch(
                replace(
                  `/topUp/auto-recharge/sign/${protocolToken}?requestNo=${requestNo}`
                )
              )
              break
            default:
              break
          }
        }
      })
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_UP_AUTO_RECHARGE_LIST.INIT_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_LIST.INIT_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_AUTO_RECHARGE_LIST.CLOSE_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_LIST.CLOSE_SUCCESS]: (state, action) =>
    Object.assign({}, state, action.payload),
  [TOP_UP_AUTO_RECHARGE_LIST.REOPEN_REQUEST]: (state, action) => state,
  [TOP_UP_AUTO_RECHARGE_LIST.REOPEN_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export default function(history) {
  return {
    id: 'topUpAutoRechargeList',
    reducerMap: {
      topUpAutoRechargeListState: reducer
    },
    // middlewares when this module needed
    middlewares: [apiMiddleware, routerMiddleware(history)]
    // Actions to fire when this module is added/removed
    // initialActions: [],
    // finalActions: []
  }
}
