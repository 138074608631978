import React from 'react'
import { withRouter } from 'react-router'
import classes from './styles.module.scss'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { Carousel, List } from 'antd-mobile'
import _ from 'lodash'
import queryString from 'query-string'

class MapCarousel extends React.Component {
  constructor(props) {
    super(props)
    const params = queryString.parse(props.location.search)
    this.state = {
      params,
    }
  }

  handleCarouselChange = (index) => {
    console.log('handleCarouselChange', index)
    const { storeList } = this.props
    const { lat, lng } = storeList[index].location
    this.props.form.setFieldsValue({
      selected: { lat: Number(lat), lng: Number(lng) },
    })
  }

  handleCarouselCurrent = () => {
    const { storeList, form } = this.props
    const selected = form.getFieldValue('selected') || this.state.params
    const index = _.findIndex(storeList, function(item) {
      const { lat, lng } = item.location || {}
      return `${lat}${lng}` === `${selected.lat}${selected.lng}`
    })
    if (index > -1) {
      return index
    }
    // 默认选中第一个元素
    form.setFieldsValue({
      selected: storeList[0].location
    })
    return 0
  }

  goToList = () => {
    const { storeList, history, listUrl, form } = this.props
    const { selected = {}, type } = form.getFieldsValue()
    const link = queryString.stringifyUrl({
      url: listUrl,
      query: { ...selected, type },
    })
    sessionStorage.setItem('storeList', JSON.stringify(storeList))
    history.push(link)
  }

  render() {
    const { storeList } = this.props
    return (
      <div className={classes.componentsMapCarousel}>
        <Carousel
          className={classes.carousel}
          selectedIndex={this.handleCarouselCurrent()}
          frameOverflow='visible'
          cellSpacing={10}
          slideWidth={0.8}
          dots={false}
          afterChange={this.handleCarouselChange}
        >
          {storeList.map((item, index) => {
            const id = `${item.lat}${item.lng}`
            const mobile = item.mobile || item.tel
            const name = item.merchantName || item.name
            const distance = item.distance || _.get(item, 'distanceParam.value')
            return (
              <List key={id} className='no-border'>
                <List.Item>
                  <div className={classes.merchantName}>
                    <b>{name}</b>
                  </div>
                  {item.businessHours && (
                    <p className='font-xs black'>
                      <FormattedMessage {...messages.hour} /> {item.businessHours}
                    </p>
                  )}
                  {mobile && (
                    <a href={`tel:${mobile}`}>
                      <span className='iconfont iconMerchant-callphone green font-lg' />
                    </a>
                  )}
                  <div className='gray font-xs'>
                    {distance && `${parseInt(distance)}M - `}
                    {item.address} {item.city && ` - ${item.city}`}
                  </div>
                </List.Item>
              </List>
            )
          })}
        </Carousel>
        {storeList.length && (
          <div className={classes.btn} onClick={this.goToList}>
            <span className='iconfont iconMerchant-list font-md' />
            <b>
              <FormattedMessage {...messages.list} />
            </b>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(MapCarousel)
